<template>
  <form @submit.prevent="reloadObservations" class="flex flex-col gap-2" ref="formRef">
    <div class="flex flex-col">
      <div class="flex gap-2 md:gap-4">
        <AdvancedSelect v-if="userDetails && (userDetails?.is_educator || userDetails?.is_system_manager 
          || userDetails.user_roles.includes('Guest Facilitator'))" 
          :dataList="typeList" placeholder="" dispName="dispName" inputName="type"
          :default="typeParam" />
        <div class="basis-90" v-if="userDetails?.is_educator || userDetails?.is_system_manager">
          <SelectSearchSingle v-if="userDetails" :dataList="educatorListWithAll"
            placeholder="" dispName="educator_name" inputName="educator"
            :defaultValue="educatorParam" class="min-w-40" :hasSearch="false" />
        </div>
        <div v-if="typeParam === 'child'" class="basis-90">
          <SelectSearchSingle v-if="userDetails" :dataList="userDetails.school_info.students"
            placeholder="All Students" dispName="student_name" inputName="student"
            :defaultValue="studentParam || ''" class="min-w-40" :hasSearch="false" />
        </div>
        <button type="button" :class="cn(`py-2 md:py-3 px-8 inline-flex items-center gap-x-2 text-sm
          font-semibold rounded border border-transparent bg-gray-800 text-white
          hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
          dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
          justify-center shrink-0`, (route.params.type !== 'child' && route.params.type !== 'weekly-child') && 'hidden')" @click="createNewObserv">
          Create New
        </button>
      </div>
    </div>
    <div class="flex gap-2 md:gap-4 flex-wrap">
      <ObservSearchBar />
      <button data-hs-overlay="#hs-new-educator-modal" class="py-3 px-4 inline-flex items-center gap-x-2 
        text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800 
        hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
        @click="reloadObservations" type="button">
        Show Reports for this Search
      </button>
    </div>
  </form>
</template>

<script setup>
import { watch, inject, ref, computed } from 'vue';
import AdvancedSelect from '../../ui/AdvancedSelect.vue';
import { useRoute, useRouter } from 'vue-router';
import SelectSearchSingle from '../../ui/SelectSearchSingle.vue';
import ObservSearchBar from './ObservSearchBar.vue';
import { HSOverlay } from 'preline/preline';
import { cn } from '@/components/ui/utils.js'

const router = useRouter();
const route = useRoute()

const typeParam = ref(route.params.type)
const educatorParam = ref(route.params.educator)
const studentParam = ref(route.query.student)

watch(() => route.params, () => {
  // console.log('Route changed', route)
  typeParam.value = route.params.type
  educatorParam.value = route.params.educator
  studentParam.value = route.query.student
})

const formRef = ref(null)
const userDetails = inject('userDetails')

const allowedNames = ['Hetvi','Kshama','Neha', 'Nikita','Priyanka', 'Ruchika', 'Sai'];

const educatorListWithAll = computed(() => {
  if (!userDetails.value?.school_info) return [{ name: 'all', educator_name: 'All Facilitators' }]

  return [{ name: 'all', educator_name: 'All Facilitators' }].concat(userDetails.value.school_info.educators.sort((a,b) => {
    return a.educator_name.localeCompare(b.educator_name)
  }))
})

const typeList = [
  { name: 'child', dispName: 'Individual' },
  { name: 'activity', dispName: 'Group/Activity' },
  { name: 'unstructured', dispName: 'Weekly' },
  { name: 'parent', dispName: 'Parent' },
  { name: 'weekly-child', dispName: 'Weekly Child' }
]

const props = defineProps(['observationResource'])

const isUserDetailsLoaded = computed(() => !!userDetails.value);


const reloadObservations = () => {
  const formData = new FormData(formRef.value)
  const type = formData.get('type')
  const educator = formData.get('educator')
  const parent = formData.get('parent')
  const search = formData.get('observation-search')
  const student = formData.get('student')
  if (!search && !student) {
    router.push(`/front-portal/observations/view/${type || typeParam.value}/${educator || parent || 'all'}`)
  } else {
    let query = {}
    if (student) query.student = student
    if (search) query.search = search
    router.push({ 
      // name: 'ObservationsView', 
      path: `/front-portal/observations/view/${type || typeParam.value}/${educator || parent || 'all'}`,
      // params: { type: type, educator: educator }, 
      query: query 
    })
  }
  // console.log(educator)
  // observationResource.update({
  //   params: {

  //   }
  // })
}

const createNewObserv = () => {
  if (typeParam.value === 'weekly-child') {
    HSOverlay.open('#hs-weekly-child-form-modal-all')
    return
  }
  HSOverlay.open('#hs-daily-form-modal-all')
}
</script>