<template>
  <Card @click="viewObservation" class="transition-all cursor-pointer hover:brightness-95 rounded">
    <template #title>
      <p class="font-semibold text-base">
        <span v-if="props.observation.about !== `None`">{{ props.observation.about }}</span>
        <span v-if="props.observation.about === `None`">No Subject</span>
        <span v-if="props.observation.is_archived" class="text-sm text-gray-600"> (archived)</span>
      </p>
    </template>
    <div class="flex flex-col gap-2">
      <div class="text-sm text-gray-600 flex gap-1 items-center">
        <span class="font-medium">{{ props.observation.date }}</span>
        <span class="text-lg">|</span>
        <span class="font-medium">
          {{ truncateWithEllipsis(findValueByName(userDetails.school_info.educators, props.observation.educator, 'educator_name'), 8) }}
        </span>
      </div>
      <div v-if="props.observation.students" class="flex gap-2 flex-wrap">
        <div v-for="student in props.observation.students.slice(0, 3)"   
          class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ student.student_name }}
        </div>
        <!-- <div v-if="props.observation?.students.length > 3" class="text-[#FDD835] font-bold text-3xl h-8">
          ...
        </div> -->
      </div>
      <div v-if="props.observation.cohort" class="flex gap-2 flex-wrap">
        <div class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ findValueByName(userDetails.school_info.cohorts, props.observation.cohort, 'cohort_name') }}
        </div>
      </div>
      <p class="text-ellipsis line-clamp-2 font-light text-gray-700 mt-2 text-sm xl:text-base">
        {{ props.observation.feedback }}
      </p>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import { findValueByName, truncateWithEllipsis } from '@/lib/utils'
import { inject } from 'vue';

const props = defineProps([
    'observation', 'selectObservation'
])

const userDetails = inject('userDetails')

const viewObservation = () => {
  props.selectObservation(props.observation)
  HSOverlay.open('#hs-unstruct-edit-modal-all')
}
</script>