export const sections = [
  {
    section: "1. Learning Approach",
    questions: [
      {
        number: 1,
        question: "How did the child primarily approach learning tasks this week?",
        input: 'learning_approach',
        options: [
          "Independently explored and problem-solved",
          "Collaborated effectively with peers",
          "Sought guidance when needed",
          "Required frequent support and encouragement",
        ],
      },
      {
        number: 2,
        question: "Did the child demonstrate any new skills or knowledge?",
        input: 'if_new_skills',
        text_input: 'new_skills_desc',
        check: true,
        options: [
          "Yes",
          "No",
        ],
      },
      {
        number: 3,
        question: "How was the child's focus and engagement in activities?",
        input: 'focus_engagement',
        options: [
          "Remained fully engaged in most activities",
          "Showed varied engagement depending on interest",
          "Often needed redirection to stay on task",
          "Struggled to maintain focus on most activities",
        ],
      },
      {
        number: 4,
        question: "Did the child show critical thinking or decision-making skills?",
        input: 'critical_thinking',
        text_input: 'critical_thinking_example',
        options: [
          "Yes, frequently",
          "Yes, occasionally",
          "No observable instances",
        ],
      },
    ],
  },
  {
    section: "2. Friendships and Emotions",
    questions: [
      {
        number: 5,
        question: "How did the child interact with peers this week?",
        input: 'peer_interaction',
        options: [
          "Engaged cooperatively and resolved conflicts independently",
          "Interacted positively but sometimes needed help with conflicts",
          "Preferred solitary activities",
          "Struggled with peer interactions",
        ],
      },
      {
        number: 6,
        question: "How did the child express emotions?",
        input: 'emotional_expression',
        options: [
          "Expressed emotions clearly and regulated them well",
          "Expressed emotions but sometimes struggled to regulate them",
          "Had difficulty expressing emotions verbally",
          "Expressed emotions primarily through behavior",
        ],
      },
      {
        number: 7,
        question: "How did the child respond to transitions or unexpected changes?",
        input: 'reaction_to_change',
        options: [
          "Adapted easily to changes",
          "Needed some support but generally managed well",
          "Often became upset but could be calmed with assistance",
          "Significantly struggled with changes",
        ],
      },
    ],
  },
  {
    section: "3. Independence and Responsibility",
    questions: [
      {
        number: 8,
        question: "How did the child handle responsibilities?",
        input: 'responsibilities',
        options: [
          "Took on and completed tasks independently",
          "Completed tasks with occasional reminders",
          "Needed frequent reminders to complete tasks",
          "Avoided or refused responsibilities",
        ],
      },
      {
        number: 9,
        question: "How did the child behave during unstructured time?",
        input: 'unstructured_behaviour',
        options: [
          "Created own meaningful activities or play",
          "Engaged in play but sometimes needed direction",
          "Often seemed unsure how to use unstructured time",
          "Frequently disrupted others or sought adult attention",
        ],
      },
    ],
  },
  {
    section: "4. Interests and Creativity",
    questions: [
      {
        number: 10,
        question: "Which areas of interest or creativity did the child explore this week?",
        input: 'areas_of_interest',
        text_input: 'other_interests',
        options: [
          "Art and crafts",
          "Music or movement",
          "Storytelling or creative writing",
          "Building or construction",
          "Scientific exploration",
        ],
      },
      {
        number: 11,
        question: "Did the child try any new activities or show interest in new topics?",
        input: 'if_new_activities',
        text_input: 'new_activities_desc',
        options: [
          "Yes, enthusiastically",
          "Yes, with encouragement",
          "No new interests observed",
        ],
      },
    ],
  },
  {
    section: "5. Physical Activities and Well-being",
    questions: [
      {
        number: 12,
        question: "How was the child's energy level and physical engagement?",
        input: 'energy_level',
        options: [
          "Consistently active and engaged in physical activities",
          "Varied energy levels throughout the week",
          "Often seemed tired or reluctant to engage in physical activities",
          "Extremely active, sometimes having difficulty settling",
        ],
      },
      {
        number: 13,
        question: "Did you notice any changes in the child's physical skills or coordination?",
        input: 'changes_in_coord',
        text_input: 'coord_changes_desc',
        options: [
          "Yes, improvement observed",
          "No significant changes noted",
          "Yes, some challenges observed",
        ],
      },
    ],
  },
  {
    section: "6. Communication",
    questions: [
      {
        number: 14,
        question: "How did the child primarily communicate this week?",
        input: 'primary_communication',
        options: [
          "Expressed ideas clearly and engaged in complex conversations",
          "Communicated effectively for basic needs and simple ideas",
          "Used a mix of verbal and non-verbal communication",
          "Struggled to communicate needs or ideas",
        ],
      },
      {
        number: 15,
        question: "Did the child show interest in books or storytelling?",
        input: 'interest_in_books',
        options: [
          "Yes, frequently sought out books or stories",
          "Sometimes engaged with books when offered",
          "Showed little interest in books or stories",
        ],
      },
    ],
  },
  {
    section: "7. Exploration and Curiosity",
    questions: [
      {
        number: 16,
        question: "How curious was the child about their surroundings this week?",
        input: 'curiosity',
        options: [
          "Actively explored and asked questions about new things",
          "Showed interest when new topics or objects were introduced",
          "Occasionally curious about specific topics",
          "Showed little curiosity or interest in new things",
        ],
      },
      {
        number: 17,
        question: "How did the child approach new or challenging situations?",
        input: 'approach_challenges',
        options: [
          "Embraced challenges with enthusiasm",
          "Attempted new things with some hesitation",
          "Needed encouragement to try new things",
          "Avoided new or challenging situations",
        ],
      },
    ],
  },
  {
    section: "8. Problem-Solving and Critical Thinking",
    questions: [
      {
        number: 18,
        question: "How did the child approach problems or puzzles?",
        input: 'approach_puzzles',
        options: [
          "Persistently tried different strategies to solve problems",
          "Attempted to solve problems but sometimes got frustrated",
          "Often asked for help before trying to solve problems",
          "Showed little interest in problem-solving activities",
        ],
      },
      {
        number: 19,
        question: "Did the child demonstrate any creative problem-solving?",
        input: 'if_creative_solving',
        text_input: 'creative_solving_desc',
        options: [
          "Yes, often came up with unique solutions",
          "Sometimes showed creative approaches",
          "Typically used conventional problem-solving methods",
          "Struggled to engage in problem-solving",
        ],
      },
    ],
  },
  {
    section: "9. Collaboration and Teamwork",
    questions: [
      {
        number: 20,
        question: "How did the child work with others on group tasks?",
        input: 'group_tasks',
        options: [
          "Actively contributed and supported team efforts",
          "Participated in group work when encouraged",
          "Preferred to work independently even in group settings",
          "Had difficulty engaging in group tasks",
        ],
      },
      {
        number: 21,
        question: "Did the child take on any leadership roles in group activities?",
        input: 'leadership_roles',
        options: [
          "Yes, often initiated or guided group activities",
          "Sometimes took on leadership roles",
          "Rarely took on leadership roles",
          "Never took on leadership roles",
        ],
      },
    ],
  },
  {
    section: "10. Overall Well-being and Engagement",
    questions: [
      {
        number: 22,
        question: "How would you describe the child's overall well-being this week?",
        input: 'overall_wellbeing',
        options: [
          "Seemed happy and thriving",
          "Generally content with occasional ups and downs",
          "Showed signs of stress or discontent",
          "Significant concerns about well-being",
        ],
      },
      {
        number: 23,
        question: "How engaged was the child in the learning environment overall?",
        input: 'environment_engagement',
        options: [
          "Highly engaged and enthusiastic",
          "Moderately engaged, with varying interest levels",
          "Often seemed disconnected or uninterested",
          "Struggled to engage with the learning environment",
        ],
      },
      {
        number: 24,
        question: "Were there any activities or subjects that particularly excited the child this week?",
        input: 'if_exciting_activities',
        text_input: 'exciting_activities_desc',
        check: true,
        options: [
          "Yes",
          "No",
        ],
      },
    ],
  },
];

export const formStructure = [
  {
    title: "Weekly Changes",
    questions: [
      {
        number: 1,
        id: "weekly_changes",
        text: "Have you noticed any changes in the child this week compared to last week?",
        type: "multipleChoice",
        options: [
          "Improved focus or engagement",
          "New skills or knowledge demonstrated",
          "Changes in social interactions",
          "Shifts in interests or preferences",
          "Changes in emotional regulation",
          "No significant changes observed",
          "Not sure",
          // "Other"
        ],
        otherOption: true,
      },
      {
        number: 2,
        id: "weekly_changes_description",
        text: "If changes were observed, please describe:",
        type: "text",
      }
    ]
  },
  {
    title: "Learning and Activities",
    questions: [
      {
        number: 3,
        id: "engaging_activities",
        text: "Which activities seemed to engage the child this week?",
        type: "competencyPicker",
        otherOption: false,
        notSureOption: false
      },
      {
        number: 4,
        id: "less_interesting_activities",
        text: "Were there any activities the child appeared less interested in?",
        type: "competencyPicker",
        otherOption: false,
        notSureOption: false
      },
      {
        number: 5,
        id: "response_to_less_interesting",
        text: "How does the child typically respond to activities they're less interested in?",
        type: "multipleChoice",
        options: [
          "Fidgets or seems restless",
          "Asks for help or different tasks",
          "Talks with friends instead",
          "Appears to zone out",
          "Not sure",
          // "Other"
        ],
        otherOption: true
      },
      {
        number: 6,
        id: "focus_ability",
        text: "How would you describe the child's ability to focus on tasks?",
        type: "multipleChoice",
        options: [
          "Stays engaged most of the time",
          "Sometimes gets distracted but can refocus",
          "Often needs reminders to pay attention",
          "Finds it hard to concentrate on tasks",
          "Not sure"
        ]
      }
    ]
  },
  {
    title: "Interests and Creativity",
    questions: [
      {
        number: 7,
        id: "current_interests",
        text: "What subjects or activities seem to interest the child most right now?",
        type: "textWithMultipleChoice",
        options: [
          "Text",
          "Not Sure"
        ],
        textFieldId: "current_interests_text"
      },
      {
        number: 8,
        id: "explore_interests",
        text: "How does the child explore these interests at school?",
        type: "textWithMultipleChoice",
        options: [
          "Text",
          "Not Sure"
        ],
        textFieldId: "explore_interests_text"
      }
    ]
  },
  {
    title: "Friendships and Behavior",
    questions: [
      {
        number: 9,
        id: "structured_activity_behavior",
        text: "How does the child typically behave during structured activities?",
        type: "multipleChoice",
        options: [
          "Follows instructions well",
          "Sometimes needs reminders about rules",
          "Often has trouble following activity rules",
          "Not sure"
        ]
      },
      {
        number: 10,
        id: "free_play_behavior",
        text: "What does the child usually do during free play time?",
        type: "multipleChoice",
        options: [
          "Plays well with others and initiates activities",
          "Enjoys solo play and creates own games",
          "Seems unsure what to do during free time",
          "Not sure"
        ]
      },
      {
        number: 11,
        id: "handle_disappointment",
        text: "How does the child generally handle disappointment or changes?",
        type: "multipleChoice",
        options: [
          "Usually stays calm and adapts",
          "Might get upset but calms down quickly",
          "Often gets frustrated or has difficulty adjusting",
          "Not sure"
        ]
      },
      {
        number: 12,
        id: "social_interaction_changes",
        text: "Have you noticed any changes in the child's social interactions?",
        type: "multipleChoice",
        options: [
          "Yes",
          "No",
          "Not sure"
        ],
        followUpQuestion: {
          id: "social_interaction_changes_description",
          condition: ["Yes"],
          text: "If yes, what have you observed?",
          type: "text"
        }
      }
    ]
  },
  {
    title: "Challenges and Growth",
    questions: [
      {
        number: 13,
        id: "notable_challenges",
        text: "Did the child face any notable challenges this week?",
        type: "multipleChoice",
        options: [
          "Yes, with schoolwork",
          "Yes, with peers",
          "Yes, with emotions",
          "No significant challenges observed",
          "Not sure"
        ]
      },
      {
        number: 14,
        id: "challenge_approach",
        text: "If challenges were observed, how did the child approach them?",
        type: "textWithMultipleChoice",
        options: [
          "Text",
          "Not applicable",
          "Not Sure",
        ],
        textFieldId: "challenge_approach_text"
      }
    ]
  },
  {
    title: "Group and Individual Activities",
    questions: [
      {
        number: 15,
        id: "group_participation",
        text: "How would you describe the child's participation in group activities?",
        type: "multipleChoice",
        options: [
          "Actively participates and contributes ideas",
          "Joins in when encouraged",
          "Prefers to observe rather than participate",
          "Has difficulty engaging in group work",
          "Not sure",
          // "Other"
        ],
        otherOption: true
      },
      {
        number: 16,
        id: "individual_activities",
        text: "How does the child typically handle individual activities?",
        type: "multipleChoice",
        options: [
          "Works independently and stays focused",
          "Needs some guidance but completes tasks",
          "Often seeks help or reassurance",
          "Struggles to work independently",
          "Not sure",
          // "Other"
        ],
        otherOption: true
      },
      {
        number: 17,
        id: "activity_observations",
        text: "Any notable observations about the child during group or individual activities?",
        type: "textWithMultipleChoice",
        options: [
          "Text",
          "Nothing notable observed",
          "Not Sure",
        ],
        textFieldId: "activity_observations_text"
      }
    ]
  },
  {
    title: "Additional Insights",
    questions: [
      {
        number: 18,
        id: "additional_insights",
        text: "Is there anything else you'd like to share about the child from this week?",
        type: "textWithMultipleChoice",
        options: [
          "Text",
          "Nothing additional to report",
          "Not Sure",
        ],
        textFieldId: "additional_insights_text"
      }
    ]
  }
];