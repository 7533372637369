<!--Update Events form-->
<!--Updates and deletes the data from the frappe backend/database-->
<template>
<div> 

  <!-- Cross button -->
  <div class="sticky top-2 mr-2 flex justify-end z-10">
      <button @click="closeForm" class="border-black">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
     </button>
  </div>

  <!-- Form container -->
  <div class="event-form relative px-4 text-xs md:text-sm">
    <div>
      <form @submit.prevent="updateEvent" class="flex flex-col gap-1.5">
          
        
              <!-- Activity Title text input field-->  
              <!-- <input v-if="fetchedSelectedEventData.activity_details" v-model="fetchedSelectedEventData.activity_details.name" id="activitiesSelect" type="text" class="text-2xl text- font-semibold leading-9 tracking-wide text-left text-black border:white" placeholder="Activity name"> -->
              <input v-model="selectedActivityTitle" id="activitiesSelect" type="text" class="text-2xl text- font-semibold leading-9 tracking-wide text-left text-black border:white" placeholder="Activity name">
            
              <!-- Input for Activity Description -->
              <div class="flex flex-row items-center justify-between">
                  <label for="description" class="font-medium text- text-black mr-2">Description:</label>
                  <div class="w-[15rem] md:w-[80%]">
                    <textarea v-model="eventData.activity_description" id="description" placeholder="Activity Description"
                      class="w-[15rem] md:w-[100%] h-[6rem] md:h-[8rem] border border-gray-300 rounded text-sm md:text-sm align-middle"> 
                    </textarea>
                  </div>
              </div>             

              <!-- Competencies Select -->
              <div class="flex flex-row items-center justify-between">
                <label for="competenciesSelect" class="font-medium text- text-black mr-2">Competencies:</label> 
                <!-- Select -->
                <div class="relative border w-[14rem] md:w-[80%]">
                <select v-model="selectedValues" id="competenciesSelect" ref="competenciesSelect" multiple data-hs-select='{
                  "hasSearch": true,
                  "placeholder": "Select...",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-xs md:text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                  
                  <option v-for="competency in competenciesOptions" :key="competency.name" :value="competency.name">{{ competency.competency_name }}</option>
                </select>
                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                  </div>
                </div>
              </div>
              <!-- End Select -->

              <!-- Educators/Led by Select -->
              <div class="flex flex-row items-center justify-between">
                <label for="educatorsSelect" class="font-medium text- text-black mr-2">Led by:</label>
                <!-- Select -->
                <div class="relative w-[15rem] md:w-[80%]">
                <select v-model="selectedEducators" id="educatorsSelect" ref="educatorsSelect" multiple data-hs-select='{
                  
                      "placeholder": "Select option...",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                      "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                      "mode": "tags",
                      "wrapperClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                      "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700\"><div class=\"size-6 me-1\" data-icon></div><div class=\"whitespace-nowrap text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center size-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-neutral-700/50 dark:hover:bg-neutral-700 dark:text-neutral-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 size-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
                      "tagsInputClasses": "py-3 px-2 rounded-lg order-1 text-sm outline-none dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400",
                      "optionTemplate": "<div class=\"flex items-center\"><div class=\"size-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"text-xs text-gray-500 dark:text-neutral-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>",
                      "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                    }' class="hidden">
                  <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                  </div>
                </div>
              </div>
              <!-- End Select -->
              
               
              <!-- Input for Activity link -->
              <div class="flex flex-row items-center justify-between">
                <label for="activity_link" class="font-medium text- text-black mr-2">Activity Link:</label>
                <div class="border w-[15rem] md:w-[80%]">
                  <input v-model="selectedActivityLink" id="activity_link" type="text" placeholder="url"
                        class="w-full border border-gray-300 rounded px-4 text-xs md:text-sm align-middle">
                </div>
              </div>          

              <!-- Students/Learners Participating Select -->
              <div class="flex flex-row items-center justify-between">
              <label for="studentsSelect" class="font-medium text- text-black mr-2">Learners Participating:</label>
              <!-- Select -->
              <div class="relative w-[15rem] md:w-[80%]">
              <select v-model="selectedStudents" id="studentsSelect" ref="studentsSelect" multiple data-hs-select='{                         
                    "placeholder": "Select option...",
                    "toggleTag": "<button type=\"button\"></button>",
                    "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                    "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
                    "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
                    "mode": "tags",
                    "wrapperClasses": "relative ps-0.5 pe-9 min-h-[46px] flex items-center flex-wrap text-nowrap w-full border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
                    "tagsItemTemplate": "<div class=\"flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-neutral-900 dark:border-neutral-700\"><div class=\"size-6 me-1\" data-icon></div><div class=\"whitespace-nowrap text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"inline-flex flex-shrink-0 justify-center items-center size-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-neutral-700/50 dark:hover:bg-neutral-700 dark:text-neutral-400 cursor-pointer\" data-remove><svg class=\"flex-shrink-0 size-3\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M18 6 6 18\"/><path d=\"m6 6 12 12\"/></svg></div></div>",
                    "tagsInputClasses": "py-3 px-2 rounded-lg order-1 text-sm outline-none dark:bg-neutral-900 dark:placeholder-neutral-500 dark:text-neutral-400",
                    "optionTemplate": "<div class=\"flex items-center\"><div class=\"size-8 me-2\" data-icon></div><div><div class=\"text-sm font-semibold text-gray-800 dark:text-neutral-200\" data-title></div><div class=\"text-xs text-gray-500 dark:text-neutral-500\" data-description></div></div><div class=\"ms-auto\"><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 size-4 text-blue-600\" xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" viewBox=\"0 0 16 16\"><path d=\"M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z\"/></svg></span></div></div>",
                    "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                  }' class="hidden">
                <option v-for="student in studentsOptions" :key="student.name" :value="student.name">{{ student.student_name }}</option>
              </select>
                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                  <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                </div>
              </div>
              </div>
              <!-- End Select --> 
              
              <!-- Preline Accordian -->
              <div class="hs-accordion-group">
                <div class="hs-accordion" id="hs-basic-heading-three">
                  <button
                    type="button" 
                    class="hs-accordion-toggle hs-accordion-active: py-3 inline-flex items-center gap-x-1.5 w-full font-medium focus:outline-none focus:ring-2 focus:ring-transparent text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active: dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
                    aria-controls="hs-basic-collapse-three">
                    Additional Details
                    <svg class="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="M5 12h14" />
                      <path d="M12 5v14" />
                    </svg>
                    <svg class="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="M5 12h14" />
                    </svg>
                    
                  </button>
                  <div id="hs-basic-collapse-three"
                    class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 mb-2"
                    aria-labelledby="hs-basic-heading-three">
                    <p class="text-gray-800 dark:text-gray-200">

                      <!-- Input for Visitors -->
                      <div class="flex flex-row items-center justify-between mb-2">
                      <label for="visitors" class="font-medium text- text-black mr-2">Visitors</label>
                      <div class="border w-[15rem] md:w-[80%]">
                        <input v-model="eventData.visitors" id="visitors" type="text" placeholder="Visitors"
                              class="w-[15rem] md:w-[80%] border border-gray-300 rounded px-4 text-xs md:text-sm align-middle">
                      </div>
                      </div>


                      <!-- Input for Additional Comments -->
                      <div class="flex flex-row items-center justify-between">
                        <label for="additional_comments" class="font-medium text-black ">Additional Notes:</label>
                        <div class="w-[15rem] md:w-[80%]">
                          <textarea v-model="eventData.additional_comments" id="additional_comments" placeholder="Additional Comments"
                            class="w-[15rem] md:w-[100%] h-25 border border-gray-300 rounded text-xs md:text-sm align-middle"></textarea>
                        </div>  
                      </div>

                    </p>
                  </div>
                </div>
              </div>
              
        
              <!-- Buttons -->
              <div class="mt-2 flex md:justify-center mb-5">
                
                <div class="border bg-customNavbar">
                  <button @click="closeForm" class="m-1 py-1 md:py-1 px-2 border-black">Close</button>  
                </div>
                <button type="submit"  class="py-0 md:py-2 px-2 w-full md:w-1/4 bg-black text-white rounded-md cursor-pointer hover:bg-gray-800 block ml-2 mr-2">                               
                  Save         
                </button>
                <button type="button" @click="deleteEventConfirmation"  class="py-0 md:py-2 px-2 w-full md:w-1/4 bg-red-600 text-white rounded-md cursor-pointer hover:bg-red-700 block">
                  Delete Event                                    
                </button>
              </div> 
      </form>       
    </div> 
  </div>

 </div> 
 
 </template>
  
  
  <script>
  import { onMounted } from 'vue';
  import moment from 'moment';

  export default {
    computed: {
    selectedValues() {
      return []; 
    }
  },
    setup() {
    
    },
    inject: ["$call"],
    props: {
      event: Object,
      optionsResponse: {
        type: Object,
        required: true,
      },
      fetchedSelectedEventData: { // This is the prop to accept the full event data
        type: Object,
        required: true, 
      }
    },
    data() {
      return {
        eventData: {
           activity_description: '',
         }, // added activity_description as it kept throwing warning in console eventhough functionality was not getting effected
        isEventDataFetched: false,
        competenciesOptions: [],
        
        selectedCompetencies: [],
        
        selectedValues: [], // Initialize with an empty array
        selectedCohorts:[],
        selectedStudents:[],
        selectedEducators:[],
        selectedActivityTitle:{},
        selectedActivityLink:{},
        selectedRoom: {},
        
        activitiesOptions: [],
        cohortsOptions: [],
        educatorsOptions: [],
        studentsOptions: [],
        locationOptions:[],
        sessionOptions:[]
      };
    },
    mounted() {
    this.fetchFullEventData().then(() => { //Form gets rendered at the end of fetchFullEventData
    this.assignOptionsData(); // Preline gets initialised at the end of this function
    this.preselectMultiSelectFields();
  });

  },

  methods: {
   preselectMultiSelectFields() {
  
    this.selectedActivityTitle = this.eventData.activity_details?.name ?? this.eventData.activity_name ?? null;
    this.selectedActivityLink = this.eventData.activity_details?.activity_link?? null;
  
    console.log ("This is event data object in in preseleect funtion===============================================",this.eventData)
    const preselectedCustomCompetencies = this.eventData.custom_competency?.map(c => c.id);
    this.selectedValues = preselectedCustomCompetencies; // Assuming this.selectedValues is used for custom_competency

    const preselectedCohorts = this.eventData.cohorts?.map(item => item.id);
    this.selectedCohorts = preselectedCohorts;

    const preselectedStudents = this.eventData.students_participating?.map(item => item.id);
    this.selectedStudents = preselectedStudents;

    const preselectedEducators = this.eventData.led_by?.map(item => item.id);
    this.selectedEducators = preselectedEducators;
    
    this.selectedLocation = this.eventData.event_location?.room_id

    this.selectedSession = this.eventData.session
  },
 async fetchFullEventData() {
  // console.log("Fetch full events data called")
     if (this.event && this.event.name) {// no need to check for event but replacing it with this.fetchedSelectedEventData throws error
        try {
          this.eventData = this.fetchedSelectedEventData; // Populate eventData with the fetched full event data
          this.eventData.start = moment(this.eventData.start).format('YYYY-MM-DD');
          this.isEventDataFetched = true; // Flag to render the Form (have been removed now)
        } 
        
        catch (error) {
          console.error('Error fetching full event data:', error);
        }
      }
    },

    async assignOptionsData() {
      try {
        console.log("assign options data called");
        console.log("This is the prop before assigning values", this.optionsResponse);

        // Directly assign fetched data for all fields
        this.competenciesOptions = this.optionsResponse.competencies;
        this.cohortsOptions = this.optionsResponse.cohorts;  // Assign all cohorts
        this.educatorsOptions = this.optionsResponse.educators;  // Assign all educators
        this.studentsOptions = this.optionsResponse.students;
        this.locationOptions = this.optionsResponse.location;
        this.sessionOptions = this.optionsResponse.session;

        setTimeout(() => {
          window.HSStaticMethods.autoInit(); // Preline is initialized
          console.log("Preline Initialise inside Editform after 100ms delay");
        }, 100);
        
      } catch (error) {
        console.error('Error assigning data:', error);
      }
    },


   async updateEvent() {
    // const selectedActivities = this.getSelectedValues('activitiesSelect');
    const selectedCompetencies = this.getSelectedValues('competenciesSelect');
    const selectedCohorts = this.getSelectedValues('cohortsSelect');
    const selectedEducators = this.getSelectedValues('educatorsSelect');
    const selectedStudents = this.getSelectedValues('studentsSelect');
    const selectedLocation = this.getSelectedValues('locationSelect');
    const selectedSession = this.getSelectedValues('sessionSelect');
   

    const eventData = {
      event_name: this.eventData.name,  // Unique identifier of the event
      title: this.selectedActivityTitle,
      custom_competency_list: selectedCompetencies,
      cohort_list: selectedCohorts,
      led_by_list: selectedEducators,
      students_participating_list: selectedStudents,
      activity_description: this.eventData.activity_description,
      visitors: this.eventData.visitors,
      additional_comments: this.eventData.additional_comments,
      start: this.eventData.start,
      end: this.eventData.end,
      location: selectedLocation,
      session: selectedSession,
      activity_link: this.selectedActivityLink
      
    };
   
        try {
         console.log("This is data before updating $$$$$$$$$$$$$$$$$$$$$$$",eventData)
          await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.update_event_details', eventData);
          this.$emit('update-success'); // Emit an event on successful update
          this.$emit('close'); // You can also emit the close event here
          
        } catch (error) {
          console.error('Error updating event:', error);
          this.$emit('update-error', error);
        }
      },
     
      deleteEventConfirmation(){
        console.log("Emmiting delete event confirmation")
       
        // Emitting 'delete-event-confirmation' event with data
        this.$emit('delete-event-confirmation', {
          event_name: this.eventData.name
        });
        this.$emit('close');
      },
      closeForm() {
        this.$emit('close');
      },
      getSelectedValues(ref) {
        const selectElement = this.$refs[ref];
        if (selectElement.multiple) {
          // Handle multi-select
          const selectedOptions = selectElement.selectedOptions;
          return Array.from(selectedOptions).map(option => option.value);
        } else {
          // Handle single-select
          return selectElement.value;
        }
    },

      
    }
  };
  </script>
  
<style scoped>
.event-form form input {
  border: 0px solid #ddd;
  border-radius: 4px;
  max-width: 100%;
}
/* .event-form form input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
} */


</style>
  