<template>
  <div :class="cn('p-4 overflow-y-auto flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3" @keydown.stop>
      <div class="flex justify-between items-center">
        <label for="autoheightTextareaGroupSummary" class="block font-medium text-sm md:text-base mb-2">
          2. Share a brief description of the activity and how it unfolded. What did the kids do? (please observe and note without judgement)
        </label>
      </div>
      <textarea id="autoheightTextareaGroupSummary" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        rows="10" placeholder="Enter text here..." name="summary" v-model="summary" ref="summaryInputRef"
        @input="textareaAutoHeight"></textarea>
    </div>
    <div>
      <AudioRecorder :userData="props.userData" observationType="activity" 
        @transcriptGenerated="appendSummary" @transcriptStatus="handleTranscriptStatus" />
      <div v-if="transcriptStatusText"
        :class="cn(`text-sm font-semibold text-gray-600 p-3 text-center`)">
        {{ transcriptStatusText }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref } from 'vue';
import AudioRecorder from '../AudioRecorder.vue'

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'userData'
])

const summary = ref(props.observation?.activity_summary || '')
const summaryInputRef = ref(null)

const appendSummary = (newText) => {
  if (summary.value)
    summary.value = summary.value + '\n\n' + newText
  else
    summary.value = newText
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  summaryInputRef.value.style.height = 'auto'
  let calculatedHeight = summaryInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  summaryInputRef.value.style.height = `${calculatedHeight}px`
}

const transcriptStatusText = ref('')

const handleTranscriptStatus = (statusObj) => {
  if (statusObj.status === 'start') {
    transcriptStatusText.value = "Generating transcript, please wait..."
  } else if (statusObj.status === 'fail') {
    transcriptStatusText.value = 'Transcript generation failed, please try again...'
    setTimeout(() => {
      transcriptStatusText.value = ''
    }, 3500)
  } else if (statusObj.status === 'success') {
    transcriptStatusText.value = ''
  }
}
</script>