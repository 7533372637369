<template>
  <Card :class="cn(`basis-1/3 gap-2 rounded flex-1 min-w-0`, 'lg:flex')">
    <template #title>
      <div class="flex lg:flex-col justify-between items-center lg:items-stretch lg:gap-2 xl:flex-row 
        xl:items-center lg:flex-auto xl:flex-initial" ref="cardRef">
        <span class="font-medium text-sm md:text-lg lg:flex lg:flex-col xl:inline">
          Individual <span>Observations</span>
        </span>
        <!-- <DailyObservationModal v-if="childModalActive" modalName="daily-form-modal-2" :userData="props.userData"
          :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations">
          <template #trigger>
            <button type="button" class="py-2 md:py-3 px-8 2xl:px-16 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
              justify-center shrink-0" data-hs-overlay="#hs-daily-form-modal-2"
              @click="currentFormNum = 2">
              Add New
            </button>
          </template>
        </DailyObservationModal> -->
      </div>
    </template>
    <div class="flex flex-col h-full gap-2 flex-auto">
      <!-- <Card class="hs-tooltip basis-1/2 h-full flex justify-center items-center hidden md:flex">
        <DailyObservationModal modalName="daily-form-modal-1" :userData="props.userData">
          <template #trigger>
            <button type="button" class="hs-tooltip-toggle py-3 px-4 inline-flex justify-center items-center
              text-3xl rounded-3xl border border-transparent text-gray-800 gap-x-2
              hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white
              dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600
              h-20 w-20" data-hs-overlay="#hs-daily-form-modal-1" @click="currentFormNum=1">
              +
              <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible
                opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900
                text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700" role="tooltip">
                Create New Observation
              </span>
            </button>
          </template>
        </DailyObservationModal>
      </Card> -->
      <div v-if="observationExists" class="flex flex-col gap-2 md:gap-4 flex-auto">
        <DailyObservViewCard v-for="observation in props.observeData.daily_v2.slice(0,2)"
          :observation="observation" :selectObservation="selectObservation" :version="2" />
      </div>
      <div v-else class="flex flex-col gap-2 md:gap-4 flex-auto items-center text-xs xl:text-sm text-gray-500
        font-medium">
        Nothing to show here. Click on the Add New button to create your first observation
      </div>
      <div v-if="observationExists"
        class="flex items-center justify-start gap-8 mt-2">
        <router-link :to="`/front-portal/observations/view/child/all`" class="flex-auto flex">
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center">
            View All
          </button>
        </router-link>
      </div>
      <div v-else class="flex items-center justify-start gap-8 mt-2">
        <div>
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center"
            @click="addNewObservation">
            Add New
          </button>
        </div>
      </div>
    </div>
    <ObservEditModal modalName="observation-edit-modal" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" />
  </Card>
</template>

<script setup>
  import { HSOverlay } from 'preline/preline';
  import Card from '../ui/Card.vue';
  import DailyObservationModal from './forms/DailyObservationModal.vue';
  import { computed, ref, watch } from 'vue';
  import { cn } from '@/components/ui/utils'
  import ObservEditModal from './edit/ObservEditModal.vue';
  import DailyObservViewCard from './view/DailyObservViewCard.vue';

  const props = defineProps([
    'userData', 'observTab', 'observeData',
    'reloadObservations'
  ])

  const cardRef = ref(null)

  watch(() => props.observTab, () => {
    if (props.observTab === 'child') {
      cardRef.value.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  })

  // view all route - changed to all
  // const viewAllEducator = computed(() => {
  //   if (props.userData?.is_educator)
  //     return props.userData.educator_id
  //   return 'all'
  // })

  const selectedObservation = ref(null)
  const selectedObservationVersion = ref(2)

  const selectedSignal = ref(false)

  const selectObservation = (observationData, version = 1) => {
    selectedObservation.value = observationData
    selectedObservationVersion.value = version
    selectedSignal.value = !selectedSignal.value
    HSOverlay.open('#hs-observation-edit-modal')
  }

  const childModalActive = ref(true)
  const reloadChildModal = () => {
    console.log('Reloading Child observation modal')
    childModalActive.value = false
    setTimeout(() => childModalActive.value = true, 100)
  }

  const observationExists = computed(() => {
    if (props.observeData?.daily?.length 
              || props.observeData?.daily_v2?.length) {
      return true;
    } else {
      return false;
    }
  })

  const addNewObservation = () => {
    HSOverlay.open('#hs-daily-form-modal')
  }
</script>