<template>
  <div v-if="props.observation" class="p-4 space-y-2">
    <h3 v-if="!isSavePreview && props.observation.educator_name" 
      class="font-bold text-xl flex gap-4">
      <span>{{ getFormattedDate(props.observation.date) }}</span>
      <span>-</span>
      <span>
        {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
      </span>
    </h3>
    <!-- <div v-if="props.observation.activity_time" class="space-y-2">
      <h4 class="font-semibold">
        Session:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.activity_time }}
      </p>
    </div> -->
    <div v-if="props.observation.cohorts" class="space-y-2">
      <h4 class="font-semibold">
        Group(s) activity was presented to:
      </h4>
      <div v-if="!isSavePreview" class="flex flex-wrap gap-2">
        <div v-for="cohort in props.observation.cohorts"   
          class="bg-[#FDD835] px-2 rounded">
          {{ cohort.cohort_name }}
        </div>
      </div>
      <div v-if="isSavePreview" class="flex flex-wrap gap-2">
        <div v-for="cohort in props.observation.cohorts"   
          class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.cohorts, cohort, 'cohort_name') }}
        </div>
      </div>
    </div>
    <div v-if="props.observation.core_idea_type" class="space-y-2">
      <h4 class="font-semibold">
        What is the big picture/core idea?
      </h4>
      <div v-if="props.observation.core_idea_type === 'Text'" class="text-gray-500">
        {{ props.observation.core_idea }}
      </div>
      <div v-else class="text-gray-500">
        Unsure
      </div>
    </div>
    <div v-if="props.observation.activity_summary || props.observation.summary" class="space-y-2">
      <h4 class="font-semibold">
        Brief summary of the activity:
      </h4>
      <div v-if="props.observation.activity_summary" class="text-gray-500">
        {{ props.observation.activity_summary }}
      </div>
      <div v-if="props.observation.summary" class="text-gray-500">
        {{ props.observation.summary }}
      </div>
    </div>
    <div v-if="props.observation.engagement?.length" class="space-y-2">
      <h4 class="font-semibold">
        Engagement Level:
      </h4>
      <div v-for="engagement in props.observation.engagement" class="text-gray-500">
        <span class="font-semibold">
          {{ findValueByName(userDetails?.school_info.students, engagement.child, 'student_name') }}
        </span>
        <span>: </span>
        <span>{{ engagement.engagement_level }}</span>
      </div>
    </div>
    <div class="space-y-2">
      <h4 class="font-semibold">
        Competencies explored in the activity:
      </h4>
      <div v-if="!isSavePreview && props.version >= 2 && props.observation.custom_primary_competency" >
        <span class="bg-[#e0b700] px-2 rounded">{{ props.observation.custom_primary_competency }}</span>
      </div>
      <div v-if="!isSavePreview" class="flex flex-wrap gap-2">
        <div v-if="props.version === 1" class="bg-[#e0b700] px-2 rounded">
          {{ props.observation.primary_competency }}
        </div>
        <div v-if="props.version >= 2 && props.observation.primary_competency" class="bg-[#e0b700] px-2 rounded">
          {{ findValueByName(competencyList, props.observation.primary_competency, 'competency_name') }}
        </div>
        <div v-for="competency in props.observation.secondary_competencies"   
          class="bg-[#FDD835] px-2 rounded">
          {{ competency.competency_name }}
        </div>
        <div v-if="props.version >= 2 && props.observation.custom_secondary_competencies" 
          class="bg-[#FDD835] px-2 rounded">
          {{ props.observation.custom_secondary_competencies }}
        </div>
      </div>
      <div v-if="isSavePreview" class="flex flex-wrap gap-2">
        <div v-if="props.version === 1" class="bg-[#e0b700] px-2 rounded">
          {{ props.observation.primary_competency }}
        </div>
        <div v-if="props.version >= 2" class="bg-[#e0b700] px-2 rounded">
          {{ props.observation.primary_competency.competency === "Other" 
              ? findValueByName(competencyList, props.observation.other_primary, 'competency_name') 
              : (props.observation.primary_competency.is_id ?
                findValueByName(competencyList, props.observation.primary_competency.competency, 'competency_name')
              : props.observation.primary_competency.competency)
          }}
        </div>
        <div v-for="competency in props.observation.secondary_competencies"   
          class="bg-[#FDD835] px-2 rounded">
          {{ competency }}
        </div>
        <div v-for="competency in props.observation.other_secondary"   
          class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(competencyList, competency, 'competency_name') }}
        </div>
      </div>
    </div>
    <div v-if="props.observation.creativity_evidence" class="space-y-2">
      <h4 class="font-semibold">
        Evidence of creativity/innovation during the activity:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.creativity_evidence }}
      </div>
    </div>
    <div v-if="props.observation.activity_rating" class="space-y-2">
      <h4 class="font-semibold">
        Overally how did the activity go?
      </h4>
      <div class="text-gray-500">
        {{ props.observation.activity_rating }}
      </div>
    </div>
    <div v-if="props.version == 2 && props.observation.worth_repeating" class="space-y-2">
      <h4 class="font-semibold">
        Is this activity worth repeating?
      </h4>
      <div class="text-gray-500">
        {{ props.observation.worth_repeating }}
      </div>
    </div>
    <div v-if="props.version >= 2 && props.observation.worth_building" class="space-y-2">
      <h4 class="font-semibold">
        Is this activity worth building on?
      </h4>
      <div class="text-gray-500">
        {{  props.observation.worth_building }}
      </div>
    </div>
    <div v-if="props.observation.additional_notes" class="space-y-2">
      <h4 class="font-semibold">
        Additional Notes:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.additional_notes }}
      </div>
    </div>
  </div>
  <div v-else class="h-[30vh] flex flex-col justify-center items-center gap-4">
    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent 
      text-[#fdd835] rounded-full" role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-gray-600">
      Loading observation. Please wait...
    </p>
  </div>
</template>

<script setup>
import { findValueByName, getFormattedDate } from '@/lib/utils'
import { inject, watch } from 'vue';

const props = defineProps(['observation', 'isSavePreview', 'version'])

const userDetails = inject('userDetails')
const competencyList = inject('observationCompetencies')

watch(() => props.observation, ()=> {
  console.log(props.observation)
})
</script>