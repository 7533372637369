<template>
  <div :class="cn('p-4 overflow-hidden flex-auto max-h-full flex flex-col gap-4 text-gray-800 dark:text-gray-400 font-normal text-base',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <!-- <div v-if="props.observation" class="bg-[#FFFAE6] p-2 rounded-md flex flex-col gap-2 text-xs sm:text-sm">
      <p class="font-medium text-sm sm:text-base">Observation Details:</p>
      <div class="flex gap-2">
        <p class="font-medium">Session:</p>
        <p>{{ props.observation.activity_time }}</p>
      </div>
      <div class="flex gap-2">
        <p class="font-medium">Cohorts:</p>
        <div class="flex flex-wrap gap-2">
          <div v-for="cohort in props.observation.cohorts"
            class="bg-[#FDD835] px-2 rounded" v-if="userDetails">
            {{ findValueByName(userDetails?.school_info.cohorts, cohort, 'cohort_name') }}
          </div>
        </div>
      </div>
    </div> -->

    <div class="flex flex-col gap-2 overflow-y-auto">
    <!-- <div class="flex flex-col gap-2 max-h-[40vh] md:max-h-[45vh] overflow-y-auto"> -->
      <div class="space-y-1">
        <p class="font-medium text-xs md:text-sm">
          Select the activity linked to the observation:
        </p>
        <p class="text-gray-500 font-medium text-xs">
          Don't see an activity here? Check if the date is right?
        </p>
      </div>
      <div v-if="!props.events" class="text-xs">
        No activities found with the same date as the observations.
      </div>
      <div v-for="event in filteredEvents"
        v-if="filteredEvents?.length"
        :class="cn('border rounded-lg shadow-sm p-2 cursor-pointer text-xs sm:text-sm flex flex-col gap-2',
        selectedEvent === event.name && 'border-[#FDD835] border-2',
        props.observation.event?.name === event.name && 'border-[#FDD835] border-2 border-dashed')"
        @click="() => selectEvent(event.name)">
        <!-- {{ event }} -->
        <div class="flex gap-2">
          <p class="font-medium">Activity Title:</p>
          <p v-if="event.linked_activity_name">{{ event.linked_activity_name }}</p>
          <p v-else-if="event.activity_name">{{ event.activity_name }}</p>
          <p v-else>Unnamed Activity</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Date:</p>
          <p>{{ event.date }}</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Session:</p>
          <p>{{ event.session }}</p>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Cohorts:</p>
          <div class="flex flex-wrap gap-2">
            <div v-for="cohort in event.cohorts"
              class="bg-[#FDD835] px-2 rounded">
              {{ cohort.cohort_name }}
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <p class="font-medium">Led by:</p>
          <div class="flex flex-wrap gap-2">
            <div v-for="educator, index in event.led_by" >
              <!-- class="bg-[#FDD835] px-2 rounded"> -->
              <span v-if="index !== 0">, </span>
              {{ educator.educator_name }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="font-medium text-gray-600 pl-4 text-xs md:text-sm">
        No activities found for the same session.
      </div>
    </div>
    <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
      <div class="flex">
        <label class="text-xs md:text-sm text-gray-700 ms-2 flex gap-4 items-center">
          <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
            focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            id="subcomp-checkbox-group-8" v-model="isNotEventLinked" name="is_not_event_linked">
            Not Linked to any activity
        </label>
      </div>
    </div>

  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { inject, ref, computed, watch } from 'vue';
import { findValueByName } from '@/lib/utils';

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation',
  'events', 'selectedCohorts'
])

const filteredEvents = computed(() => {
  if (!props.events || !props.observation) return []
  // return props.events.filter((event) => {
  //   return event.session.toLowerCase() === props.observation.activity_time.split(' ')[0].toLowerCase()
  // })
  console.log("selected cohorts", props.selectedCohorts)
  return props.events.filter((event) => {
    return props.selectedCohorts.some(selCohort => event.cohorts.map(coh => coh.name).includes(selCohort));
  });
})
console.log("filtered events", filteredEvents)

const emit = defineEmits(['change:value'])

const selectedEvent = ref('')

const userDetails = inject('userDetails')

const isNotEventLinked = ref(false)

const selectEvent = (eventId) => {
  if (selectedEvent.value === eventId) {
    selectedEvent.value = ''
    emit('change:value','')
  } else {
    selectedEvent.value = eventId
    isNotEventLinked.value = false
    emit('change:value', eventId)
  }
}

watch(isNotEventLinked, (newValue) => {
  if (newValue && selectedEvent.value !== '') {
    // calling select again will remove the value, the function is a toggle
    selectEvent(selectedEvent.value)
  }
})
</script>