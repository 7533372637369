<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div :class="cn(`space-y-3`)">
      <h4 class="font-semibold text-base md:text-lg">
        {{ getNthAlphabet(props.sectionNumber) }}. {{ props.section }}
      </h4>
      <p class="font-medium text-sm md:text-base">
        {{ props.questionNumber }}. {{ props.question.text }}
      </p>
      <div :class="cn(`flex flex-col gap-y-2 md:gap-y-4`, textMode && 'hidden')">
        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Critical Thinking" 
              v-model="checked" name="comp_pair">
              Critical Thinking
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Emotional Intelligence" 
              v-model="checked" name="comp_pair">
            Emotional Intelligence
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Fine Motor Skills" 
              v-model="checked" name="comp_pair">
              Fine Motor Skills
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Math & Numeracy" 
              v-model="checked" name="comp_pair">
            Math & Numeracy
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Music & Rhythm" 
              v-model="checked" name="comp_pair">
            Music & Rhythm
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Physical Wellness" 
              v-model="checked" name="comp_pair">
            Physical Wellness
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Reading & Writing" 
              v-model="checked" name="comp_pair">
            Reading & Writing
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Science Exploration" 
              v-model="checked" name="comp_pair">
            Science Exploration
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Storytelling" 
              v-model="checked" name="comp_pair">
              Storytelling
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Visual Arts" 
              v-model="checked" name="comp_pair">
            Visual Arts
          </label>
        </div>

        <div class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value="Other" 
              v-model="checked" name="comp_pair">
            Other
          </label>
        </div>

        <div :class="cn(`space-y-4`, !checked.includes('Other') && 'hidden')" @keydown.stop>
          <SelectSearch v-if="props.competencyList?.length && renderSelect" placeholder="Select Competencies..." 
            :dataList="filteredCompetencyList" :inputName="`${props.question.id}-comps`" dispName="competency_name"
            ref="selectedCompRef" :defaultList="selectInputDefaultComps" @valueModified="handleCompSelectDisplay"
            :selectId="`${props.question.id}CompSelect`" />
        </div>
      </div>

      <!-- <div class="flex flex-col gap-1 md:gap-2 rounded p-2 bg-[#FEF3C2]">
        <div class="text-sm font-normal pl-4">
          Cannot find what you are looking for?
        </div>
        <div class="flex">
          <label class="text-sm text-gray-800 ms-4 font-semibold flex gap-4">
            <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" value=""
              id="subcomp-checkbox-group-8" v-model="textMode" name="text_mode">
              Enter custom text
          </label>
        </div>
      </div>
      <div :class="cn(`space-y-4`, !textMode && 'hidden')" @keydown.stop >
        <div class="flex gap-2 font-normal text-xs md:text-sm flex-wrap">
          <div v-for="comp in checked.filter((item) => item !== 'Other')"   
            class="bg-[#FDD835] px-2 rounded items-center justify-center py-1">
            {{ comp }}
          </div>
          <div v-for="comp in otherComps" 
            class="bg-[#FDD835] px-2 rounded items-center justify-center py-1">
            {{ findValueByName(props.competencyList, comp, 'competency_name') }}
          </div>
        </div>
        <textarea id="hs-autoheight-textarea-custom-comp-act" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          rows="10" placeholder="Enter competencies..." name="custom_anecdote_competencies" v-model="compText" ref="compTextRef"
          @input="textareaAutoHeight"></textarea>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { ref, computed, watch } from 'vue'
import SelectSearch from '@/components/ui/SelectSearch.vue';
import { findValueByName, findNameByValue, getNthAlphabet } from '@/lib/utils'

const props = defineProps([
  'pageNum', 'componentPageNum', 'competencyList',
  'defaultCheckPairs', 'defaultCompetencies', 
  'question', 'section', 'sectionNumber',
  'questionNumber', 'observation'
])
console.log({props})

const emit = defineEmits(['change:comps'])

const checked = ref(props.defaultCheckPairs || [])
const textMode = ref(false)

const renderSelect = ref(true)

const compText = ref('')
const compTextRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  compTextRef.value.style.height = 'auto'
  let calculatedHeight = compTextRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  compTextRef.value.style.height = `${calculatedHeight}px`
}

const otherComps = ref([])
const handleCompSelectDisplay = (selectedComps) => {
  console.log("Comps selecte", {selectedComps})
  otherComps.value = selectedComps
}

const compItems = [
  "Emotional Intelligence",
  "Reading & Writing",
  "Math & Numeracy",
  "Science Exploration",
  "Physical Wellness",
  "Critical Thinking",
  "Music & Rhythm",
  "Critical Thinking",
  "Storytelling",
  "Visual Arts"
]

const compItemIds = ref({})

const filteredCompetencyList = computed(() => {
  if (!props.competencyList) return []
  // if (!Array.isArray(competencyList.value)) return []
  return props.competencyList.filter((comp) => {
    const compInCompItems = compItems.some(str => str === comp.competency_name)
    // console.log(`Comp: |${comp.competency_name}|, compInCompItems=${compInCompItems}`)
    if (compInCompItems) compItemIds.value[comp.competency_name] = comp.name;
    return !compInCompItems;
  })
})

const selectInputDefaultComps = computed(() => {
  if (!props.defaultCompetencies)
    return []
  const mergedArray = [...new Set([...props.defaultCompetencies])]
  return mergedArray
})

const mixedCompetencies = computed(() => {
  const selectedCompItemIds = []
  checked.value.forEach((checkedComp) => {
    if (checkedComp !== 'Other') {
      // console.log("compitemids", {compItemIds})
      // console.log(`checkedcomp:${checkedComp}...`)
      selectedCompItemIds.push(compItemIds.value[checkedComp])
    }
  })
  return [...selectedCompItemIds, ...otherComps.value]
})

watch(mixedCompetencies, (newValue) => {
  console.log({compItemIds})
  emit('change:comps', newValue)
})
</script>