<template>
  <div :class="cn('p-4 flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-6">
      <transition name="fade" class="duration-500">
        <div v-if="prevSelectStatus && filteredPrevStudents.length" 
          :class="cn(`flex flex-col gap-4 bg-[#FEF3C2] p-4 rounded-md`)">
          <p class="font-semibold">
            You previously added the following students. Do you want to add them again?
          </p>
          <div class="flex flex-col gap-4">
            <div class="font-normal flex gap-4 flex-wrap">
              <span v-if="filteredPrevStudents" v-for="student in filteredPrevStudents"
                class="text-sm">
                {{ findValueByName(userDetails.school_info.students, student, 'student_name') }}
              </span>
            </div>
            <div class="flex gap-4">
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-teal-500 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                dark:hover:bg-neutral-800" @click="addPrevSelection">
                Yes
              </button>
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-red-500 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                dark:hover:bg-neutral-800" @click="prevSelectStatus = false">
                No
              </button>
              <!-- <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-teal-100 text-teal-800 hover:bg-teal-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-teal-900 dark:text-teal-500 dark:hover:text-teal-400">
                Yes
              </button>
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-red-100 text-red-800 hover:bg-red-200
                disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-900 dark:text-red-500
                dark:hover:text-red-400">
                No
              </button> -->
            </div>
          </div>
        </div>
      </transition>
      <div class="rounded bg-[#F9EEBD] p-2 space-y-4">
        <p class="text-xs md:text-sm font-medium">
          These are the Participating Learners as per your cohort selection:
        </p>
        <div class="flex flex-col gap-4">
          <div v-for="cohort in props.selectedCohorts" class="flex gap-4 items-start">
            <p v-if="userDetails" class="text-xs md:text-sm font-medium">
              {{ findValueByName(userDetails.school_info.cohorts, cohort, 'cohort_name') }}:
            </p>
            <div v-if="cohortStudents" class="text-xs md:text-sm font-medium text-gray-700">
              {{ cohortStudents[cohort].map((student) => student.student_name).join(', ') }}
              <!-- <span v-for="student in cohortStudents[cohort]" class="p-1 px-2">
                {{ student.student_name }}
              </span> -->
            </div>
          </div>
        </div>
      </div>
      <p class="font-medium text-sm md:text-base">
        Do you want to select any additional students?
      </p>
      <div class="flex flex-col gap-y-4">
        <div :class="cn('relative border rounded-md')">
          <!-- <input type="text" class="py-3 pe-0 block w-full bg-transparent border-t-transparent 
            border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent 
            focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 
            disabled:pointer-events-none md:w-2/3" placeholder="Competency Name" name="other_secondary"
            autocomplete="off"> -->
            <SelectSearch v-if="showStudentSelect" placeholder="Select Students..." 
              :dataList="filteredStudentList" inputName="other_students" dispName="student_name" 
              :defaultList="selectedStudentsDefault" @valueModified="handleNewChildSelected" 
              selectId="addStudentSelect" />
        </div>
        <div class="flex gap-2 text-xs md:text-sm font-normal pl-4 flex-wrap">
          <div v-for="child in selectedChildList" class="bg-[#FDD835] pl-2 py-1 rounded flex 
            items-center justify-center gap-1">
            <span>{{ findValueByName(props.studentList, child, 'student_name') }}</span>
            <div class="p-1 h-full pr-2 cursor-pointer hover:bg-[#efc51f] flex justify-center items-center"
              @click="() => { removeStudent(child)}">
              x
            </div>
          </div>
        </div>
      </div>
      <!-- <div>
        <div v-if="emit student list" class="text-sm font-normal">Selected students:</div>
      </div> -->
      <!-- <div class="flex flex-wrap">
        <div v-for="student in filteredStudentList">{{ student.student_name }}</div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, inject, ref } from 'vue'
import SelectSearch from '../../../ui/SelectSearch.vue';
import { findValueByName } from '@/lib/utils'

const props = defineProps([
  'pageNum', 'componentPageNum', 'observation', 
  'studentList', 'defaultStudents', 'selectedCohorts',
])

const userDetails = inject('userDetails')

const showStudentSelect = ref(true)
const prevSelectStatus = ref(true)
const selectedStudentsDefault = ref([])

const selectedChildList = ref([])

// const compItems = [
//   "Emotional Intelligence",
//   "Reading & Writing",
//   "Math & Numeracy",
//   "Science Exploration",
//   "Physical Wellness",
//   "Music & Rhythm",
//   "Critical Thinking",
//   "Storytelling",
//   "Visual Arts"
// ]

const cohortStudents = computed(() => {
  if (!props.studentList) return null
  if (!props.selectedCohorts) return null
  const cohortsObj = {};
  props.selectedCohorts.forEach((cohort) => {
    cohortsObj[cohort] = [];
  });
  props.studentList.forEach((student) => {
    if (props.selectedCohorts.includes(student.cohort)) {
      cohortsObj[student.cohort].push(student);
    }
  });

  return cohortsObj;
})

const filteredPrevStudents = computed(() => {
  if (!userDetails.value?.engage_students?.length) return []
  return userDetails.value.engage_students.filter((studentID) => {
    const matchingStudent = userDetails.value.school_info.students.find((student) => student.name === studentID)
    if (!matchingStudent) return false
    return !props.selectedCohorts.includes(matchingStudent.cohort)
  })
})

const filteredStudentList = computed(() => {
  if (!props.studentList) return []
  showStudentSelect.value = false
  setTimeout(() => {
    showStudentSelect.value = true
  }, 100)
  return props.studentList.filter((student) => {
    return !props.selectedCohorts.includes(student.cohort)
  })
})

const addPrevSelection = () => {
  // console.log(filteredPrevStudents.value)
  selectedStudentsDefault.value = [...selectedStudentsDefault.value, ...filteredPrevStudents.value]
  prevSelectStatus.value = false
  showStudentSelect.value = false
  setTimeout(() => {
    showStudentSelect.value = true
  }, 100)
}

const handleNewChildSelected = (selectedList) => {
  console.log("list changed")
  console.table(selectedList)
  selectedChildList.value = selectedList
}

// const competencyList = createListResource({
//   doctype: 'Competency',
//   fields: ['name','competency_name', 'description'],
//   orderBy: 'competency_name asc',
//   start: 0,
//   pageLength: 30,
//   auto: true
// })
const removeStudent = (studentID) => {
  console.log(`Removing student: ${studentID}`)
  selectedChildList.value = selectedChildList.value.filter((student) => student !== studentID)
  showStudentSelect.value = false
  setTimeout(() => {
    showStudentSelect.value = true
  }, 50)
}
</script>

<style>
/* Define transition effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>