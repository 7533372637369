<template>
  <div :class="cn(`flex flex-col h-full py-6 px-2 lg:pt-8 lg:pl-8 gap-4 lg:gap-8 lg:pr-4 overflow-y-auto`)">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 class="text-3xl">Micro Learning</h2>
        <p class="text-sm">
          There is so much to explore about learning and facilitation. 
          Here are a few micro-quizzes designed to help you learn interactively. 
          Please let us know if you would like us to add anything in particular.
        </p>
      </div>
    </div>


    <div v-if="sortedAttemptQuiz" class="flex flex-col md:flex-row md:flex-wrap gap-4">
      <LearningCardAttempted v-for="quiz in sortedAttemptQuiz" :title="quiz.title" 
        :description="quiz.description" :qID="quiz.name" :updateScore="setLastScore" :prevResults="quiz.results"
        :reloadQuizzes="reloadQuizList" />
    </div>
    
    <!-- <h3 v-if="sortedAttemptQuiz" class="font-semibold text-xl">
      Recommended Learning
    </h3> -->
    <div v-if="sortedQuizList" class="flex flex-col md:flex-row md:flex-wrap gap-4">
      <LearningCard v-for="quiz in sortedQuizList" :title="quiz.title"
        :description="quiz.description" :qID="quiz.name" :updateScore="setLastScore"
        :reloadQuizzes="reloadQuizList"
        />
    </div>

    <CompletionModal :score="lastScore" />
  </div>
  <!-- <div v-if="isNotEducator" class="h-full flex justify-center items-center">
    This page is only available for educators.
  </div> -->
</template>

<script setup>
import CompletionModal from '../components/educators/CompletionModal.vue';
import LearningCard from '../components/educators/LearningCard.vue';
import LearningCardAttempted from '../components/educators/LearningCardAttempted.vue';
import { createResource } from 'frappe-ui'
import { computed, ref } from 'vue';
import { cn } from '@/components/ui/utils.js';
import { watch } from 'vue';

const quizListResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.facilitator_questionnaire.api.get_questionnaires',
  auto: true
})

console.log(quizListResource)

const reloadQuizList = async () => {
  await quizListResource.reload()
}

const sortedQuizList = computed(() => {
  if (!quizListResource.data?.quiz_list) return []
  return quizListResource.data.quiz_list.toSorted((a, b) => {
    if (a.is_attempted && b.is_attempted) return 0
    else if (a.is_attempted) return 1
    else return -1
  })
})

const sortedAttemptQuiz = computed(() => {
  if (!quizListResource.data?.attempted_list) return []
  return quizListResource.data.attempted_list
})

const isNotEducator = computed(() => {
  if (quizListResource.data && quizListResource.data.status === 'fail')
    return true
  return false
})

const lastScore = ref(0)

const setLastScore = (score) => {
  lastScore.value = score
}


// Add a watcher on quizListResource.data
// watch(
//   () => quizListResource.data,
//   (newData) => {
//     console.log('Quiz List Response in Educators /learnings page======================:', newData);
//   }
// );
</script>