<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
  <div>
    <label class="font-medium text-sm md:text-base">Your name:</label>
    <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
      focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
      name="educator_id" v-model="educator" />
    <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
      focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
      readonly:opacity-50 readonly:pointer-events-none 
      font-normal opacity-70" 
      name="educator_name" :readonly="true"
      :value="findValueByName(props.userData?.school_info.educators, educator, 'educator_name')" autocomplete="off">
      <!-- :v-model="educatorName" autocomplete="off"> -->
  </div>
  <div class="space-y-3">
    <p class="font-medium text-sm md:text-base">
      Who/what is this feedback about
    </p>
    <div class="flex flex-col md:flex-row gap-2 md:gap-4 mt-4 text-xs md:text-sm">
      <div class="flex">
        <label class="text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
            disabled:pointer-events-none" id="hs-radio-un-about-group-1" value="Students"
            v-model="aboutPicked">
          Students
        </label>
      </div>

      <div class="flex">
        <label class="text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="hs-radio-un-about-group-2" value="Cohort"
            v-model="aboutPicked">
          Cohort
        </label>
      </div>

      <div class="flex">
        <label class="text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="hs-radio-un-about-group-2" value="Week"
            v-model="aboutPicked">
          Week
        </label>
      </div>

      <div class="flex">
        <label class="text-gray-600 ms-4 font-medium flex gap-4">
          <input type="radio" name="about" class="shrink-0 mt-0.5 border-gray-200 
            rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            id="hs-radio-un-about-group-2" value="None"
            v-model="aboutPicked">
          None
        </label>
      </div>
    </div>
  </div>
  <div :class="cn(!(aboutPicked === 'Students') && 'hidden')">
    <p class="font-medium text-sm md:text-base">
      Names of the students:
    </p>
    <!-- <StudentSelect :studentsList="props.studentsList" ref="studentSelectRef" /> -->
    <SelectSearch v-if="props.studentsList" placeholder="Select Student(s)..."
      :dataList="props.studentsList" inputName="students" dispName="student_name" 
      :defaultList="defaultStudentList" />
  </div>
  <div :class="cn(!(aboutPicked === 'Cohort') && 'hidden')" >
    <p class="font-medium text-sm md:text-base">
      Select the cohort
    </p>
    <div class="grid grid-cols-2 grid-rows-2 grid-flow-col lg:grid-cols-3 gap-y-2 mt-4" 
      v-if="props.userData">
      <div v-for="(cohort, index) in filteredCohorts" class="flex">
        <label class="text-xs md:text-sm text-gray-700 ms-3 font-normal flex gap-2 md:gap-4 items-center">
          <input type="radio" class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600
            focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
            :id="`hs-unstruct-cohort-${index}`"
            :value="cohort.name" name="cohort" :checked="props.observation.cohort === cohort.name">
          <!-- Bombils (2/3)s -->
          {{ cohort.cohort_name }}
        </label>
      </div>
    </div>
  </div>
  <div class="space-y-3">
    <label class="font-medium text-sm md:text-base">
      Date of Observation:
    </label>
    <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
      focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
      id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
      required @change="emitDate" @click="openDatePicker">
  </div>
  </div>
  <!-- <FormFooter :pageNum="props.pageNum" :goToNextPage="props.goToNextPage" :totalPageNum="props.totalPageNum"
    :goToPrevPage="props.goToPrevPage" /> -->
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, ref } from 'vue'
import StudentSelect from '@/components/observations/forms/DailyForm/StudentSelect.vue'
import SelectSearch from '@/components/ui/SelectSearch.vue';
// import FormFooter from '../FormFooter.vue';
import { findValueByName } from '@/lib/utils'
import { openDatePicker } from '@/lib/utils';

const props = defineProps([
  'pageNum', 'userData', 'studentsList',
  'observation'
])
const emit = defineEmits(['date:selected'])

const cohortList = ['Bumble Bees', 'Butterflies']

const filteredCohorts = computed(() => {
  if (props.userData) {
    const filteredSortedArray = props.userData.school_info.cohorts.filter((cohort) => {
      return cohortList.includes(cohort.cohort_name)
    }).sort((cohortA, cohortB) => {
      return cohortList.indexOf(cohortA.cohort_name) - cohortList.indexOf(cohortB.cohort_name)
    })
    return filteredSortedArray
  }
  return []
})

const educator = ref(props.observation.educator)
const dateValue = ref(props.observation.date)
// const educatorName = ref(findValueByName(props.userData?.school_info.educators, props.observation.educator, 'educator_name'))
const aboutPicked = ref(props.observation.about)
const defaultStudentList = computed(() => {
  if (!props.observation?.students) return []
  return props.observation.students.map((student) => student.name)
})

const isNameFilled = computed(() => {
  if (props.userData.is_educator) {
    return true
  }
  return false
})
const studentSelectRef = ref(null)

const areAllInputsFilled = () => {
  const pickedStudent = studentSelectRef.value.getPickedStudent()
  return isNameFilled.value && !!pickedStudent
}
defineExpose({areAllInputsFilled})

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const emitDate = () => {
  emit('date:selected', dateValue.value)
}
</script>