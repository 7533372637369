<template>
  <slot name="trigger" />

  <div :id="`hs-${modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center" role="dialog" tabindex="-1" 
    :aria-labelledby="`hs-${props.modalName}-label`">
    <div class="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
          <h3 :id="`hs-${props.modalName}-label`" class="font-bold text-gray-800 dark:text-white">
            Enable Push Notifications
          </h3>
          <button type="button" class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600" aria-label="Close" :data-hs-overlay="`#hs-${props.modalName}`">
            <span class="sr-only">Close</span>
            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
          </button>
        </div>
        <div class="p-4 overflow-y-auto space-y-4">
          <p class="mt-1 text-gray-800 dark:text-neutral-400 text-xs md:text-sm">
            Would you like to receive notifications from Zippie? 
            Stay updated with important alerts, new content, and more. 
            <!-- You can always change this later in your browser settings -->
          </p>
          <p v-if="!submissionFailed" 
            class="mt-1 text-gray-500 font-medium dark:text-neutral-400 text-xs md:text-sm text-center">
            After clicking allow, Click allow in the system popup as well.
          </p>
          <p class="mt-1 text-gray-600 font-medium dark:text-neutral-400 text-xs md:text-sm text-center"
            v-if="isSubmitting"
          >
            Saving permission status, please wait...
          </p>
          <p class="mt-1 text-red-600 font-medium dark:text-neutral-400 text-xs md:text-sm text-center"
            v-if="submissionFailed"
          >
            An error occured while saving user permission, please reload and try again.
          </p>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium 
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
            focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none 
            dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 
            dark:focus:bg-neutral-700" :data-hs-overlay="`#hs-${props.modalName}`" 
            @click="disallowNotifications">
            Deny
          </button>
          <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium 
            rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 
            focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="allowNotifications"
            >
            Allow
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { initializeFirebaseMessaging } from './firebaseUtils';
import { createResource } from 'frappe-ui';
import HSOverlay from '@preline/overlay';

const props = defineProps(['modalName', 'updatePermissionStatus'])

const currentAccessToken = ref('')

const addTokenResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.curriculum_notification_settings.api.add_fcm_token'
})

const setPermissionResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.curriculum_notification_settings.api.set_notification_permission'
})

const isSubmitting = ref(false)
const submissionFailed = ref(false)

const allowNotifications = async () => {
  submissionFailed.value = false;
  try {
    console.log('Requesting permission...');
    const permission = await Notification.requestPermission()
    let permissionToSet = permission;
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      const token = await initializeFirebaseMessaging();
      currentAccessToken.value = token;
      isSubmitting.value = true;
      await addTokenResource.submit({
        token: token,
      })
      if (addTokenResource.data.status === 'success') {
        console.log("Saved access token successfully", token)
      } else {
        console.log("Failed to save access token")
        permissionToSet = 'default';
        submissionFailed.value = true;
      }
    } else {
      console.log('Unable to get permission to notify.');
      setPermissionResource.submit({
        allowed: true,
      })
      if (setPermissionResource.data?.status === 'success') {
        console.log("Saved permission status successfully", true)
      } else {
        console.log("Failed to save permission status")
        submissionFailed.value = true;
      }
    }
    HSOverlay.close(`#hs-${props.modalName}`)
    // await new Promise(resolve => setTimeout(resolve, 100));
    props.updatePermissionStatus(permissionToSet);
  } catch (error) {
    console.error(error)
    submissionFailed.value = true;
  }
  isSubmitting.value = false;
}

const disallowNotifications = async () => {
  submissionFailed.value = false;
  try {
    setPermissionResource.submit({
      allowed: false,
    })
    if (setPermissionResource?.data?.status === 'success') {
      console.log("Saved permission status successfully", false)
    } else {
      console.log("Failed to save permission status")
      submissionFailed.value = true;
    }
  } catch (error) {
    console.error(error)
    submissionFailed.value = true;
  }
}
</script>