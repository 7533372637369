<template>
  <div v-if="props.observation" class="p-4 space-y-2">
    <h3 v-if="!isSavePreview && props.observation.learners" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.students, props.observation.learners, 'student_name') }}
      <span v-if="props.observation.additional_students?.length">
        and {{ props.observation.additional_students.length }} more
      </span>
    </h3>
    <h3 v-if="isSavePreview && props.observation.learner_name" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.students, props.observation.learner_name, 'student_name') }}
    </h3>
    <div v-if="!isSavePreview && props.observation.additional_students?.length && userDetails?.school_info" class="space-y-2">
      <h4 class="font-semibold">
        Additional Students:
      </h4>
      <div class="flex flex-wrap gap-2">
        <div v-for="student in props.observation.additional_students"
          class="bg-[#FDD835] px-2 rounded">
          {{ findValueByName(userDetails?.school_info.students, student.student, 'student_name') }}
        </div>
      </div>
    </div>
    <div v-if="!isSavePreview && props.observation.educator_name" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name') }}
      </p>
    </div>
    <div v-if="isSavePreview && props.observation.educator_id" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.educator_name }}
      </p>
    </div>
    <div v-if="props.observation.date" class="space-y-2">
      <h4 class="font-semibold">
        Date:
      </h4>
      <p class="text-gray-500">
        {{ observation.date }}
      </p>
    </div>
    <div v-if="props.observation.anecdotes" class="space-y-2">
      <h4 class="font-semibold">
        Anedotes from the day:
      </h4>
      <div class="text-gray-500">
        {{ props.observation.anecdotes }}
      </div>
    </div>
    <div v-if="props.observation.competencies || props.observation.anecdote_competencies || props.observation.custom_competency_text" class="space-y-2">
      <h4 class="font-semibold">
        Competencies anecdote is linked to:
      </h4>
      <div v-if="!props.isSavePreview" class="flex flex-wrap gap-2">
        <div v-for="competency in props.observation.competencies"
          class="bg-[#FDD835] px-2 rounded">
          {{ competency.competency_name }}
        </div>
      </div>
      <div v-if="!props.isSavePreview" class="flex flex-wrap gap-2">
        <div class="bg-[#FDD835] px-2 rounded">
          {{ props.observation.custom_competency_text }}
        </div>
      </div>
      <div v-if="props.isSavePreview" class="flex flex-wrap gap-2">
        <div v-for="competency in props.observation.anecdote_competencies"
          class="bg-[#FDD835] px-2 rounded">
          <span v-if="competency.is_id && competencyList">
            {{ findValueByName(competencyList, competency.comp, 'competency_name') }}
          </span>
          <span v-if="!competency.is_id">{{ competency.comp }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-[30vh] flex flex-col justify-center items-center gap-4">
    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent 
      text-[#fdd835] rounded-full" role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-gray-600">
      Loading observation. Please wait...
    </p>
  </div>
</template>

<script setup>
import { findValueByName } from '@/lib/utils'
import { inject } from 'vue';

const props = defineProps([
  'observation', 'isSavePreview',
])

const userDetails = inject('userDetails')
const competencyList = inject('observationCompetencies')
</script>