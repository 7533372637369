<template>
  <div class="flex flex-col h-[94vh] md:h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 v-if="route.params.type === 'child'" class="text-3xl">Individual Observations</h2>
        <h2 v-if="route.params.type === 'activity'" class="text-3xl">Group/Activity Observations</h2>
        <h2 v-if="route.params.type === 'unstructured'" class="text-3xl">Weekly Observations</h2>
        <h2 v-if="route.params.type === 'weekly-child'" class="text-3xl">Weekly Child Observations</h2>
        <h2 v-if="route.params.type === 'parent'" class="text-3xl">
          <span v-if="userDetails?.user_roles.includes('Parent')">Child </span>
          <span v-else>Parent </span>
          Observations
        </h2>
        <p v-if="route.params.type === 'unstructured'" class="text-sm">
          Anything from the week that's worth bringing to our collective attention
        </p>
      </div>
    </div>
    <ViewControls :observationResource="observationResource" />
    <ObservationList v-if="observationResource.data?.status === 'success'" 
      :observationType="route.params.type" :observations="observationResource.data.observations" />
    <PageControls v-if="observationResource.data?.status === 'success'" 
      :paginationDetails="observationResource.data.pagination" @navigate-to="navigateToPage" />
    <DailyObservationModal v-if="route.params.type === 'child' && childModalActive" modalName="daily-form-modal-all" 
      :userData="userDetails":reloadFn="reloadChildModal" />
    <WeeklyChildModal v-if="route.params.type === 'weekly-child' && childModalActive" modalName="weekly-child-form-modal-all" 
      :userData="userDetails" />
    <FormConfirmModal :createAnotherFn="addAnotherObservFn" :modalNum="1" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { createResource, createListResource } from 'frappe-ui';
import ObservationList from '../components/observations/view/ObservationList.vue';
import ViewControls from '../components/observations/view/ViewControls.vue';
import { onMounted, provide, readonly, watch, ref, inject } from 'vue';
import PageControls from '../components/observations/view/PageControls.vue';
import DailyObservationModal from '../components/observations/forms/DailyObservationModal.vue';
import FormConfirmModal from '../components/observations/forms/FormConfirmModal.vue';
import WeeklyChildModal from '../components/observations/forms/WeeklyChild/WeeklyChildModal.vue';

const userDetails = inject('userDetails')

const route = useRoute()

const educatorID = route.params.educator === 'all' ? '' : route.params.educator
const studentID = route.query.student || ""
const searchKey = route.query.search || ""

const competencies = ref(null)
provide('observationCompetencies', readonly(competencies))

const observationResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.api.search_observations',
  params: {
    type: route.params.type,
    educator_id: educatorID,
    student_id: studentID,
    search_key: searchKey,
    parent_id: educatorID,
  },
  auto: true,
})
console.log(observationResource)

const competencyResource = createListResource({
  doctype: 'Competency',
  fields: ['name', 'competency_name', 'description'],
  orderBy: 'competency_name asc',
  pageLength: 60,
  auto: true,
})

// onMounted(async () => {
//   await competencyResource.fetch()
//   console.log(competencyResource)
//   competencies.value = competencyResource.data
// })

watch(() => competencyResource.data, () => {
  if (competencyResource.data)
    competencies.value = competencyResource.data
})

watch(route, () => {
  const newEduID =  route.params.educator === 'all' ? '' : route.params.educator
  const newStudentID = route.query.student === 'all' ? '' : route.query.student
  observationResource.update({
    params: {
      type: route.params.type,
      educator_id: newEduID,
      student_id: newStudentID,
      search_key: route.query.search || "",
      parent_id: newEduID,
    }
  })
  observationResource.reload()
  console.log(observationResource)
})

const navigateToPage = (n) => {
  console.log({ n })
  observationResource.submit({
    type: route.params.type,
    educator_id: educatorID,
    search_key: searchKey,
    page: n,
  })
}

// need modification for other observs
const addAnotherObservFn = (formNum) => {
  HSOverlay.open(`#hs-daily-form-modal-all`)
}

const childModalActive = ref(true)
const reloadChildModal = () => {
  childModalActive.value = false
  setTimeout(() => childModalActive.value = true, 100)
}

</script>