<template>
  <div class="p-4 space-y-2 max-h-[80vh] overflow-y-auto lg:max-h-5/6">
    <h3 v-if="props.activityDetails.activity_name" 
      class="font-bold text-xl ">
      {{ props.activityDetails.activity_name }}
    </h3>
    <div v-if="props.activityDetails.domain" class="space-y-2">
      <h4 class="font-semibold">
        Domain:
      </h4>
      <p class="text-gray-500" v-if="domainResource.data">
        {{ findValueByName(domainResource.data, props.activityDetails.domain, 'domain_name') }}
      </p>
    </div>
    <div v-if="props.activityDetails.competencies" class="space-y-2">
      <h4 class="font-semibold">
        Competencies:
      </h4>
      <p class="text-gray-500">
        {{  props.activityDetails.competencies.map((comp) => comp.competency_name).join(', ') }}
      </p>
    </div>
    <div v-if="props.activityDetails.summary" class="space-y-2">
      <h4 class="font-semibold">
        Summary:
      </h4>
      <div class="text-gray-500">
        {{ props.activityDetails.summary }}
      </div>
    </div>
    <div v-if="props.activityDetails.activity_link" class="space-y-2">
      <h4 class="font-semibold">
        Activity Link:
      </h4>
      <div class="text-gray-500">
        <a :href="props.activityDetails.activity_link">
          {{ props.activityDetails.activity_link }}
        </a>
      </div>
    </div>
    <div v-if="props.activityDetails.activity_description" class="space-y-2">
      <h4 class="font-semibold">
        Description:
      </h4>
      <div class="text-gray-500">
        {{ removeHTMLContents(props.activityDetails.activity_description) }}
      </div>
    </div>
    <div v-if="props.activityDetails.observation_guide" class="space-y-2">
      <h4 class="font-semibold">
        Observation Guide:
      </h4>
      <div class="text-gray-500">
        {{ removeHTMLContents(props.activityDetails.observation_guide) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';
import { removeHTMLContents } from '../../ui/utils';
import { findValueByName } from '@/lib/utils.js';

const props = defineProps(['activityDetails'])
// console.log("Activity Details", props.activityDetails)

const domainResource = inject('domainResource')
</script>