<template>
    <div class="px-2 sm:px-4 pb-4 md:px-8 overflow-x-auto mt-4 md:mt-8" style="height: 100%; width: 100%;">
      
      <!-- Top bar of the page -->
      <div class="flex justify-between mb-4 md:mb-8 mx-3 md:mx-0">
           
          <!-- Title of page -->
          <div class="flex items-center">
             <!-- Back arrow -->
             <div @click="goBack" class="md:hidden cursor-pointer">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0098 9.10156C19.5621 9.10156 20.0098 8.65385 20.0098 8.10156C20.0098 7.54928 19.5621 7.10156 19.0098 7.10156V9.10156ZM0.302658 7.39446C-0.0878658 7.78498 -0.0878658 8.41815 0.302658 8.80867L6.66662 15.1726C7.05714 15.5632 7.69031 15.5632 8.08083 15.1726C8.47136 14.7821 8.47136 14.1489 8.08083 13.7584L2.42398 8.10156L8.08083 2.44471C8.47136 2.05418 8.47136 1.42102 8.08083 1.03049C7.69031 0.63997 7.05714 0.63997 6.66662 1.03049L0.302658 7.39446ZM19.0098 7.10156L1.00977 7.10156V9.10156L19.0098 9.10156V7.10156Z" fill="#6B7280"/>
                </svg>
              </div>
              <div class="text-lg md:text-2xl font-semibold md:font-medium ml-3"> Lunch Menu</div>
          </div>

          <!-- Plus sign -->
          <div v-if="props.userRoleView==='System Manager'" @click="showCreateNewLunchMenu=true" class="bg-[#FDD835] rounded-full w-10 h-10 flex items-center justify-center cursor-pointer ">
            <span class="text-black text-3xl leading-none flex text-center ">+</span>
          </div>

      </div>

      <!-- Create New Lunch Menu component -->
      <template v-if="showCreateNewLunchMenu">
        <CreateNewLunchMenu @close="showCreateNewLunchMenu=false"  @newMenuCreated="handleMenuUpdated"></CreateNewLunchMenu>
      </template>
 
      <!-- Edit Lunch Menu Form Component -->
      <template v-if="showEditLunchMenuForm">
        <EditLunchMenuForm 
          :docId="selectedMenu.name"
          :initialDate="selectedMenu.date"
          :initialItems="selectedMenu.menu_items.map(item => item.item)"
          :initialTag="selectedMenu.tag"
          @close="showEditLunchMenuForm=false"
          @menuUpdated="handleMenuUpdated"
        />
      </template>

      <!--Lunch Menu Cards -->
      <div v-if="lunchMenuData.menu.length>0" class="flex flex-wrap items-start gap-y-2 sm:gap-4 items-center">
       
        <div v-for="(menuItem, index) in lunchMenuData.menu" :key="index" @click="handleLunchMenuClick(menuItem)" class="w-[calc(50%-1rem)] max-w-[370px] md:max-w-[220px] mb-2 mx-2"
        :class="{ 'cursor-pointer': props.userRoleView==='System Manager'}">
      
          <div v-if="menuItem.tag" class=" bg-[#FEF3C2] rounded-t-xl text-sm text-center inline-flex px-3 py-[3px] ml-3 max-w-[143px] truncate">{{ menuItem.tag }}</div>
          
          <div class="border border-slate-200 min-h-[152px] max-w-[220px] pb-2 rounded-xl">
              <!-- Date -->
              <div class="font-medium rounded-t-xl w-full text-sm text-center text-slate-500 py-3"
                :class="{ 'bg-[#FEF3C2]': isToday(menuItem.date), 'bg-[#F3F4F6]': !isToday(menuItem.date) }">
                  
                  <!-- Calendar symbol -->
                  <div class="flex items-center justify-center gap-x-1">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.6205 3.22437H11.3811V2.32354C11.3811 2.15743 11.2341 1.9977 11.0616 2.00409C10.8891 2.01048 10.7422 2.14465 10.7422 2.32354V3.22437H6.26995V2.32354C6.26995 2.15743 6.123 1.9977 5.9505 2.00409C5.778 2.01048 5.63106 2.14465 5.63106 2.32354V3.22437H4.398C3.97439 3.22437 3.56813 3.39265 3.2686 3.69218C2.96906 3.99172 2.80078 4.39798 2.80078 4.82159V11.8494C2.80078 12.273 2.96906 12.6792 3.2686 12.9788C3.56813 13.2783 3.97439 13.4466 4.398 13.4466H12.6205C13.0441 13.4466 13.4504 13.2783 13.7499 12.9788C14.0494 12.6792 14.2177 12.273 14.2177 11.8494V4.82159C14.2177 4.39798 14.0494 3.99172 13.7499 3.69218C13.4504 3.39265 13.0441 3.22437 12.6205 3.22437ZM13.5788 11.8494C13.5788 12.3796 13.1508 12.8077 12.6205 12.8077H4.398C3.86773 12.8077 3.43967 12.3796 3.43967 11.8494V6.46992H13.5788V11.8494ZM13.5788 5.83104H3.43967V4.82159C3.43967 4.29131 3.86773 3.86326 4.398 3.86326H5.63106V4.2402C5.63106 4.40631 5.778 4.56604 5.9505 4.55965C6.123 4.55326 6.26995 4.41909 6.26995 4.2402V3.86326H10.7422V4.2402C10.7422 4.40631 10.8891 4.56604 11.0616 4.55965C11.2341 4.55326 11.3811 4.41909 11.3811 4.2402V3.86326H12.6205C13.1508 3.86326 13.5788 4.29131 13.5788 4.82159V5.83104Z" fill="#6B7280"/>
                      <path d="M8.1847 11.0292V8.81865C8.1847 8.81015 8.18243 8.8018 8.17813 8.79447C8.17383 8.78713 8.16766 8.78107 8.16025 8.77691C8.15283 8.77275 8.14444 8.77063 8.13594 8.77078C8.12744 8.77093 8.11913 8.77334 8.11187 8.77776L7.70426 9.02821C7.68974 9.03705 7.67312 9.04187 7.65612 9.04217C7.63912 9.04247 7.62234 9.03824 7.60751 9.02992C7.59269 9.02159 7.58034 9.00947 7.57174 8.9948C7.56315 8.98013 7.55861 8.96343 7.55859 8.94643V8.53051C7.55859 8.46343 7.59373 8.40082 7.65187 8.36632L8.13998 8.07435C8.16969 8.05646 8.20369 8.04696 8.23837 8.04688H8.63832C8.66349 8.04687 8.68841 8.05183 8.71166 8.06146C8.73492 8.0711 8.75605 8.08521 8.77384 8.10301C8.79164 8.12081 8.80576 8.14194 8.81539 8.16519C8.82503 8.18845 8.82998 8.21337 8.82998 8.23854V11.0286C8.82998 11.0794 8.80979 11.1282 8.77384 11.1641C8.7379 11.2 8.68915 11.2202 8.63832 11.2202H8.37701C8.35179 11.2205 8.32678 11.2157 8.30341 11.2062C8.28005 11.1967 8.25881 11.1827 8.24092 11.1649C8.22303 11.1472 8.20884 11.126 8.19919 11.1027C8.18954 11.0794 8.18462 11.0544 8.1847 11.0292Z" fill="#6B7280"/>
                    </svg>
                    <!-- Date -->
                    <div class="">{{  moment(menuItem.date).format('ddd, Do MMM')}}</div>
                  </div>
                  
              </div>

              <!-- Itmes -->
              <ul class="list-disc pl-6 mt-3">
                <li v-for="(item, itemIndex) in menuItem.menu_items" :key="itemIndex" class="mb-1 mr-1 text-xs font-semibold">
                  {{ item.item }}
                  <!-- <span v-if="item.description" class="text-xs text-gray-500 block ml-1 sm:ml-2">{{ item.description }}</span> -->
                </li>
              </ul>
          </div>    

        </div>

      </div>
  
      <!-- Loading spinner -->
      <div v-else-if="isLoading" class="absolute inset-0 flex items-center justify-center md:mt-12">
            <div style="color: #FDD835"
              class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full"
              role="status" aria-label="loading">
            <span class="sr-only">Loading...</span>
          </div>
      </div>
  
      <div v-else class="text-center text-gray-500 mt-4 sm:mt-8 text-sm sm:text-base">
        No lunch menu data available for this week.
      </div>

      <!-- Pagination buttons -->
       <div class="md:absolute md:bottom-0 md:w-[50%] md:left-[25%]">
      <div class="flex md:mb-6 justify-between items-center mb-2 mt-6 md:mt-0 md:mb-2.5">
        <button @click="getPreviousWeek" class="px-4 py-2 text-xs bg-gray-200 rounded">Previous Week</button>
        <span class="text-xs">{{ moment(currentWeekStart).format('MMM D') }} - {{ moment(currentWeekEnd).format('MMM D') }}</span>
        <button @click="getNextWeek" class="px-4 py-2 text-xs bg-gray-200 rounded">Next Week</button>
      </div>
    </div>

    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, inject } from 'vue';
  import { useRouter } from 'vue-router';
  import moment from 'moment';
  import CreateNewLunchMenu from '../components/lunchMenu/CreateNewLunchMenu.vue';
  import EditLunchMenuForm from '../components/lunchMenu/EditLunchMenuForm.vue';
  
  const $call = inject('$call');
  const lunchMenuData = ref(null); //To store data for rendering
  const isLoading = ref(true);
  const showCreateNewLunchMenu = ref(false);
  const showEditLunchMenuForm = ref(false);
  const selectedMenu = ref(null); //To hold data for passing it to Edit Modal

  // Pagination variables
  const currentWeekStart = ref(null);
  const currentWeekEnd = ref(null);
  const router = useRouter();



const props = defineProps({
  userRoleView: {
      type: String,
      required: true,
    }
});

  
const fetchLunchMenu = async (start = null, end = null) => {
  try {
    isLoading.value = true;
    const response = await $call('curriculum_planner.curriculum_planner.doctype.lunch_menu.api.get_week_lunch_menu', {
      start_date: start,
      end_date: end
    });
    if (response.status === 'success') {
      lunchMenuData.value = response.data;
      currentWeekStart.value = response.data.week_start;
      currentWeekEnd.value = response.data.week_end;
    } else {
      console.error('Failed to fetch lunch menu:', response.message);
    }
  } catch (error) {
    console.error('Error fetching lunch menu:', error);
  } finally {
    isLoading.value = false;
  }
 };
  
  
 const goBack = () => {
  router.go(-1); // This navigates back one step in the browser history
  };

  const isToday = (date) => {
    return moment(date).isSame(moment(), 'day');
  };

  
  const handleLunchMenuClick = (menuItem) => {
   if(props.userRoleView==='System Manager'){ 
     selectedMenu.value = menuItem;
     showEditLunchMenuForm.value = true;
    }
  };

 const handleMenuUpdated = async (updatedMenu) => {
  console.log('Menu updated:', updatedMenu);
  await fetchLunchMenu(currentWeekStart.value, currentWeekEnd.value); // Refresh the lunch menu data
 };

//  Naivgation funtions for pagination
 const getNextWeek = () => {
  const nextWeekStart = moment(currentWeekEnd.value).add(1, 'days').format('YYYY-MM-DD');
  const nextWeekEnd = moment(nextWeekStart).add(6, 'days').format('YYYY-MM-DD');
  fetchLunchMenu(nextWeekStart, nextWeekEnd);
};

const getPreviousWeek = () => {
  const prevWeekEnd = moment(currentWeekStart.value).subtract(1, 'days').format('YYYY-MM-DD');
  const prevWeekStart = moment(prevWeekEnd).subtract(6, 'days').format('YYYY-MM-DD');
  fetchLunchMenu(prevWeekStart, prevWeekEnd);
};
  
  onMounted(() => {
    fetchLunchMenu();
  });
  </script>
  
  <style scoped>
  @media screen and (max-width: 768px) {
    /* Add any mobile-specific styles here */
  }
  </style>