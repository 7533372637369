<template>
  <div v-if="props.observation" class="p-4 space-y-2">
    <h3 v-if="!isSavePreview && props.observation.student" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.students, props.observation.student, 'student_name') }}
    </h3>
    <h3 v-if="isSavePreview && props.observation.student_name" 
      class="font-bold text-xl ">
      {{ findValueByName(userDetails?.school_info.students, props.observation.student_name, 'student_name') }}
    </h3>
    <div v-if="!isSavePreview && props.observation.educator" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ findValueByName(userDetails?.school_info.educators, props.observation.educator, 'educator_name') }}
      </p>
    </div>
    <div v-if="isSavePreview && props.observation.educator_name" class="space-y-2">
      <h4 class="font-semibold">
        Educator:
      </h4>
      <p class="text-gray-500">
        {{ props.observation.educator_name }}
      </p>
    </div>
    <div v-if="props.observation.date" class="space-y-2">
      <h4 class="font-semibold">
        Date:
      </h4>
      <p class="text-gray-500">
        {{ observation.date }}
      </p>
    </div>
    <div v-for="section in formStructure" class="space-y-2">
      <h4 class="font-semibold py-2">
        {{ section.title }}
      </h4>
      <div v-for="(question, index) in section.questions" class="space-y-2 pl-2 md:pl-4">
        <h5>
          {{ (index + 1) }}. {{ question.text }}
        </h5>
        <!-- <p v-if="question.check" class="text-gray-500 pl-3">
          {{ observation[question.id] === 1 ? "Yes" : "No" }}
        </p> -->
        <p v-if="question.type === 'textWithMultipleChoice' && observation[question.id]
           && observation[question.id] === 'Text' && observation[question.textFieldId]" 
          class="text-gray-500 pl-3">
          {{ observation[question.textFieldId] }}
        </p>
        <p v-else-if="question.type === 'textWithMultipleChoice' && observation[question.id]
           && observation[question.id] !== 'Text'" 
          class="text-gray-500 pl-3">
          {{ observation[question.id] }}
        </p>
        <div v-else-if="question.type === 'competencyPicker'">
          <div class="flex gap-2" v-if="observation[question.id]?.length">
            <div v-for="competency in observation[question.id]"
              class="bg-[#FDD835] px-2 rounded">
              {{ competency.competency_name }}
            </div>
          </div>
          <p v-else class="text-red-500 pl-3">
            Not Answered
          </p>
        </div>
        <!-- Below, not specially handling the case where other value text input value is empty, other would be shown -->
        <p v-else-if="observation[question.id] && question.otherOption" class="text-gray-500 pl-3">
          <p v-if="observation[question.id] === 'Other' && observation[`${question.id}_other`]">
            {{ observation[`${question.id}_other`] }}
          </p>
          <p v-else>
            {{ observation[question.id] }}
          </p>
        </p>
        <p v-else-if="observation[question.id]" class="text-gray-500 pl-3">
          {{ observation[question.id] }}
        </p>
        <p v-else class="text-red-500 pl-3">
          Not Answered
        </p>
      </div>
    </div>
  </div>
  <div v-else class="h-[30vh] flex flex-col justify-center items-center gap-4">
    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent 
      text-[#fdd835] rounded-full" role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-gray-600">
      Loading observation. Please wait...
    </p>
  </div>
</template>

<script setup>
import { findValueByName, getFormattedDate } from '@/lib/utils'
import { inject, watch } from 'vue';
import { formStructure } from '../../forms/WeeklyChild/weeklyChildQuestions';

const props = defineProps(['observation', 'isSavePreview', 'version'])

const userDetails = inject('userDetails')
// const competencyList = inject('observationCompetencies')

watch(() => props.observation, ()=> {
  console.log(props.observation)
})
</script>