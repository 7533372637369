<template >
 
<div class="overflow-auto h-full" id="scroll-container">

  <!--Preline tabs and buttons  -->
  <div v-if="learnerAndUserProfileDetails && learnerAndUserProfileDetails.learners && learnerAndUserProfileDetails.learners.length > 0" class="flex flex-wrap justify-between pr-14">
    
    <!-- Preline tabs -->
    <div class="flex flex-col pr-4 md:p-0 mt-4 ml-8 md:ml-14"> 
      <div style="background-color: #FFFAE6;" class="flex hover:bg-gray-200 rounded-lg transition p-1">
        <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
          <button v-for="(learner, index) in learnerAndUserProfileDetails.learners" :key="index" type="button"  
              :class="[
                'hs-tab-active:bg-white hs-tab-active:text-black hs-tab-active:dark:bg-neutral-800 hs-tab-active:dark:text-black-400 py-3 px-4 flex flex-col md:flex-row items-center gap-x-2 bg-transparent text-sm text-gray-500 hover:text-gray-700 font-medium rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-white',
                { 'active': activeTabIndex === index}
              ]"
              @click="activeTabIndex = index; activeLearnerId = learner.name"
              :id="'segment-item-' + (index + 1)"
              :data-hs-tab="'#segment-' + (index + 1)"
              :aria-controls="'segment-' + (index + 1)"
              role="tab"
              >
              <!-- pfp -->
              <img :src=learner.presigned_s3_url class="rounded-full w-16 h-16" alt="Profile Picture">
        
              <div> {{learner.student_name }}</div>
          </button>
        </nav>
      </div>
    </div>

     <router-link :to="{ path: '/front-portal/photo-essays/photos-of-kids', query: { learners:activeLearnerId}}"  class="text-black p-3.5 rounded-lg self-center text-xs md:text-sm md:h-[50px] ml-8 mt-4" style="background-color:rgba(253, 216, 53, 1);"> <span class="flex hidden md:inline">View</span> Photos of {{activeStudentName }}</router-link>
  </div> 

  <!--Learners Profile Body  -->
  <div v-if="learnerAndUserProfileDetails && learnerAndUserProfileDetails.learners && learnerAndUserProfileDetails.learners.length > 0" class="px-8 py-6 lg:px-14 lg:py-10" style="height: 100%;"> 
    
  <div v-for="(learner, index) in learnerAndUserProfileDetails.learners" :key="index">
    
  <!--Info container -->
  <div :id="'segment-' + (index + 1)" :class="{ 'hidden': index !== activeTabIndex }" role="tabpanel" :aria-labelledby="'segment-item-' + (index + 1)" class="md:border border-slate-200 md:p-4 rounded-lg w-full mb-4 md:mb-8">
    
      <!--Navigation auto scroll bar -->
      <div class="hidden md:flex gap-6 lg:gap-8 mb-4 md:text-sm lg:text-normal">
        <div @click="scrollToSection(`basic-info-${activeLearnerId}`)" class="border border-slate-200 rounded-md py-3 px-5 font-medium">
          Basic Info
        </div>
        <div @click="scrollToSection(`current-likes-dislikes-${activeLearnerId}`)" class="border border-slate-200 rounded-md py-3 px-5 font-medium">
          Current Likes and Dislikes
        </div>
        <div @click="scrollToSection('likes-dislikes-over-time')" class="border border-slate-200 rounded-md py-3 px-5 font-medium">
          Likes and Dislikes Over time
        </div>
        <div @click="scrollToSection('observation-summaries')" class="border border-slate-200 rounded-md py-3 px-5 font-medium">
          Observation Summaries
        </div>
        <div @click="scrollToSection('daily-observations')" class="border border-slate-200 rounded-md py-3 px-5 font-medium">
          Daily Observations
        </div>
      
      </div>

      <!--Navigation auto scroll bar for PHONE SCREENS -->
      <div class="sticky top-[0px] bg-white flex w-full overflow-auto md:hidden mb-[20px] justify-between border border-slate-200 rounded-lg px-3 h-[60px] items-center">
            
          <button @click="scrollToSection(`basic-info-${activeLearnerId}`)" class="focus:outline-none">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.75 26.8594C22.75 24.8703 21.9598 22.9626 20.5533 21.5561C19.1468 20.1496 17.2391 19.3594 15.25 19.3594C13.2609 19.3594 11.3532 20.1496 9.9467 21.5561C8.54018 22.9626 7.75 24.8703 7.75 26.8594" stroke="#6B7280" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.252 19.3594C18.0134 19.3594 20.252 17.1208 20.252 14.3594C20.252 11.598 18.0134 9.35938 15.252 9.35938C12.4905 9.35938 10.252 11.598 10.252 14.3594C10.252 17.1208 12.4905 19.3594 15.252 19.3594Z" stroke="#6B7280" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24 4.35938H6.5C5.11929 4.35938 4 5.47866 4 6.85938V24.3594C4 25.7401 5.11929 26.8594 6.5 26.8594H24C25.3807 26.8594 26.5 25.7401 26.5 24.3594V6.85938C26.5 5.47866 25.3807 4.35938 24 4.35938Z" stroke="#6B7280" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <!-- <svg  width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.75 26.8594C22.75 24.8703 21.9598 22.9626 20.5533 21.5561C19.1468 20.1496 17.2391 19.3594 15.25 19.3594C13.2609 19.3594 11.3532 20.1496 9.9467 21.5561C8.54018 22.9626 7.75 24.8703 7.75 26.8594" stroke="black" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.252 19.3594C18.0134 19.3594 20.252 17.1208 20.252 14.3594C20.252 11.598 18.0134 9.35938 15.252 9.35938C12.4905 9.35938 10.252 11.598 10.252 14.3594C10.252 17.1208 12.4905 19.3594 15.252 19.3594Z" stroke="black" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M24 4.35938H6.5C5.11929 4.35938 4 5.47866 4 6.85938V24.3594C4 25.7401 5.11929 26.8594 6.5 26.8594H24C25.3807 26.8594 26.5 25.7401 26.5 24.3594V6.85938C26.5 5.47866 25.3807 4.35938 24 4.35938Z" stroke="black" stroke-width="1.15535" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
          </button>
          <!-- <img @click="scrollToSection(`current-likes-dislikes-${activeLearnerId}`)"
              :src="activeSection === `current-likes-dislikes-${activeLearnerId}` ? '/src/assets/learners-profile-icons/current_likes_and_dislikes_selected.png' : '/src/assets/learners-profile-icons/current_likes_and_dislikes_unselected.png'"
              class="h-[39px]"/> -->

          <button @click="scrollToSection(`current-likes-dislikes-${activeLearnerId}`)" class="focus:outline-none">
            <svg width="23" height="31" viewBox="0 0 23 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 11.1094V2.10938" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.99945 14.1994L7.74945 11.1094H3.37695C3.14409 11.1094 2.91442 11.0552 2.70613 10.951C2.49785 10.8469 2.31667 10.6957 2.17695 10.5094C2.03723 10.3231 1.9428 10.1068 1.90115 9.8777C1.85949 9.64859 1.87175 9.41293 1.93695 9.18937L3.68445 3.18938C3.77533 2.8778 3.96481 2.60411 4.22445 2.40938C4.4841 2.21464 4.7999 2.10937 5.12445 2.10938H15.2495C15.6473 2.10938 16.0288 2.26741 16.3101 2.54871C16.5914 2.83002 16.7495 3.21155 16.7495 3.60938V9.60938C16.7495 10.0072 16.5914 10.3887 16.3101 10.67C16.0288 10.9513 15.6473 11.1094 15.2495 11.1094H13.1795C12.9004 11.1095 12.6269 11.1875 12.3897 11.3346C12.1526 11.4817 11.9611 11.692 11.837 11.9419L9.24945 17.1094C8.89577 17.105 8.54765 17.0207 8.2311 16.8629C7.91455 16.7051 7.63776 16.4778 7.4214 16.198C7.20504 15.9182 7.05472 15.5931 6.98165 15.247C6.90859 14.9009 6.91467 14.5428 6.99945 14.1994Z" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 20.1094V29.1094" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 17.0194L15.25 20.1094H19.6225C19.8554 20.1094 20.085 20.1636 20.2933 20.2677C20.5016 20.3719 20.6828 20.5231 20.8225 20.7094C20.9622 20.8957 21.0566 21.1119 21.0983 21.341C21.14 21.5702 21.1277 21.8058 21.0625 22.0294L19.315 28.0294C19.2241 28.3409 19.0346 28.6146 18.775 28.8094C18.5154 29.0041 18.1996 29.1094 17.875 29.1094H7.75C7.35218 29.1094 6.97064 28.9513 6.68934 28.67C6.40804 28.3887 6.25 28.0072 6.25 27.6094V21.6094C6.25 21.2116 6.40804 20.83 6.68934 20.5487C6.97064 20.2674 7.35218 20.1094 7.75 20.1094H9.82C10.0991 20.1092 10.3726 20.0312 10.6097 19.8842C10.8469 19.7371 11.0383 19.5268 11.1625 19.2769L13.75 14.1094C14.1037 14.1138 14.4518 14.198 14.7684 14.3558C15.0849 14.5136 15.3617 14.741 15.5781 15.0208C15.7944 15.3006 15.9447 15.6257 16.0178 15.9718C16.0909 16.3179 16.0848 16.676 16 17.0194Z" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <!-- <svg width="23" height="31" viewBox="0 0 23 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 11.1094V2.10938" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.99945 14.1994L7.74945 11.1094H3.37695C3.14409 11.1094 2.91442 11.0552 2.70613 10.951C2.49785 10.8469 2.31667 10.6957 2.17695 10.5094C2.03723 10.3231 1.9428 10.1068 1.90115 9.8777C1.85949 9.64859 1.87175 9.41293 1.93695 9.18937L3.68445 3.18938C3.77533 2.8778 3.96481 2.60411 4.22445 2.40938C4.4841 2.21464 4.7999 2.10937 5.12445 2.10938H15.2495C15.6473 2.10938 16.0288 2.26741 16.3101 2.54871C16.5914 2.83002 16.7495 3.21155 16.7495 3.60938V9.60938C16.7495 10.0072 16.5914 10.3887 16.3101 10.67C16.0288 10.9513 15.6473 11.1094 15.2495 11.1094H13.1795C12.9004 11.1095 12.6269 11.1875 12.3897 11.3346C12.1526 11.4817 11.9611 11.692 11.837 11.9419L9.24945 17.1094C8.89577 17.105 8.54765 17.0207 8.2311 16.8629C7.91455 16.7051 7.63776 16.4778 7.4214 16.198C7.20504 15.9182 7.05472 15.5931 6.98165 15.247C6.90859 14.9009 6.91467 14.5428 6.99945 14.1994Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 20.1094V29.1094" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 17.0194L15.25 20.1094H19.6225C19.8554 20.1094 20.085 20.1636 20.2933 20.2677C20.5016 20.3719 20.6828 20.5231 20.8225 20.7094C20.9622 20.8957 21.0566 21.1119 21.0983 21.341C21.14 21.5702 21.1277 21.8058 21.0625 22.0294L19.315 28.0294C19.2241 28.3409 19.0346 28.6146 18.775 28.8094C18.5154 29.0041 18.1996 29.1094 17.875 29.1094H7.75C7.35218 29.1094 6.97064 28.9513 6.68934 28.67C6.40804 28.3887 6.25 28.0072 6.25 27.6094V21.6094C6.25 21.2116 6.40804 20.83 6.68934 20.5487C6.97064 20.2674 7.35218 20.1094 7.75 20.1094H9.82C10.0991 20.1092 10.3726 20.0312 10.6097 19.8842C10.8469 19.7371 11.0383 19.5268 11.1625 19.2769L13.75 14.1094C14.1037 14.1138 14.4518 14.198 14.7684 14.3558C15.0849 14.5136 15.3617 14.741 15.5781 15.0208C15.7944 15.3006 15.9447 15.6257 16.0178 15.9718C16.0909 16.3179 16.0848 16.676 16 17.0194Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
          </button>



          <button @click="scrollToSection('likes-dislikes-over-time')" class="h-[39px]">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.4173 8.77539L16.5007 18.6921L10.6673 12.8587L3.08398 20.4421" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.416 8.77539H26.416V15.7754" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </button>    
           <!-- <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.4173 8.77539L16.5007 18.6921L10.6673 12.8587L3.08398 20.4421" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.416 8.77539H26.416V15.7754" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg> -->


          <button @click="scrollToSection('observation-summaries')"class="h-[39px]">
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.75 26.8594H25.75C26.413 26.8594 27.0489 26.596 27.5178 26.1271C27.9866 25.6583 28.25 25.0224 28.25 24.3594V21.8594H13.25V24.3594C13.25 25.0224 12.9866 25.6583 12.5178 26.1271C12.0489 26.596 11.413 26.8594 10.75 26.8594ZM10.75 26.8594C10.087 26.8594 9.45107 26.596 8.98223 26.1271C8.51339 25.6583 8.25 25.0224 8.25 24.3594V6.85938C8.25 6.19633 7.98661 5.56045 7.51777 5.09161C7.04893 4.62277 6.41304 4.35938 5.75 4.35938C5.08696 4.35938 4.45107 4.62277 3.98223 5.09161C3.51339 5.56045 3.25 6.19633 3.25 6.85938V10.6094H8.25" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.5 21.8594V6.85938C24.5 6.19633 24.2366 5.56045 23.7678 5.09161C23.2989 4.62277 22.663 4.35938 22 4.35938H5.75" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.5 10.6094H13.25" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.5 15.6094H13.25" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>    
            <!-- <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 26.8594H25.75C26.413 26.8594 27.0489 26.596 27.5178 26.1271C27.9866 25.6583 28.25 25.0224 28.25 24.3594V21.8594H13.25V24.3594C13.25 25.0224 12.9866 25.6583 12.5178 26.1271C12.0489 26.596 11.413 26.8594 10.75 26.8594ZM10.75 26.8594C10.087 26.8594 9.45107 26.596 8.98223 26.1271C8.51339 25.6583 8.25 25.0224 8.25 24.3594V6.85938C8.25 6.19633 7.98661 5.56045 7.51777 5.09161C7.04893 4.62277 6.41304 4.35938 5.75 4.35938C5.08696 4.35938 4.45107 4.62277 3.98223 5.09161C3.51339 5.56045 3.25 6.19633 3.25 6.85938V10.6094H8.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.5 21.8594V6.85938C24.5 6.19633 24.2366 5.56045 23.7678 5.09161C23.2989 4.62277 22.663 4.35938 22 4.35938H5.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.5 10.6094H13.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.5 15.6094H13.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->



          <button @click="scrollToSection('daily-observations')" class="h-[39px]">  
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.75 14.3594L13.25 16.8594L18.25 11.8594" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.5 24.3594C20.0228 24.3594 24.5 19.8822 24.5 14.3594C24.5 8.83653 20.0228 4.35938 14.5 4.35938C8.97715 4.35938 4.5 8.83653 4.5 14.3594C4.5 19.8822 8.97715 24.3594 14.5 24.3594Z" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M27 26.8594L21.625 21.4844" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </button>    
          <!-- <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.75 14.3594L13.25 16.8594L18.25 11.8594" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.5 24.3594C20.0228 24.3594 24.5 19.8822 24.5 14.3594C24.5 8.83653 20.0228 4.35938 14.5 4.35938C8.97715 4.35938 4.5 8.83653 4.5 14.3594C4.5 19.8822 8.97715 24.3594 14.5 24.3594Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M27 26.8594L21.625 21.4844" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> -->
        </div>

      
        <!-- Basic info -->
        <div :id="`basic-info-${activeLearnerId}`" class="flex flex-col gap-2 border border-slate-200 pl-4 rounded-lg pt-3 pb-3 mb-[20px]">

        <div>
          <span class="font-medium mr-2"> Age:</span>
          <span class="text-slate-500">{{ calculateAge(learner.date_of_birth) }}</span>
        </div>  

        <div v-if="learner.date_of_birth">
          <span class="font-medium mr-2"> Birthday:</span>
          <span class="text-slate-500">{{moment(learner.date_of_birth).format('DD MMMM') }}</span>
        </div>  

        <div>
          <span class="font-medium mr-2"> Cohort:</span>
          <span class="text-slate-500">{{learner.cohort_name }}</span>
        </div>  

        <div v-if="learner.parent_1 || learner.parent_2">
          <span class="font-medium mr-2"> Parents Names:</span>
          <span class="text-slate-500"> <template v-if="learner.parent_1">{{learner.parent_1.parent_name }}</template> & <template v-if="learner.parent_2">{{learner.parent_2.parent_name }}</template> </span>
        </div>  

        <div class="flex flex-col md:flex-row w-full">
          <span class="font-medium mr-2 min-w-[175px]"> Emergency Contacts:</span>
          <div v-if="learner.emergency_contact && learner.emergency_contact.length" class="w-full flex flex-col md:flex-row">
            <div v-for="(contact, index) in learner.emergency_contact" :key="index" class="">
              <span class="text-slate-500">{{ contact.contact_name }}: {{ contact.phone_number }}({{ contact.relation_to_child }}) </span>
              <template v-if="index < learner.emergency_contact.length - 1"><span class="hidden md:inline text-slate-500 mr-1">,</span></template>
            </div>
          </div>
          <span v-else class="text-slate-500">No emergency contacts available</span>

        </div> 
         

        <div>
          <span class="font-medium mr-2"> Food Preferences:</span>
          <span class="text-slate-500">{{learner.food_preference }}</span>
        </div>  
        
        <div>
          <span class="font-medium mr-2"> Allergies:</span>
          <span class="text-slate-500">{{learner.allergies }} </span>
        </div> 

        <div>
          <span class="font-medium mr-2"> Fun Facts about {{ learner.student_name }}:</span>
          <span class="text-slate-500">{{learner.fun_facts }} </span>
        </div> 

        </div>

        <!--Current Likes and Dislikes -->
        <template v-if="learner.likes || learner.dislikes">
          <div :id="`current-likes-dislikes-${activeLearnerId}`" class="font-medium mr-2 mb-[12px]"> Current Likes & Dislikes:</div>
          <!-- container -->
          <div class="flex flex-col gap-3 border border-slate-200 rounded-lg pl-4 py-4 text-sm">
            <div v-if="learner.likes">
              <span class="font-medium mr-2"> Likes:</span>
              <span class="text-slate-500"> {{ learner.likes }}</span>
            </div> 

            <div v-if="learner.dislikes">
              <span class="font-medium mr-2"> Dislikes:</span>
              <span class="text-slate-500"> {{ learner.dislikes }}</span>
            </div> 

          </div>
        </template>
        

        <!--Likes and Dislikes Over time-->
        <template v-if="activeLearnerLikesAndDislikes.length> 0"></template>
          <div id="likes-dislikes-over-time" class="font-medium mr-2 mt-[20px]"> Likes & Dislikes Over time:</div>
          <!-- container with border-->
          <div class="flex flex-col gap-3 border-t border-r border-l border-slate-200 rounded-xl pl-4 mt-[12px]" :class="[activeLearnerLikesAndDislikes <=0 ? 'p-1 mt-0 border-b':'pt-4']">
          
            <div v-if="activeLearnerLikesAndDislikes <=0" class="text-sm text-slate-400">Coming soon </div>
            
            <div v-for="(likeAndDislike,index) in activeLearnerLikesAndDislikes" :key="likeAndDislike.name">
                
              <div class="border-b pb-4 border-slate-300 mr-2">
                <div class="font-medium text-sm mr-2 mb-1">{{ moment(likeAndDislike.creation).format('Do MMMM YYYY') }}</div>
                    <div>
                      <span class="font-medium mr-2 text-sm"> Likes:</span>
                      <span class="text-slate-500 text-sm"> {{ likeAndDislike.likes }}</span>
                    </div> 

                    <div>
                      <span class="font-medium mr-2 text-sm"> Dislikes:</span>
                      <span class="text-slate-500 text-sm"> {{ likeAndDislike.dislikes }}</span>
                    </div> 
              </div>
            </div>     

          </div>  
        


        <!-- Observation summaries -->
        <div id="observation-summaries" class="font-medium mr-2 mt-[20px] mb-[12px]"> Observation Summaries:</div>
        <div class="border border-slate-200 rounded-lg">
            <!-- Header row -->
            <div class="h-[40px] border-b border-slate-200 flex items-center bg-[#FFFAE6]"><div class="mr-20 ml-4"> Month</div> <div>Summary</div></div>
            
            <!-- Body rows -->
            <div class="text-sm text-slate-400 mb-2 mt-2 ml-2"> Observations summary coming soon </div>
            <!-- <div v-for="(observation,index) in activeLearnerObservations" :key="`summary-${observation.name}`" class="">
              <div class="h-[40px]  flex overflow-auto items-center border-t border-slate-200 hover:bg-[#FFF6D4]">
                <div class="text-slate-500 font-medium text-sm mt-1 ml-4 ">{{ moment(observation.creation).format('Do MMMM YYYY') }}</div>
                <div class="w-[70%] text-clip ml-6">{{ observation.anecdotes }}</div>
              </div>
            </div>  -->
        </div>   
        
        <!-- Daily Observations widgets -->
        <div id="daily-observations" v-if="dailyObservations">
            <div class="font-medium mr-2 mt-[20px]"> Daily Observations:</div>
            <div class="text-sm text-slate-400 mt-[12px]">Daily observations coming soon </div>
            <div v-for="(observation,index) in activeLearnerObservations" :key="observation.name" class="mt-4 border p-4 w-full md:w-[280px] lg:w-[280px] border-slate-400 rounded-lg">
              <div class="text-sm md:text-normal"> {{learner.student_name}}'s Daily Observation</div>
              <div class="text-slate-500 font-medium text-sm mt-1"> {{ moment(observation.creation).format('Do MMMM YYYY') }} </div>
            </div>
        </div>
        
        
      </div>

    </div>
 </div>

</div> 

</template>
  
  <script setup>
  import { ref, onMounted, inject,watch, computed } from 'vue';
  import moment from 'moment';
  import { useRoute } from 'vue-router';

  

      const learnerAndUserProfileDetails = ref([]);
      const dailyObservations = ref([]);
      const likesAndDislikesOverTime = ref([]);
      // Inject the $call method
      const $call = inject('$call');
      const activeTabIndex = ref(0);
      const activeLearnerId = ref('');
      const activeSection = ref('');

      const route = useRoute();

      // funtion to fetch images for photo essays widget
      const fetchProfileDetails = async () => {
        try {
          const response = await $call('curriculum_planner.curriculum_planner.common.user_details.api.fetch_learner_and_user__profile_details');
          learnerAndUserProfileDetails.value = response;

          console.log("This is response of learner and user profile",learnerAndUserProfileDetails.value)
         
        } catch (error) {
          console.error('Error fetching recent images:', error);
        }
      };

      // Funtion to fetch list of daily observations from Daily Observations V2
      // const fetchDailyObservations = async () => {
      // try {
      //   const response = await $call('curriculum_planner.curriculum_planner.doctype.daily_observations_v2.api.fetch_daily_observations_v2');
      //   dailyObservations.value = response;
      //   console.log("This is the reponse of fetched observastions list of v2",response)
      //   } catch (error) {
      //     console.error('Error fetching daily observations:', error);
      //   }
      // };

      // Funtion to fetch likes and dislikes of learners overtime 
      const fetchLikesAndDislikesOverTime = async () => {
        try {
          const response = await $call('curriculum_planner.curriculum_planner.doctype.likes_and_dislikes_over_time.api.fetch_likes_and_dislikes_over_time');
          likesAndDislikesOverTime.value = response;
          console.log("This is the reponse of fetched likes and dislikes over time+++++++++",response)
        } catch (error) {
          console.error('Error fetching daily observations:', error);
        }
      };

      // function to return active student name based on id
      const activeStudentName = computed(() => {
        if (learnerAndUserProfileDetails.value.learners && activeLearnerId.value) {
          const matchedLearner = learnerAndUserProfileDetails.value.learners.find(
            learner => learner.name === activeLearnerId.value
          );
          return matchedLearner ? matchedLearner.student_name : '';
        }
        return '';
      });

      // Funciton to return likes and dislike overtime vlues of active learner tab
      const activeLearnerLikesAndDislikes = computed(() => {
        if (likesAndDislikesOverTime.value && activeLearnerId.value) {
          const matchedLearner = likesAndDislikesOverTime.value.find(
            learner => learner.student_id === activeLearnerId.value
          );
          return matchedLearner ? matchedLearner.likes_and_dislikes : [];
        }
        return [];
      });

    // Funtion to retun obseration summary of active learner's tabs
    const activeLearnerObservations = computed(() => {
      if (dailyObservations.value && activeLearnerId.value) {
        const matchedLearner = dailyObservations.value.find(
          learner => learner.student_id === activeLearnerId.value
        );
        return matchedLearner ? matchedLearner.observations : [];
      }
      return [];
    });


    const calculateAge = (dateOfBirth) => {
      if (!dateOfBirth) {
          return 'NA';
      }

      const dob = new Date(dateOfBirth);
      if (isNaN(dob.getTime())) {
          return 'NA';
      }

      const now = new Date();
      const yearsDiff = now.getFullYear() - dob.getFullYear();
      const monthsDiff = now.getMonth() - dob.getMonth();

      let years = yearsDiff;
      let months = monthsDiff;

      if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
          years--;
          months = 12 + monthsDiff;
      }

      if (years === 0) {
          return `${months} month${months !== 1 ? 's' : ''}`;
      } else if (months === 0) {
          return `${years} year${years !== 1 ? 's' : ''}`;
      } else {
          return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`;
      }
  };


    const scrollToSection = (sectionId) => {
      console.log ("This is section id", sectionId)
      const container = document.getElementById('scroll-container');
      const section = document.getElementById(sectionId);
      if (container && section) {
        const offset = 50; // Adjust this value as needed
        const containerRect = container.getBoundingClientRect();
        const sectionRect = section.getBoundingClientRect();
        const top = sectionRect.top - containerRect.top + container.scrollTop - offset;

        container.scrollTo({
          top: top,
          behavior: 'smooth'
        });

        activeSection.value = sectionId;
        console.log("This is activeSection.value after assigning it learners id",sectionId)
      }
    };

    const onScroll = () => {
      const container = document.getElementById('scroll-container');
      const sections = container.querySelectorAll('section');
      sections.forEach((section) => {
        const sectionRect = section.getBoundingClientRect();
        if (sectionRect.top <= 100 && sectionRect.bottom >= 100) {
          activeSection.value = section.id;
        }
      });
    };


      onMounted(async () => {

        await fetchProfileDetails();
        if (learnerAndUserProfileDetails.value.learners && learnerAndUserProfileDetails.value.learners.length > 0) {
          activeLearnerId.value = learnerAndUserProfileDetails.value.learners[activeTabIndex.value]?.name || '';
        }

        if (route.query.learner) {
          activeLearnerId.value = route.query.learner;
          const index = learnerAndUserProfileDetails.value.learners.findIndex(
            learner => learner.name === activeLearnerId.value
          );
          if (index !== -1) {
            activeTabIndex.value = index;
            // Use setTimeout to ensure the DOM is fully rendered
            setTimeout(() => {
              const tab = document.querySelector(`#segment-item-${index + 1}`);
              if (tab) {
                tab.click();
              }
            }, 0);
          }
        }
        // fetchDailyObservations();
        fetchLikesAndDislikesOverTime();
        const tabButtons = document.querySelectorAll('[data-hs-tab]');
        tabButtons.forEach((button, index) => {
          button.addEventListener('click', () => {
            activeTabIndex.value = index;
          });
        });

        const container = document.getElementById('scroll-container');
        container.addEventListener('scroll', onScroll);

      });


      watch(activeLearnerId, (newValue, oldValue) => {
        console.log('Active learner ID changed:', newValue);
      });
    
      watch(activeSection, (newValue, oldValue) => {
        console.log('activeSection changed:', newValue);
      });
      
  </script>
  