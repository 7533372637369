<template>
  <div class="flex flex-col gap-4 pb-8">
    <h2 class="font-semibold text-xl">
      All Activities
    </h2>
    <div class="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8 lg:mr-[168px] items-stretch">
      <div v-if="activities.length" v-for="activity in activities" :class="cn('basis-1/3 flex flex-col justify-center min-h-80',
        // !activity.summary && 'hidden'
      )">
      <!-- {{ activity }} -->
        <ActivitySearchCard
          :activity="activity" />
      </div>
    </div>
    <div class="flex justify-center">
      <span class="text-gray-600 font-medium text-sm cursor-pointer" @click="loadMoreActivities"
        v-if="activityResource.data?.pagination && currentPage < activityResource.data.pagination.total_pages">
        Load More...
      </span>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { createResource } from 'frappe-ui'
import { ref, onMounted, computed, watch } from 'vue';
import ActivitySearchCard from './view/ActivitySearchCard.vue';
import { cn } from '../ui/utils';

const route = useRoute()

const activities = ref([])
const isFetchingActivities = ref(false)
const currentPage = ref(1)
const loadEmptyActivities = ref(false)

const activityResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity.api.get_search_results',
 params: {
  search_key: '',
  empty: loadEmptyActivities.value,
 },
//  auto: true
})

const fetchActivities = async (page = 1) => {
  const searchKey = route.params.searchKey;
  isFetchingActivities.value = true;
  try {
    await activityResource.submit({
      search_key: searchKey || '',
      empty: loadEmptyActivities.value,
      page: page,
    })

    if (activityResource.data.status === 'success') {
      currentPage.value = activityResource.data.pagination.current_page
      console.log("Imported Activities", activityResource)
      activities.value.push(...activityResource.data.activities)
    } else {
      console.log("Failed to fetch activities")
      console.error(activityResource.data.message)
    }
  } catch (error) {
    console.error("Failed to make activity fetch request", error)
  }
  isFetchingActivities.value = false;
}

onMounted(async () => {
  fetchActivities();
}),


watch(route, () => {
  // activityResource.reset()
  // activityResource.update({
  //   params: {
  //     search_key: route.params.searchKey,
  //     page: 1,
  //     empty: loadEmptyActivities.value,
  //   }
  // })
  // activityResource.reload()
  console.log("ROUTE XHANGED")
  console.log("Loadempty", loadEmptyActivities.value)
  console.log("Fetchign activities for new routs", route)
  fetchActivities(1)
  console.log(activityResource)
})

// const loadedWOFail = computed(() => {
//   if (!activityResource.data) 
//     return false
//   else if (activityResource.data.status === 'fail')
//     return false
//   else
//     return true
// })

const loadMoreActivities = async () => {
  const totalPages = activityResource.data.pagination.total_pages;

  if (currentPage.value >= totalPages) return;
  // isFetchingActivities.value = true
  console.log("Loading more activities")
  // isFetchingActivities.value = false
  await fetchActivities(currentPage.value + 1);
}
</script>