<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">Your name:</label>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
        id="activity-observ-form-date" name="educator_id" 
        :value="props.userData.has_educator_doc ? props.userData.educator_id : ''">
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        readonly:opacity-50 readonly:pointer-events-none
        font-normal opacity-70" 
        id="activity-observ-form-date" name="educator_name" :readonly="true"
        :value="props.userData.has_educator_doc ? props.userData.educator_name : ''" autocomplete="off">
    </div>
    <div class="space-y-3">
      <p class="font-medium text-sm md:text-base">
        Name of the child for whom observations are made:
      </p>
      <div class="border rounded-md z-100" @keydown.stop>
        <input type="text" name="student_name" class="hidden" v-model="studentId" />
        <StudentSelect :studentsList="props.studentsList" selectId="weeklyChildFormMainSelect"
          @valueModified="handleStudentChange" />
      </div>
    </div>
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">
        Date of observation:
      </label>
      <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        font-normal" 
        id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
        required @change="emitDate" @click="openDatePicker">
    </div>
  </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, ref } from 'vue'
import StudentSelect from '../DailyForm/StudentSelect.vue'
import { findValueByName } from '@/lib/utils.js'
// import FormFooter from '../FormFooter.vue';
import { openDatePicker } from '@/lib/utils';

const props = defineProps(['pageNum', 'userData', 'studentsList'])
const emit = defineEmits(['date:selected', 'additionalSelected'])

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const dateValue = ref(formattedDate)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}

const studentId = ref('')
const handleStudentChange = (newStudentId) => {
  console.log("newStudent value", newStudentId)
  studentId.value = newStudentId;
}
</script>