<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Group Observation <span v-if="mode===2" class="text-sm">(edited)</span>
              <span v-if="isArchived" class="text-sm">(archived)</span>
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col" ref="formRef">
          <div :class="cn(`max-h-[70vh] overflow-y-auto`, isCommenting && 'max-h-[30dvh]')">
            <ActivityObservPreview v-if="mode === 0" :observation="props.observation" :version="props.version" />
            <ActivityObservEditPage1 v-if="mode === 1" :observation="props.observation" :pageNum="pageNum"
              :userData="userDetails" />
            <ActivityFormSummaryPage v-if="mode === 1" :observation="props.observation" :pageNum="pageNum"
              :componentPageNum="2" />
            <ActivityFormEngagePage  v-if="mode === 1" :observation="props.observation" :pageNum="pageNum"
              :componentPageNum="3" :cohorts="engageCohorts" />
            <ActivityFormPrimaryPage v-if="mode === 1" :pageNum="pageNum" :componentPageNum="4"
              :observation="props.observation" :competencyList="competencyList" :version="props.version" />
            <ActivityEditSecondary v-if="mode === 1" :pageNum="pageNum" :componentPageNum="5" 
              :observation="props.observation" :competencyList="competencyList" />
            <ActivityFormEvidencePage v-if="mode === 1" :pageNum="pageNum" :componentPageNum="6"
              :observation="props.observation" />
            <ActivityFormRatingPage v-if="mode === 1" :pageNum="pageNum" :componentPageNum="7"
              :observation="props.observation" />
            <ActivityFormWorthPage v-if="mode === 1" :pageNum="pageNum" :componentPageNum="8"
              :observation="props.observation" />
            <ActivityFormWorthPage2 v-if="mode === 1" :pageNum="pageNum" :componentPageNum="9"
              :observation="props.observation" />
            <ActivityFormNotesPage v-if="mode === 1" :pageNum="pageNum" :componentPageNum="10"
              :observation="props.observation" /> 
            <ActivityObservPreview v-if="mode === 2" :observation="previewData" :isSavePreview="true"
              :version="props.version" />
            <div>
              <CommentsModule v-if="mode === 0 && fetchCommentsResource.data?.status === 'success'"
                :comments="fetchCommentsResource.data.comments" />
              <div ref="commentsRef"></div>
            </div>
            <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
              hideInputWarning && 'hidden')">
              Please answer all the required questions!
            </div>
          </div>
          <ObservationCommentInput v-if="isCommenting" :isSavingComment="isSavingComment" 
            :commentResponse="commentSaveResponse" :userList="taggableUsers" 
            @insert:tag="addToTags" />
          <div class="p-3">
            <span v-if="isSavingComment" :class="cn(`text-sm font-semibold text-gray-600 text-center`)">
              Saving comment... Please wait...
            </span>
            <span v-if="commentSaveResponse" :class="cn(`text-sm font-semibold text-red-500 text-center`)">
              {{ commentSaveResponse }}
            </span>
            <span v-if="isCommmentSaveSuccess":class="cn(`text-sm font-semibold text-green-600 text-center`)">
              Comment saved!
            </span>
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-end">
            <div class="flex md:justify-end items-center gap-4 py-3 px-4 flex-wrap">
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')" 
                :data-hs-overlay="`#hs-${props.modalName}`" :disabled="mode !== 0 || isCommenting">
                Close
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || props.version === 1 || props.version === 2 || isCommenting" @click="mode = 1">
                Edit
              </button>
              <button type="button" :class="cn(`py-2 inline-flex md:px-10 flex-auto md:basis-auto 
                items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white justify-center
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || props.version === 1 || isCommenting" @click="isCommenting = true">
                Comment
              </button>
              <div :class="cn(`flex gap-2`, !isCommenting && 'hidden')">
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                  rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || !isCommenting" @click="isCommenting = false">
                  Discard
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || props.version === 1 || !isCommenting || isSavingComment" @click="saveComment">
                  Save
                </button>
              </div>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode === 0 && 'hidden', isCommenting && 'hidden')" 
                @click="goToPrevPage" :disabled="(mode !== 1 && mode !== 2) || pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum >= totalPageNum && 'hidden')" 
                @click="goToNextPage" :disabled="mode !== 1 ||pageNum >= totalPageNum">
                Next
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum !== totalPageNum && 'hidden')" 
                @click="showPreview" :disabled="mode !== 1 || pageNum != totalPageNum">
                Preview
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 2 && 'hidden')" 
                :disabled="mode !== 2">
                Save
              </button>
              <button type="button" :class="cn(`py-2 flex-auto inline-flex items-center gap-x-2 text-sm font-semibold
                md:basis-auto md:px-10 md:flex-initial
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none justify-center`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleArchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || isArchived
                || isCommenting">
                Archive
              </button>
              <button type="button" :class="cn(`py-2 flex-auto inline-flex items-center gap-x-2 text-sm font-semibold
                md:basis-auto md:px-10 md:flex-initial
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none justify-center`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', !isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleUnarchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || !isArchived ||
                isCommenting">
                Unarchive
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, version, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { getFinalUserTagList } from '@/lib/utils.js';
import { HSOverlay } from 'preline/preline';
import { createResource, createListResource } from 'frappe-ui';
import ActivityObservPreview from './ActivityObservPreview.vue';
import ActivityObservEditPage1 from './ActivityObservEditPage1.vue'
import ActivityFormSummaryPage from '@/components/observations/forms/ActivityForm/ActivityFormSummaryPage.vue'
import ActivityFormEngagePage from '@/components/observations/forms/ActivityForm/ActivityFormEngagePage.vue'
import ActivityFormPrimaryPage from '@/components/observations/forms/ActivityForm/ActivityFormPrimaryPage.vue'
import ActivityFormEvidencePage from '@/components/observations/forms/ActivityForm/ActivityFormEvidencePage.vue'
import ActivityFormRatingPage from '@/components/observations/forms/ActivityForm/ActivityFormRatingPage.vue'
import ActivityFormNotesPage from '@/components/observations/forms/ActivityForm/ActivityFormNotesPage.vue'
import ActivityEditSecondary from './ActivityEditSecondary.vue';
import ActivityFormWorthPage from '../../forms/ActivityForm/ActivityFormWorthPage.vue';
import ActivityFormWorthPage2 from '../../forms/ActivityForm/ActivityFormWorthPage2.vue';
import ObservationCommentInput from '../ObservationCommentInput.vue';
import CommentsModule from '../comments/CommentsModule.vue';

const props = defineProps([
  'modalName', 'observation', 'selectedSignal',
  'version', 'scrollSignal'
])
const userDetails = inject('userDetails')
const competencyList = inject('observationCompetencies')

const engageCohorts = ref([])

const mode = ref(0)  // 0 - view, 1 - edit, 2 - preview
const pageNum = ref(1)
const formRef = ref(null)
const previewData = ref(null)
const totalPageNum = 10

const isCommenting = ref(false)

watch(() => props.selectedSignal, () => {
  mode.value = 0
})

const hideInputWarning = ref(true)
const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const goToNextPage = () => {
  if (pageNum.value < 1 || pageNum.value >= totalPageNum.value) return
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) { 
    const eduId = formData.get('educator_id')
    const date = formData.get('date')
    const cohort = formData.getAll('cohorts')
    const time = formData.get('activity_time')
    if (Array.isArray(cohort)) engageCohorts.value = cohort
    if (!(eduId && date && cohort)) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 2) { 
    const summary = formData.get('summary')
    if (!summary) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 4) { 
    if(!formData.get('primary_competency')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 5) {
    if(!formData.get('secondary_competencies')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 6) {
    if(!formData.get('creativity_evidence')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 7) {
    if (!formData.get('activity_rating')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 8) {
    if (props.observation.worth_repeating && !formData.get('worth_repeating')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  } else if (pageNum.value === 9) {
    if (props.observation.worth_building && !formData.get('worth_building')) {
      hideInputWarning.value = false
      setHideWarningTime(1500)
      return
    }
  }
  

  hideInputWarning.value = true
  pageNum.value++
}

const goToPrevPage = () => {
  if (mode.value === 2) mode.value = 1
  if (pageNum.value <= 1) return
  pageNum.value--
}

const showPreview = () => {
  const formData = new FormData(formRef.value)
  const formDataObj = {}
  const engagement = []
  
  formData.forEach((value, key) => {
      if (key.startsWith('engage-')) {
        const itemId = key.substring('engage-'.length)
        engagement.push({ child: itemId, engagement_level: value })
      }
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  formDataObj.engagement = engagement
  if (!Array.isArray(formDataObj.cohorts)) {
    formDataObj.cohorts = [formDataObj.cohorts]
  }
  if (!Array.isArray(formDataObj.secondary_competencies)) {
    formDataObj.secondary_competencies = [formDataObj.secondary_competencies]
  }
  if (!Array.isArray(formDataObj.other_secondary)) {
    formDataObj.other_secondary = [formDataObj.other_secondary]
  }
  if (formDataObj.primary_competency === 'Other') {
    formDataObj.primary_competency = { 
      competency: formDataObj.other_primary,
      is_id: true,
    }
  } else {
    formDataObj.primary_competency = {
      competency: formDataObj.primary_competency,
      is_id: false,
    }
  }

  console.log(formDataObj)
  previewData.value = formDataObj
  mode.value = 2
}

const observationSaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observations.api.edit_observation'
})
const isSaving = ref(false)

const handleSubmit = async (e) => {
  const formDataObj = previewData.value
  formDataObj.id = props.observation.name

  console.log(formDataObj)
  observationSaveResource.update({
    params: {
      observation_data: formDataObj,
      version: 'v2'
  }})
  try {
    isSaving.value = true
    await observationSaveResource.submit()
    isSaving.value = false
    if (observationSaveResource.data.status === 'success') {
      HSOverlay.close(`#hs-${props.modalName}`)
      pageNum.value = 1
      // HSOverlay.open('#hs-form-confirm-modal-2')
    } else if (observationSaveResource.data.status === 'fail') {
      alert("Form Submission Failed. Make sure you have filled everything correctly.")
    }
  } catch (error) {
    console.error(error)
  }
}

const archiveResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.api.archive_observation',
})

const isArchived = ref(props.observation?.is_archived === 1 ? true : false)

const fetchCommentsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observations.api.fetch_comments'
})

watch(() => props.observation, () => {
  isCommenting.value = false
  if (props.observation) {
    fetchCommentsResource.submit({
      observation_id: props.observation.name
    })
  }
  if (props.observation?.is_archived === 1)
    isArchived.value = true
  else if (props.observation?.is_archived === 0)
    isArchived.value = false
})

const handleArchive = async () => {
  archiveResource.update({
    params: {
      type: 'activity',
      observation_id: props.observation.name,
      archive_status: 1,
      version: `v${props.version}`
    }
  })
  
  try {
    await archiveResource.submit()
    if (archiveResource.data.status === 'success') {
      isArchived.value = true
    } else if (archiveResource.data.status === 'fail') {
      alert("Failed Archiving Observation.")
    }
  } catch (error) {
    console.error(error)
  }
}

const handleUnarchive = async () => {
  archiveResource.update({
    params: {
      type: 'activity',
      observation_id: props.observation.name,
      version: `v${props.version}`,
      archive_status: 0,
    }
  })
  
  try {
    await archiveResource.submit()
    if (archiveResource.data.status === 'success') {
      isArchived.value = false
    } else if (archiveResource.data.status === 'fail') {
      alert("Failed Unarchiving Observation.")
    }
  } catch (error) {
    console.error(error)
  }
}

const taggableUsers = computed(() => {
  if (!userDetails?.value?.school_info) return []
  return userDetails.value.school_info.educators
    .filter((educator) => !!educator.user_link)
    .map((educator) => {
      return {
        value: educator.educator_name,
        label: educator.educator_name,
        name: educator.user_link,
      }
    })
})

const taggedUsers = ref([])

const addToTags = (item) => {
  if (!taggedUsers.value.includes(item))
    taggedUsers.value.push(item)
}

const isSavingComment = ref(false)
const commentSaveResponse = ref('')
const isCommmentSaveSuccess = ref(false)

const commentSaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.activity_observations.api.save_comment'
})

const saveComment = async () => {
  isSavingComment.value = true
  const formData = new FormData(formRef.value)
  const comment = formData.get('comment')
  const finalTags = getFinalUserTagList(comment, taggedUsers.value)
  const taggedUserNames = finalTags.map((user) => user.name).join(',')
  console.log("Tagged Users: ", taggedUsers.value)
  console.log("Final Tags", taggedUserNames)

  console.log(comment)
  console.log(props.observation)
  try {
    await commentSaveResource.submit({
      comment_data: {
        'observation_id': props.observation.name,
        'comment': comment,
        'tagged_users': taggedUserNames || '',
      },
      version: `v${props.version}`
    })

    if (commentSaveResource.data.status === 'success') {
      fetchCommentsResource.reload()
      isSavingComment.value = false
      isCommenting.value = false
      isCommmentSaveSuccess.value = true
      setTimeout(() => {
        isCommmentSaveSuccess.value = false
      }, 1500)
    } else if (commentSaveResource.data.status === 'fail') {
      isSavingComment.value = false
      commentSaveResponse.value = commentSaveResource.data.message
      setTimeout(() => {
        commentSaveResponse.value = ''
      }, 1500)
      console.error("Failed to save comment.")
      console.error(commentSaveResource.data.message)
    }
  } catch (error) {
    isSavingComment.value = false
    console.error(error)
  }
}

const commentsRef = ref(null)

watch(() => props.scrollSignal, () => {
  // console.log('scroll signal(new) is ', props.scrollSignal)
  // console.log("comments ref", commentsRef.value)
  setTimeout(() => {
    commentsRef.value.scrollIntoView({ behavior: 'smooth' });
  }, 1000)
})
</script>