<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`, isSelectingAdditional && 'hidden')" >
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">Your name:</label>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
        id="activity-observ-form-date" name="educator_id" 
        :value="props.userData.has_educator_doc ? props.userData.educator_id : ''">
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        readonly:opacity-50 readonly:pointer-events-none
        font-normal opacity-70" 
        id="activity-observ-form-date" name="educator_name" :readonly="true"
        :value="props.userData.has_educator_doc ? props.userData.educator_name : ''" autocomplete="off">
    </div>
    <div class="space-y-3">
      <p class="font-medium text-sm md:text-base">
        Name of the child for whom observations are made:
      </p>
      <div class="border rounded-md" @keydown.stop>
        <StudentSelect :studentsList="props.studentsList" ref="studentSelectRef"
          @valueModified="handleMainStudentChange" selectId="dailyFormMainSelect" />
      </div>
      <div class="text-xs md:text-sm font-medium text-gray-600 pt-3 pl-2 cursor-pointer hover:text-[#eac325]" 
        @click="isSelectingAdditional = true">
        <span v-if="!additionalStudents.length">Add more students...</span>
        <span v-else>+ 
          <span class="inline-flex gap-1">
            <span v-for="student, index in additionalStudents.slice(0,3)">
              {{ findValueByName(props.studentsList, student, 'student_name') }}
              <span v-if="index < 2 && index < additionalStudents.length - 1">,</span>
            </span>
          </span>
          <span v-if="additionalStudents.length > 3"> and 
            {{ additionalStudents.length - 3 }}
             more...</span>
        </span>
      </div>
    </div>
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">
        Date of observation:
      </label>
      <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        font-normal" 
        id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
        required @change="emitDate" @click="openDatePicker">
    </div>
  </div>
  <AddStudents :class="cn(!isSelectingAdditional && 'hidden')" :pageNum="props.pageNum" 
    :studentsList="props.studentsList" :exitFn="exitAdditionalSelectMode" 
    @selectionConfirmed="handleAdditionalSelection" :mainStudent="mainStudent" />
  <!-- <FormFooter :pageNum="props.pageNum" :goToNextPage="props.goToNextPage" :totalPageNum="props.totalPageNum"
    :goToPrevPage="props.goToPrevPage" /> -->
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import { computed, ref } from 'vue'
import StudentSelect from './StudentSelect.vue'
import AddStudents from './AddStudents.vue';
import { findValueByName } from '@/lib/utils.js'
// import FormFooter from '../FormFooter.vue';
import { openDatePicker } from '@/lib/utils';

const props = defineProps(['pageNum', 'userData', 'studentsList'])
const emit = defineEmits(['date:selected', 'additionalSelected'])

const isNameFilled = computed(() => {
  if (props.userData.is_educator) {
    return true
  }
  return false
})
const studentSelectRef = ref(null)

const areAllInputsFilled = () => {
  const pickedStudent = studentSelectRef.value.getPickedStudent()
  return isNameFilled.value && !!pickedStudent
}

const resetInputs = () => {
  studentSelectRef.value.resetSelectField()
  dateValue.value = formattedDate
}

defineExpose({areAllInputsFilled, resetInputs})

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const dateValue = ref(formattedDate)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}

const isSelectingAdditional = ref(false)
const additionalStudents = ref([])
const mainStudent = ref('')

const exitAdditionalSelectMode = () => {
  isSelectingAdditional.value = false
}

const handleAdditionalSelection = (studentList) => {
  additionalStudents.value = studentList
  emit('additionalSelected', studentList)
}

const handleMainStudentChange = (studentId) => {
  mainStudent.value = studentId
}
</script>