<template>
  <div class="overflow-auto border">
    <Dashboard v-if="uppy" :uppy="uppy" :props="{
        inline: true,
        hideUploadButton: true
      }" />
    
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits, watch } from 'vue';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { Dashboard } from '@uppy/vue';

import GoldenRetriever from '@uppy/golden-retriever';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import { toRaw, isReactive } from 'vue';


// Inject the $call method
const $call = inject('$call');

const props = defineProps({
  startUpload: Boolean,
  formData: Object,
  toggleUploadStatus: {
    type: Function,
    required: true
  },
  instanceId: {
    type: String,
    required: true, // e.g., 'new-album' or 'existing-album'
  },
});

// Define emits
const emit = defineEmits(['uploadStarted', 'uploadComplete', 'uploadError']);


const uppy = ref(null);
const filesSelected = ref(false);

const currentObjectKey = ref(null);  // Add this line to store the current object key
const isImageUploadStarted = ref(false);
const isVideoUploadStarted = ref(false);

const isImageUploadComplete = ref(false);
const isVideoUploadComplete = ref(false);

// Track total upload progress

const  totalFileNumber = ref(0);
const uploadedFileSize = ref(0); // Track the total bytes uploaded for both images and videos

const hasImages = ref(false);
const hasVideos = ref(false);

// Add these at the component level
const imageUploadProgress = ref(0);
const videoUploadProgress = ref(0);

// Function to update and emit the slower progress
const updateSlowerProgress = () => {
    let progress;

    // If both uploads are 0, we set progress to 0
    if (videoUploadProgress.value === 0 && imageUploadProgress.value === 0) {
      progress = 0;
    }
    // If image is complete but video is still in progress, continue showing video progress
    else if (imageUploadProgress.value === 100 && videoUploadProgress.value < 100) {
      progress = videoUploadProgress.value;
    }
    // If video is complete but image is still in progress, continue showing image progress
    else if (videoUploadProgress.value === 100 && imageUploadProgress.value < 100) {
      progress = imageUploadProgress.value;
    }
    // If both uploads are in progress, show the slower one
    else {
      progress = Math.min(imageUploadProgress.value, videoUploadProgress.value);
    }

    console.log("T: this is progress in slow function", progress);
    props.toggleUploadStatus({ 
      visible: true, progress: progress, details: { totalFiles: totalFileNumber.value, type: 'progress' } 
    });

  };


onMounted(async () => {
  const uppyInstanceId = props.instanceId === 'new-album' ? 'new-album-uppy' : 'existing-album-uppy';

  uppy.value = new Uppy({
    id: uppyInstanceId,
    restrictions: {
      allowedFileTypes: ['image/*', 'video/*'],
    },
    onRestored: () => {
      console.log('T:Files restored');
    },
    onBeforeUpload: (files) => {
      const filesToUpload = {};
      const imageFiles = [];

      Object.keys(files).forEach((fileID) => {
        const file = files[fileID];

        // If the file is a video, allow it to upload to S3
        if (file.meta.type === 'video') {
          filesToUpload[fileID] = file;
        
          hasVideos.value = true; // Mark that we have videos to upload
        } else if (file.meta.type === 'image') {

          // Add the raw file to the imageFiles array (but don't call addFile again)
          imageFiles.push(toRaw(file));
          
          hasImages.value = true; // Mark that we have images to upload
          uppy.value.removeFile(fileID);

        } else {
          console.error('Unsupported file type detected:', file.name);
          uppy.value.removeFile(fileID); // Remove unsupported files
        }
      });

          // If there are any images, handle them separately
          if (imageFiles.length > 0) {
            totalFileNumber.value += imageFiles.length //Add images count to total
            uploadPhotos(imageFiles);
          }
        
          totalFileNumber.value += Object.keys(filesToUpload).length; // Add video files count to total

          // Return only video files to be uploaded to S3
          return filesToUpload;
        },
    })
    // .use(GoldenRetriever, {
    //   serviceWorker: true,
    //   indexedDB: {
    //     maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB
    //   }
    // })

    .use(AwsS3, {
    companionUrl: null,
    createMultipartUpload: async (file) => {
      console.log('createMultipartUpload called for file:', file.name);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.create_multipart_upload');
      console.log('createMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
      return { uploadId: response.uploadId, key: response.key };
    },
    listParts: async (file, { uploadId, key }) => {
      console.log('listParts called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.list_parts', { uploadId, key });
      console.log('listParts response:', response);
      if (!response.success) throw new Error(response.error);
      return response.parts;
    },
    signPart: async (file, { uploadId, key, partNumber, body }) => {
      console.log('signPart called for file:', file.name, 'uploadId:', uploadId, 'key:', key, 'partNumber:', partNumber);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.get_signed_url_for_part', { uploadId, key, partNumber });
      console.log('signPart response:', response);
      if (!response.success) throw new Error(response.error);
      return { url: response.url };
    },
    
    completeMultipartUpload: async (file, { uploadId, key, parts }) => {
      console.log('completeMultipartUpload called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      
      // Format parts correctly for S3 API
      const formattedParts = parts.map(part => ({
        PartNumber: part.PartNumber,
        ETag: part.ETag || part.etag // Handle both uppercase and lowercase
      }));
      
      console.log('Formatted parts to be sent:', formattedParts);
      
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.complete_multipart_upload', { uploadId, key, parts: JSON.stringify(formattedParts) });
      
      console.log('completeMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
      return { location: response.location };
    },

    abortMultipartUpload: async (file, { uploadId, key }) => {
      console.log('abortMultipartUpload called for file:', file.name, 'uploadId:', uploadId, 'key:', key);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.abort_multipart_upload', { uploadId, key });
      console.log('abortMultipartUpload response:', response);
      if (!response.success) throw new Error(response.error);
    },
    getUploadParameters: async (file) => {
      console.log('getUploadParameters called for file:', file.name);
      const response = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.get_presigned_video_upload_url');
      console.log('getUploadParameters response:', response);

     
      if (!response.success) throw new Error(response.error);

      const { success, presigned_url, object_key } = response 
      currentObjectKey.value = object_key
      
      return {
        method: 'PUT',
        url: response.presigned_url,
        fields: {},
        headers: {
          'Content-Type': file.type,
        },
      };
    },
  })
 
  // Check if Uppy is using the Dashboard plugin
  const dashboard = uppy.value.getPlugin('Dashboard');

  if (dashboard) {
  console.log("U:Uppy is using dashboard")
    // Wait for the Dashboard to be ready
    uppy.value.on('dashboard:mounted', () => {
      // Access the input element and set the `accept` attribute for images only
      const fileInput = dashboard.el.querySelector('.uppy-Dashboard-input');
      if (fileInput) {
        fileInput.setAttribute('accept', 'image/*');
        console.log('Set accept attribute to image/*');
      }
    });
  }
  uppy.value.on('state-update', (prevState, nextState, patch) => {
  // console.log('TT: state-update event triggered');
  // console.log('TT: Files in nextState:', nextState.files);
});

  // Event listener for file addition
  uppy.value.on('file-added', (file) => {
    console.log('TT: File added:', file);
    console.log('TT: File data:', file.data);
    if (file.type.startsWith('image/')) {
      uppy.value.setFileMeta(file.id, { type: 'image' });
      filesSelected.value = true;
    } else if (file.type.startsWith('video/')) {
      uppy.value.setFileMeta(file.id, {
        type: 'video',
        description: props.formData.description,
      });
      filesSelected.value = true;
    } else {
      alert('Unsupported file type detected');
      uppy.value.removeFile(file.id);
    }
  });

  uppy.value.on('upload-progress', async(file, response) => {
  
      const bytesUploaded = file.progress.bytesUploaded;
      const bytesTotal = file.progress.bytesTotal;

    
      const progress = (bytesUploaded / bytesTotal) * 100;
      videoUploadProgress.value = progress.toFixed(2)
      
      updateSlowerProgress();
    });

  uppy.value.on('upload-success', async(file, response) => {
    
    isVideoUploadComplete.value = true; // Mark video upload as complete

     checkForCompletion(); // Check if both uploads are complete

    // Trigger transcoding and meta data update
    try {
      const transcodingResponse = await $call('curriculum_planner.curriculum_planner.doctype.uploaded_video.api.transcode_uploaded_video', {
        object_key: currentObjectKey.value,  // Assuming the S3 object key is returned in the response
        description: props.formData.description,
        manual_date: props.formData.manualDate,
        selected_educator:props.formData.selectedEducator,
        tags:props.formData.tags
      
      });
      
      if (transcodingResponse.success) {
        console.log('Transcoding job created:', transcodingResponse.job_id);
      } else {
        console.error('Failed to create transcoding job:', transcodingResponse.error);
        props.toggleUploadStatus({ visible: true, progress:0, details: { totalFiles: totalFileNumber.value, type: 'error' } });

      }
    } catch (error) {
      console.error('Error triggering transcoding:', error);
      props.toggleUploadStatus({ visible: true, progress:0, details: { totalFiles: totalFileNumber.value, type: 'error' } });

    }

  });

 
  uppy.value.on('upload-error', (file, error, response) => {
    props.toggleUploadStatus({ visible: true, progress:0, details: { totalFiles: totalFileNumber.value, type: 'error' } });

  });

  uppy.value.on('restore:start', () => {
    console.log('Attempting to restore upload...');
  });

  uppy.value.on('restore-confirmed', (restoredFiles) => {
    console.log('TT:Successfully restored');
  });

  uppy.value.on('restore:error', (error) => {
    console.error('Error restoring uploads:', error);
  });
  

  uppy.value.on('file-removed', () => {
    filesSelected.value = uppy.value.getFiles().length > 0;
  });

  /// Method to start upload
  watch(() => props.startUpload, (newValue) => {
  if (newValue && uppy.value) {
    
    console.log("TT: startUpload triggered, newValue:", newValue);
    console.log("TT: uppy.value:", uppy.value);
    console.log("TT: filesSelected.value:", filesSelected.value);
    console.log("TT: Number of files in Uppy:", uppy.value ? uppy.value.getFiles().length : 'Uppy not initialized');
  

    uppy.value.upload(); // This should re-initiate the upload process
    emit('uploadStarted');
    isVideoUploadStarted.value = true;
    checkForBothUploads();
  } else {
    console.log("StartUpload triggered but no files selected or uppy instance is null");
    console.log("uppy.value:", uppy.value);
    console.log("filesSelected.value:", filesSelected.value);
    

  }
});


const uploadPhotos = async (files) => {
  console.log("t:upload images called");

  if (files.length > 0) {
    console.log("t:Upload photos: Starting Image the upload process", files);

    if (window.Worker) {
      console.log("t:Vue: Creating Image Web Worker.");

      const worker = new Worker(new URL('../photoEssays/upload_worker.js', import.meta.url));

      // Extract the actual File objects from Uppy files
      const rawFiles = files.map(file => file.data);
      console.log("P:This is Raw file", rawFiles);

      // Emit upload-started event to Uppy for each image file
      rawFiles.forEach(file => {
        uppy.value.emit('upload-started', file);
      });

      worker.postMessage({
        files: rawFiles, 
        tagsCsv: props.formData.tags,
        description: props.formData.description,
        manual_date: props.formData.manualDate,
        educator: props.formData.selectedEducator,
        csrfToken: window.csrf_token
      });

      worker.onmessage = (event) => {
          console.log("Vue: Received message from worker.", event);
          
          if (event.data.type === 'progress') {
            
            imageUploadProgress.value = event.data.percentage
            
            updateSlowerProgress();

        } else if (event.data.type === 'success') {

            isImageUploadComplete.value = true; // Mark image upload as complete
            checkForCompletion(); // Check if both uploads are complete

        } else if (event.data.type === 'error') {
            
          props.toggleUploadStatus({ visible: true, progress: 0, details: { totalFiles: totalFileNumber.value, type: 'error' } });
        }

          // this.isLoading = false;
          // this.backgroundProcessStarted = true;
          isImageUploadStarted.value = true; 
          checkForBothUploads();
          
        };

      } else {
        console.error("Vue: Web Workers are not supported in this browser.");
        props.toggleUploadStatus({ visible: true, progress: 0, details: { totalFiles: totalFileNumber.value, type: 'error' } });
      }
    }
    else {
        console.log("Vue: No files selected for upload.");
        alert("No files selected for upload")
      }
   };

  });

  
  const checkForBothUploads = () => {
  // If both images and videos are present, emit close event only if both have started
  if (hasImages.value && hasVideos.value) {
    if (isImageUploadStarted.value && isVideoUploadStarted.value) {
      
      emit('close-component');  // Emit close when both have started
    }
  } 
  // If only images are present, emit close event when image upload has started
  else if (hasImages.value && !hasVideos.value) {
    if (isImageUploadStarted.value) {
      
      emit('close-component');  // Emit close when image upload has started
    }
  } 
  // If only videos are present, emit close event when video upload has started
  else if (!hasImages.value && hasVideos.value) {
    if (isVideoUploadStarted.value) {
      
      emit('close-component');  // Emit close when video upload has started
    }
  }
};



const checkForCompletion = () => {
  // If both images and videos are present, emit the completion event only if both are completed
  if (hasImages.value && hasVideos.value) {
    if (isImageUploadComplete.value && isVideoUploadComplete.value) {
      
      emitCompletion();
    }
  } 
  // If only images are present, emit the completion event when image upload is completed
  else if (hasImages.value && !hasVideos.value) {
    if (isImageUploadComplete.value) {
      
      emitCompletion();
    }
  } 
  // If only videos are present, emit the completion event when video upload is completed
  else if (!hasImages.value && hasVideos.value) {
    if (isVideoUploadComplete.value) {
      
      emitCompletion();
    }
  }
};

// Helper function to handle success message and close the form
const emitCompletion = () => {
  
  props.toggleUploadStatus({ 
    visible: true, 
    message: 'All uploads completed successfully!',
    details: { type: 'success', totalFiles: totalFileNumber.value } 
  });

  emit('close-component');  // Close component after all relevant uploads are complete
};



</script>

<style>

 .uppy-Dashboard-Item-previewInnerWrap {

  background-color: #FEF3C2 !important;
}

.uppy-Dashboard-inner{
  height: 400px !important;
}

@media screen and (max-width: 769px) {
  .uppy-Dashboard-inner{
  height: 320px !important;
}
}

</style>