<template>
  <!-- Buttons -->
  <div class="flex justify-between items-center w-100% md:sticky md:top-[8.5%] z-5 md:bg-white md:p-2">
   
    <!-- Ordered buttons and divs -->
    <h2
      class="flex justify-center hidden md:block text-small md:text-2xl text-black text-center font-medium items-center">
      {{ this.headerMonthYear }}
    </h2>

    <div class="flex justify-between w-[100%] md:w-auto">

      <!--Week navigation button group  -->
      <div class=" inline-flex rounded-lg shadow-sm mr-2 md:mr-0" style="background-color:rgba(253, 216, 53, 1)">

        <button v-if="viewMode === 'weekly'" @click="goToPreviousWeek" type="button"
          class="py- px-3 md:mr-0 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.93494 0.440056C6.97569 0.480696 7.00801 0.528974 7.03007 0.582126C7.05212 0.635278 7.06348 0.692259 7.06348 0.749806C7.06348 0.807352 7.05212 0.864333 7.03007 0.917485C7.00801 0.970637 6.97569 1.01892 6.93494 1.05956L1.99382 5.99981L6.93494 10.9401C6.97562 10.9807 7.00789 11.029 7.0299 11.0822C7.05192 11.1353 7.06325 11.1923 7.06325 11.2498C7.06325 11.3073 7.05192 11.3643 7.0299 11.4174C7.00789 11.4706 6.97562 11.5189 6.93494 11.5596C6.89427 11.6002 6.84598 11.6325 6.79283 11.6545C6.73968 11.6765 6.68272 11.6879 6.62519 11.6879C6.56767 11.6879 6.51071 11.6765 6.45756 11.6545C6.40441 11.6325 6.35612 11.6002 6.31544 11.5596L1.06544 6.30956C1.0247 6.26892 0.992377 6.22064 0.970321 6.16748C0.948266 6.11433 0.936913 6.05735 0.936913 5.99981C0.936913 5.94226 0.948266 5.88528 0.970321 5.83213C0.992377 5.77897 1.0247 5.7307 1.06544 5.69006L6.31544 0.440056C6.35608 0.399313 6.40436 0.366988 6.45752 0.344932C6.51067 0.322876 6.56765 0.311523 6.62519 0.311523C6.68274 0.311523 6.73972 0.322876 6.79287 0.344932C6.84603 0.366988 6.8943 0.399313 6.93494 0.440056Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>

        <button type="button"
          class="py-1.5 px-1 md:py-2 md:px-3.5 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          Week
        </button>

        <button v-if="viewMode === 'weekly'" @click="goToNextWeek" type="button"
          class="py- px-3 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.06506 11.5599C1.02431 11.5193 0.991987 11.471 0.969932 11.4179C0.947876 11.3647 0.936523 11.3077 0.936523 11.2502C0.936523 11.1926 0.947876 11.1357 0.969932 11.0825C0.991987 11.0294 1.02431 10.9811 1.06506 10.9404L6.00618 6.00019L1.06506 1.05995C1.02438 1.01927 0.992112 0.970978 0.970097 0.91783C0.948083 0.864683 0.936752 0.80772 0.936752 0.750195C0.936752 0.692669 0.948083 0.635706 0.970097 0.582559C0.992112 0.529412 1.02438 0.481121 1.06506 0.440445C1.10573 0.399768 1.15402 0.367501 1.20717 0.345487C1.26032 0.323472 1.31728 0.312142 1.37481 0.312142C1.43233 0.312142 1.48929 0.323472 1.54244 0.345487C1.59559 0.367501 1.64388 0.399768 1.68456 0.440445L6.93456 5.69044C6.9753 5.73108 7.00762 5.77936 7.02968 5.83252C7.05173 5.88567 7.06309 5.94265 7.06309 6.00019C7.06309 6.05774 7.05173 6.11472 7.02968 6.16787C7.00762 6.22103 6.9753 6.2693 6.93456 6.30994L1.68456 11.5599C1.64392 11.6007 1.59564 11.633 1.54248 11.6551C1.48933 11.6771 1.43235 11.6885 1.37481 11.6885C1.31726 11.6885 1.26028 11.6771 1.20713 11.6551C1.15397 11.633 1.1057 11.6007 1.06506 11.5599Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>
      </div>
      <h2
        class="flex justify-center md:hidden md:mr-0 md:ml-0 mr-2.5 ml-2.5 block text-small md:text-2xl text-black text-center font-medium items-center">
        {{ this.headerMonthYear }}
      </h2>


      <!-- View mode button group -->
      <nav class=" flex ml-4 hs-tooltip inline-block [--trigger:click] [--placement:right]">
        <button type="button" :style="{
        backgroundColor: viewMode === 'weekly' ? 'black' : 'rgba(253, 216, 53, 1)',
        color: viewMode === 'weekly' ? 'white' : (viewMode === 'custom' ? 'black' : 'inherit')
      }" class="text-xs md:text-s rounded-l-lg md:w-[50px] w-[43px]" @click="toggleViewMode('weekly')">
          Week
        </button>

        <button type="button" :style="{
        backgroundColor: viewMode === 'monthly' ? 'black' : 'rgba(253, 216, 53, 1)',
        color: viewMode === 'monthly' ? 'white' : (viewMode === 'custom' ? 'black' : 'inherit')
      }" class="hs-tooltip-toggle text-xs md:text-s rounded-r-lg md:w-[50px] w-[43px]"> <!-- @click="toggleViewMode('monthly')" can be used here to call the view changing function -->
          Day
        </button>
        <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
            Day view coming soon! 
          </span>

      </nav>
    </div>

  </div>


  <!-- Tool bar  -->                                                <!--md:top-[14.9%]-->
  <div class="flex flex-col md:flex-row w-[100%] md:mt-0 mt-2 md:sticky md:top-[] z-20 md:bg-white md:pl-2 md:pr-2">

    <!-- Cohort colours lable container-->
    <div class="flex w-[100%] md:w-[50%] items-center justify-between md:justify-start">

      
      <!-- colour label preline component -->
      <div class="hs-accordion-group">
        <div class="hs-accordion" id="hs-basic-heading-three">
          <button
            class="hs-accordion-toggle hs-accordion-active: py-3 inline-flex items-center gap-x-1.5 w-full font-medium focus:outline-none focus:ring-2 focus:ring-transparent text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active: dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
            aria-controls="hs-basic-collapse-three">
            <svg class="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            <svg class="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14" />
            </svg>

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-0" style="background-color: rgba(147, 148, 255, 1);">
            </div> <span class="text-sm md:text-base">Bumble Bees</span>
            
            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-2"
              style="background-color: rgba(167, 238, 142, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">Butterflies</span>

          </button>
          <div id="hs-basic-collapse-three"
            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 mb-2"
            aria-labelledby="hs-basic-heading-three">
            <p class="text-gray-800 dark:text-gray-200">

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-2"
              style="background-color: rgba(255, 138, 0, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">Group event</span>

            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters container -->
    <div class="flex justify-end items-center mt-2 mb-2 w-[100%] md:w-[50%]">
      <!-- Select Cohort -->
      <div class="border w-[35%] rounded-[8px] mr-4" style="border-color: rgba(212, 215, 221, 1);">
        <div class="relative">

          <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
          "placeholder": "Cohorts",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
        }' class="hidden">
            <option v-if="this.userRoleView && ( this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" v-for="cohort in cohortsOptions" :key="cohort.name" :value="cohort.cohort_name">{{
              cohort.cohort_name
            }}</option>
            <option v-if="this.userRoleView && this.userRoleView== 'Parent'" v-for="option in uniqueCohortsForParentOptionsList":key="option.id" :value="option.name">
                  {{ option.name }}
            </option>
          </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>

      <!-- Select Facilitator -->
      <div v-if="this.userRoleView && ( this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" class="border w-[35%] rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
        <div class="relative">

          <select id="educatorsSelect" ref="educatorsSelect" multiple data-hs-select='{
          "placeholder": "Facilitator",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
        }' class="hidden">
            <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.educator_name">{{
        educator.educator_name }}</option>
          </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>

    </div>
  </div>

    <!--Metadata Tooltip Component -->
    <Tooltip :visible="showTooltip1" :position="tooltipPosition" style="z-index: 100">
      <div class="text-xs">
          Morning Activity
       <br>
          Activity Name
      </div>    
    </Tooltip> 

    <!-- Morning Activity logo Tooltip -->
    <Tooltip :visible="showMorningTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Activity
        </div>
    </Tooltip> 

    <!-- Afternoon Activity logo Tooltip -->
    <Tooltip :visible="showAfternoonTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Afternoon Activity
        </div>
    </Tooltip> 

    <!-- Shared Event logo Tooltip -->
    <Tooltip :visible="showSharedEventTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Shared Event
        </div>
    </Tooltip> 
  

  <!-- Table -->
  <div class="flex flex-col h-[100%] md:h-auto">
    <div class="h-[100%]">
      <div class="h-[100%]">
        <div class="fade-container overflow-y-auto md:overflow-auto h-[80%] md:h-[78vh] md:w-[100%] table-container">

          <table class="min-w-full border-gray-300 overflow-y-hidden table-fixed">
            
            <!-- Header/date section of table -->
            <thead>
              <tr>
                <th scope="col"
                  class="sticky top-0 left-0 md:top-[%] z-10 px-6 py-3 text-start text-xs font-medium text-gray-500 bg-white dark:bg-gray-800 border-r border-gray-300 md:min-w-[10rem]"
                  style="background-color:rgba(254, 243, 194, 1);"></th>

                <!-- Conditional rendering of date header row based on viewMode -->

                <!-- Week view  -->
                <template v-if="viewMode === 'weekly'">
                  <th style="background-color:rgba(254, 243, 194, 1);" scope="col" v-for="date in datesOfCurrentWeek"
                    :style="{ backgroundColor: isCurrentDate(date) ? 'rgba(253, 216, 53, 1)' : 'rgba(254, 243, 194, 1)' }"
                    :key="date"
                    :ref="`day-${date}`"
                    :id="`day-${date}`"
                    class="sticky top-0 z-5 md:top-[%] min-w-[105px] left-0 px-5 md:px-10 py-1 md:py-0 lg:py-2.5 text-start text-[13px] font-semibold text-black bg-white dark:bg-gray-800 border-r border-gray-300">
                    {{ date }}
                   
                    <!-- Template to render miscellaneous events -->
                    <template v-if="miscellaneousEvents !== null">
                      <div v-if="getActivityTitle(date)" class="text-[11.5px] mt-1 flex">
                        
                        <span class="text-gray-800 mr-0.5">{{ getActivityTitle(date).title }}</span>
                       
                        <!-- Preline dropdown -->
                        <div class="hs-dropdown relative inline-flex">
                          <button id="hs-dropdown-custom-trigger" type="button" class="hs-dropdown-toggle">
                            <span class="text-blue-500">{{ getActivityTitle(date).additionalEvents }}</span>
                          </button>
                          <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-dropdown-custom-trigger">
                            <div v-for="(event, index) in getActivityTitle(date).matchingEvents" :key="index" class="text-gray-700 text-sm mt-3">
                              {{ event.activity_title }}
                            </div>
                          </div>
                        </div>
                        <!-- dropdown end -->

                      </div>
                    </template>

                  </th>
                </template>               

                <!-- Month view -->
                <template v-else>
                  <th style="background-color:rgba(254, 243, 194, 1);" scope="col" v-for="date in datesOfMonth"
                    :key="date"
                    class="sticky top-0 px-6 py-3 text-start text-xs font-bold text-gray-500 uppercase bg-white dark:bg-gray-800 border-r border-gray-300 min-w-[rem]">
                    {{ date }}</th>
                </template>
              </tr>
            </thead>


          <!-- Body of the table -->
          <!-- Loop through sessions based on sessionOrder -->
          <template v-for="(session, index) in sessionOrder" :key="`session-${session}`">
            {{ Session }}
            <template v-for="(cohort, cohortIndex) in get_cohort_render_order(Object.keys(eventsByCohort))" :key="`cohort-${cohort}`">
              
              <template v-if="(cohortIndex === 1 && ['Arrival & Calm o\'clock', 'Circle Time', 'Lunch Time', 'Free Time'].includes(session))">
              <!-- Leave it blank to Avoid rendering repeating activvity more than once, we need to only render t once for any of the cohort -->
              </template>

              <template v-else>

                <template v-for="row in rowTypes" :key="`row-${cohort}-${row.label}`">
                  
                  <tr v-if="eventsExistInCohort(cohort,session) && session==='Lunch Time' && (row.key==='competency'|| row.key==='title')">
                    <!-- to remve rows from lunch time -->
                  </tr> 

                  <tr v-else-if="eventsExistInCohort(cohort,session) && session==='Arrival & Calm o\'clock' && (row.key==='competency'|| row.key==='title')">
                  <!-- To remove competeny row -->
                  </tr> 

                  <tr v-else-if="eventsExistInCohort(cohort,session)">
                    <!-- Meta data column -->
                    <td v-if="(['Arrival & Calm o\'clock', 'Circle Time', 'Lunch Time', 'Free Time'].includes(session))"
                    :style="{ 
                          'z-index': '5', 
                          'box-shadow': `inset -10px 0 0 0 ${getBoxShadowColorForCombined(row.label)}`,
                          borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9' 
                        }"
                        class="sticky-element sticky left-0 dark:bg-gray-800 px-3 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px] bg-white">
                      <div @mouseenter="showTooltip1 = true; updateTooltipPosition($event)" @mouseleave="showTooltip1 = false">
                        {{ checkMetaData(cohort, row.label,session) }}
                      </div>
                    </td>

                    <td v-else :style="{ 'z-index': '5', 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort), borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9' }"
                        class="sticky-element sticky left-0 dark:bg-gray-800 px-3 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px] bg-white">
                      <div @mouseenter="showTooltip1 = true; updateTooltipPosition($event)" @mouseleave="showTooltip1 = false">
                        {{ checkMetaData(cohort, row.label,session) }}
                      </div>
                    </td>

                    <!-- Loop through dates of the week -->
                    <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">
                      <td @click="handleCellClick({
                          cohort: cohort,
                          label: row.label,
                          id: getSessionEvent(cohort, dateOfWeek, session)?.id,
                          title: getSessionEvent(cohort, dateOfWeek, session)?.activity_name,
                          competency: getSessionEvent(cohort, dateOfWeek, session)?.competency,
                          start: getSessionEvent(cohort, dateOfWeek, session)?.start,
                          session:session
                        })" 
                        :style="{ borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9', borderRight: '1px solid #B9B9B9' }"
                        class="text-sm text-gray-800 cursor-pointer">
                        
                        <div v-if="getSessionEvent(cohort, dateOfWeek, session)" class="flex items-center space-x-2 max-w-[10rem] md:max-w-[12rem] md:pl-2 md:pr-1" 
                          :style="{ 'justify-content': row.key === 'led_by' ? 'space-between' : 'default' }">
                        
                          <!-- Logo for Arrival and Free Play -->
                            <template v-if="['Arrival & Calm o\'clock', 'Free Play & Break'].includes(session) && row.key === 'activity_session'">
                              <div @mouseenter="showMorningTooltip = true; updateTooltipPosition($event)" @mouseleave="showMorningTooltip = false"> 
                                <img src="@/assets/sol-calendar-logos/sol-morning-activity.png" alt="Morning logo" height="20" width="20" style="max-width: none;">
                              </div>
                            </template>
                            
                            <!-- Logo for Circle Time -->
                            <template v-else-if="['Circle Time'].includes(session) && row.key === 'activity_session'">
                              <div @mouseenter="showMorningTooltip = true; updateTooltipPosition($event)" @mouseleave="showMorningTooltip = false"> 
                                <img src="@/assets/circle_time_logo.png" alt="Circle Time logo" height="20" width="20" style="max-width: none;">
                              </div>
                            </template>
                            
                            <!-- Logo for all other activities (Afternoon activities) -->
                            <template v-else-if="row.key === 'activity_session'">
                              <div @mouseenter="showAfternoonTooltip = true; updateTooltipPosition($event)" @mouseleave="showAfternoonTooltip = false"> 
                                <img src="@/assets/sol-calendar-logos/sol-afternoon-activity.png" alt="Afternoon logo" height="20" width="20" style="max-width: none;">
                              </div>
                            </template>

                          <!-- Text content -->
                          <span class="truncate">{{ checkMorningRowData(row.key, eventsByCohort, cohort, dateOfWeek, session) }}</span>
                        </div>
                      </td>
                    </template>
                  </tr>
                </template>
              </template>

            </template>
          </template>


          </table>
          
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import moment from 'moment'; // Import Moment.js
import { onMounted, nextTick } from 'vue';
import ToolTip from './ToolTip.vue';

export default {
  inject: ["$call"],
  components: {
     Tooltip: ToolTip
   },
  props: {
    events: {
      type: Array,
      default: () => [] // Provide a default empty array if events prop is not passed
    },
    optionsResponse: {
      type: Object,
      required: true,
    },
    allCohortsList: {
      type: Array,
      default: () => []
    },
    userRoles: { // Define the userRoles prop
      type: Array,
      default: () => [],
    },
    filtersApplied: {
      type: Object,
      required: false
    },
    parentsChildInfo:{
      type: Array,
      required:false
    },
    userRoleView: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      viewMode: 'weekly',
      currentWeekStart: moment().startOf('isoWeek'),
      currentSessionFlag: null, // This will hold 'morning' or 'afternoon'

      cohortsOptions: [],
      educatorsOptions: [],
      headerMonthYear: moment().format("MMMM YYYY"),

      rowTypes: [
        { label: 'Activity Session', key: 'activity_session' },
        { label: 'Morning Activity', key: 'title' },
        { label: 'Morning Competency', key: 'competency' },
        { label: 'Led By', key: 'led_by' },
      ],

      sessionOrder : [
      "Arrival & Calm o'clock",
      "Circle Time",
      "Free Play & Break",
      "Academic Activity 1",
      "Academic Activity 2",
      "Academic Activity 3",
      "Lunch Time",
      "Free Time",
      "Hobby Class"
    ],
    sessionTimings: {
        "Arrival & Calm o'clock": "9:30 - 10:00",
        "Circle Time": "10:00 - 10:30",
        "Free Play & Break": "10:30 - 11:30",
        "Academic Activity 1": "11:30 - 12:10",
        "Academic Activity 2": "12:10 - 12:40",
        "Academic Activity 3": "12:40 - 13:30",
        "Lunch Time": "13:30 - 14:30",
        "Free Time": "14:30 - 15:00",
        "Hobby Class": "15:00 - 16:00"
      },
   

      currentFilter : {},
      miscellaneousEvents: null,

      showTooltip1: false,
      showMorningTooltip: false,
      showAfternoonTooltip:false,
      showSharedEventTooltip:false,
      tooltipPosition: { x: 0, y: 0 }
    };
  },
  
  mounted() {
    this.assignOptionsData();
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
      console.log("Preline initialised in TableView.vue");

      // Initialize the select element for cohorts
      const selectElement = window.HSSelect.getInstance('#cohortsSelect');
      if (selectElement) {
        selectElement.on('change', (value) => {
          console.log('Select value changed:', value);
          // Emits event with the new value
          this.$emit('cohorts-select-changed', { type: 'cohorts', value: value });

        });
      } else {
        console.log('AdvancedSelect instance not found');
      }

      // Initialize the select element for educators
      const educatorsSelectElement = window.HSSelect.getInstance('#educatorsSelect');
      if (educatorsSelectElement) {
        educatorsSelectElement.on('change', (value) => {
          console.log('Educator select value changed:', value);
          // Emits event with the new value for educators
          this.$emit('educators-select-changed', { type: 'educators', value: value });

        });
      } else {
        console.log('Educators AdvancedSelect instance not found');
      }


      // Call assignOptionsData here to ensure it runs after the above code
      console.log('OptionsResponse prop in table view:', this.optionsResponse);

    }, 100);

    this.scrollToDay();
    this.fetchMiscellaneousEvents()

  },
  watch: {
    miscellaneousEvents: {
      immediate: true,
      handler(newVal, oldVal) {
       
        if (newVal !== null) {
          nextTick(() => {           
            setTimeout(() => {
              window.HSStaticMethods.autoInit();
             
            }, 100); // Add a small delay if necessary
          });
        } else {
          console.log('newVal of miscellaneous events is null, not Reinitializing Preline');
        }
      },
    },
  },
  computed: {

    uniqueCohortsForParentOptionsList() {
    const seen = new Set();
    return this.optionsResponse.new_cohorts.filter((option) => {
      if (seen.has(option.name)) {
        return false;
      }
      seen.add(option.name);
      return true;
    });
  },

    currentDates() {
      // Return 'datesOfMonth' if viewMode is 'monthly', otherwise 'datesOfCurrentWeek'
      return this.viewMode === 'monthly' ? this.datesOfMonth : this.datesOfCurrentWeek;
    },
    datesOfMonth() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      let dates = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        // Format parts of the date separately
        const dayString = date.toLocaleDateString('default', { day: '2-digit' }); // '01'
        const monthString = date.toLocaleDateString('default', { month: 'short' }); // 'Jan'
        const weekdayString = date.toLocaleDateString('default', { weekday: 'short' }); // 'Mon'

        // Concatenate the parts in the desired order
        const dateString = `${dayString} ${monthString} ${weekdayString}`; // '01 Jan Mon'
        dates.push(dateString);
      }
      return dates;
    },
    datesOfCurrentWeek() {
      let week = [];
      for (let i = 0; i < 5; i++) {  // Loop from Monday to Friday
        week.push(this.currentWeekStart.clone().add(i, 'days').format("DD MMM ddd"));  // "01 Feb Mon" format
      }
      return week;
    },
  
 eventsByCohort() {
  console.log("RR:THis is filter applied value",this.filtersApplied)
  const sessionOrder = [
    "Arrival & Calm o'clock",
    "Circle Time",
    "Free Play & Break",
    "Academic Activity 1",
    "Academic Activity 2",
    "Academic Activity 3",
    "Lunch Time",
    "Free Time",
    "Hobby Class"
  ];

  const permanentSessions = ["Arrival & Calm o'clock", "Circle Time", "Lunch Time", "Free Time"];

  // Predefined template event structure for sessions with missing data
  const createTemplateEvent = (session, cohortName, date) => {
    let tempActivityName, tempCompetency, tempLedby;

    switch(session) {
      case "Arrival & Calm o'clock":
        tempActivityName = "Welcome and Settle In";
        tempCompetency = "";
        break;
      case "Circle Time":
        tempActivityName = "Breathing Exercise, Chants, Surya Namaskar, School Prayer";
        tempCompetency = "Physical wellness";
        tempLedby = "";
        break;
      case "Lunch Time":
        tempActivityName = "";
        tempCompetency = "";
        break;
      case "Free Time":
        tempActivityName = "Me time/self study ";
        tempCompetency = "Independent Learning";
        break;
      default:
        tempActivityName = "Placeholder Activity";
        tempCompetency = "General Skills";
    }

    return {
      id: `template-${session}-${date}-${cohortName}`,
      activity_name: tempActivityName,
      competency: tempCompetency,
      session: session,
      start: `${date} 09:00`, // Start and end time are placeholders
      end: `${date} 10:00`,
      led_by: tempLedby,
      cohorts: [cohortName]
    };
  };

  const parentCohorts = new Set(this.parentsChildInfo?.map(cohort => cohort.name) || []);
  const useParentCohorts = parentCohorts.size > 0;

  let grouped = {}; // Grouped events by cohort and date
  let tempEvents = []; // Temporary storage for events to be grouped

  this.events.forEach(event => {
    event.activity_sessions.forEach(sessionDetail => {
      const session = sessionDetail.session_name;
      if (!session) return;

      const sessionPriority = sessionOrder.indexOf(session);
      if (sessionPriority !== -1) {
        tempEvents.push({ event, session, priority: sessionPriority });
      } else {
        tempEvents.push({ event, session, priority: sessionOrder.length });
      }
    });
  });

  tempEvents.sort((a, b) => a.priority - b.priority);

  // Populate grouped events
  tempEvents.forEach(({ event, session }) => {
    const relevantCohorts = useParentCohorts ? event.cohorts.filter(cohort => parentCohorts.has(cohort)) : event.cohorts;
    const eventDate = moment(event.start, "YYYY-MM-DD").format("DD MMM ddd");

    relevantCohorts.forEach(cohortName => {
      if (!grouped[cohortName]) {
        grouped[cohortName] = {};
      }

      if (!grouped[cohortName][eventDate]) {
        grouped[cohortName][eventDate] = [];
      }

      const eventCopy = { ...event, session };
      grouped[cohortName][eventDate].push(eventCopy);
    });
  });

  // Add permanent template events for ALL cohorts
    if ( !this.filtersApplied || Object.keys(this.filtersApplied).length === 0 ||  
    (!this.filtersApplied.cohorts || this.filtersApplied.cohorts.length === 0) && 
    (!this.filtersApplied.educators || this.filtersApplied.educators.length === 0)
  ) {
      console.log("RR: filters is undefined adding permanent events")
        // Add permanent template events for ALL cohorts
          Object.keys(grouped).forEach(cohortName => {
            Object.keys(grouped[cohortName]).forEach(dateKey => {
              permanentSessions.forEach(permanentSession => {
                const sessionExists = grouped[cohortName][dateKey].some(event => event.session === permanentSession);

                if (!sessionExists) {
                  const templateEvent = createTemplateEvent(permanentSession, cohortName, dateKey);
                  grouped[cohortName][dateKey].push(templateEvent);
                }
              });

              // Sort events by session priority
              grouped[cohortName][dateKey].sort((a, b) => {
                return sessionOrder.indexOf(a.session) - sessionOrder.indexOf(b.session);
              });
            });
          });
        }


  // Apply filters if cohorts are selected
  if (('cohorts' in this.filtersApplied) && this.filtersApplied['cohorts'].length > 0) {
    let eventsToDisplay = {};
    this.filtersApplied['cohorts'].forEach(cohort => {
      if (cohort in grouped) {
        eventsToDisplay[cohort] = grouped[cohort];
      }
    });
    grouped = eventsToDisplay;
  }

  console.log("TT: These are Grouped Events", grouped);
  return grouped;
}


    


  },
  methods: {

  sessionExistsForDate(cohort, dateOfWeek, session) {
     
    // Check if the session exists in the cohort data for the given date
    const eventsForDate = this.eventsByCohort[cohort]?.[dateOfWeek] || [];
    // Perform the session check
    const result = eventsForDate.some(event => event.session?.trim() === session.trim());
    return result
  },
   // Retrieve the event for a given session, cohort, and date
    getSessionEvent(cohort, dateOfWeek, session) {
      const eventsForDate = this.eventsByCohort[cohort]?.[dateOfWeek] || [];
      const result = eventsForDate.find(event => event.session?.trim() === session.trim()) || null;
      return result;
    },

  checkMorningRowData(row, eventsByCohort, cohort, dateOfWeek, sessionName) {
      // Retrieve events for the cohort and date
      const eventsForDate = eventsByCohort[cohort]?.[dateOfWeek] || [];

      // Find the event by session name instead of session index
      const eventForSession = eventsForDate.find(event => event.session === sessionName);

      if (!eventForSession) {
        return ''; // If no event exists for this session, return an empty string
      }

      // Handle 'led_by' case with location information
      if (row === 'led_by' && eventForSession.location) {
        const rowData = eventForSession[row];
        const locationData = eventForSession.location;

        // Combine rowData and locationData
        let combinedData = rowData + ' (' + locationData + ')';

        // Check if the combined length exceeds 15 characters
        if (combinedData.length >= 15) {
          // Process rowData to keep only the first 3 characters of each name
          const processedRowData = rowData.map(name => name.slice(0, 3)).join('+');
          combinedData = processedRowData + ' (' + locationData + ')';
        }
        return combinedData;
      }

      // Handle the 'title' case
      if (row === 'title') {
        return eventForSession["title"] || eventForSession["activity_name"] || null;
      }

      // Render the Session name
      if(row==='activity_session'){
        
        return sessionName
      }

      // Handle other row cases
      const rowData = eventForSession[row];
      if (Array.isArray(rowData)) {
        return rowData.sort().join('+');
      } else {
        // If rowData is a string or a number, just return it
        return rowData;
      }
    },

    async assignOptionsData() {
      try {
        // Directly assign cohorts and educators from optionsResponse without filtering or sorting
        this.cohortsOptions = this.optionsResponse.cohorts;
        this.educatorsOptions = this.optionsResponse.educators;
      } 
      catch (error) {
        console.error('Error assigning data:', error);
      }
    },


    isHoliday(date) {
      return !Object.keys(this.eventsByCohort).some(cohort => {
        return this.eventsByCohort[cohort][date] && this.eventsByCohort[cohort][date].length > 0;
      });
    },
    
    updateTooltipPosition(event) {
      const boundingRect = event.target.getBoundingClientRect();
  this.tooltipPosition = {
    x: boundingRect.right + window.scrollX -80, // Position to the right of the element
    y: boundingRect.top + window.scrollY +10// Align with the top of the element
      }
    },
    inivitationToPlayExist(eventsByCohort, cohort, date) {
      return eventsByCohort[cohort] && eventsByCohort[cohort][date] && eventsByCohort[cohort][date].length>=1 && eventsByCohort[cohort][date][0]['session']
    },
    circleTimeExist(eventsByCohort, cohort, date) {
      return eventsByCohort[cohort] && eventsByCohort[cohort][date] && eventsByCohort[cohort][date].length>1  && eventsByCohort[cohort][date][1]['session']
    },

    checkMetaData(cohort, label,session) {
  
      if((session === "Lunch Time" || session === "Free Time" || session === "Arrival & Calm o\'clock" || session==="Circle Time") && label === 'Led By' ){
        return null
      }
      if((session === "Lunch Time") && label === 'Morning Competency' ){
        return null
      }

      else if (label === 'Led By') {
        // return 'Led By (Room)'
        return 'Led By'
      }
      else if (label == 'Morning Activity') {
        return 'Activity Name'
      }
      else if (label === 'Morning Competency') {
        return 'Competency'
      }
      else if (label === 'Activity Session') {

        const timings = this.sessionTimings[session];
          if (timings) {
            const [start, end] = timings.split(' - ');
            
            // Convert start and end times to 12-hour format using moment.js
            const formattedStart = moment(start, "HH:mm").format("h:mm");
            const formattedEnd = moment(end, "HH:mm").format("h:mm A");

            return `${formattedStart} - ${formattedEnd}`;
          }
          return 'None'
      }
      else {
        return ''
      }
    },
   
   
    
    eventsExistInCohort(cohort, session) {
      return this.currentDates.some(dateOfWeek => {
        // Check if there are events for the cohort on the given date and session
        const cohortEvents = this.eventsByCohort[cohort];
        if (cohortEvents && cohortEvents[dateOfWeek]) {
          // Check if there is an event for the specific session on this date
          return cohortEvents[dateOfWeek].some(event => event.session === session);
        }
        return false;
      });
    },

    
    morningEventExistInCohort(cohort) {
       
      const result = this.currentDates.some(dateOfWeek => {
        return (this.eventsByCohort[cohort]) && (this.eventsByCohort[cohort][dateOfWeek]) && (this.eventsByCohort[cohort][dateOfWeek].length>=1) && (this.eventsByCohort[cohort][dateOfWeek][0]) 
       
      });
      return result;
    },
    afternoonEventExistInCohort(cohort) {
      
      const result = this.currentDates.some(dateOfWeek => {
        return (this.eventsByCohort[cohort]) && (this.eventsByCohort[cohort][dateOfWeek]) && (this.eventsByCohort[cohort][dateOfWeek].length>1) && (this.eventsByCohort[cohort][dateOfWeek][1])
       
      });
      return result;
    },
    invitationToPlayEventsExistInCohort(cohort) {
     
      return this.currentDates.some(dateOfWeek => {
        if (this.eventsByCohort[cohort][dateOfWeek]) {
          var events = this.eventsByCohort[cohort][dateOfWeek];
          return 'session' in events[0] && events[0].session === 'Invitation to Play'
        } else {

          return false;
        }

      });
    },

    circleTimeEventsExistInCohort(cohort) {
      
      return this.currentDates.some(dateOfWeek => {
        if (this.eventsByCohort[cohort][dateOfWeek]) {
          var events = this.eventsByCohort[cohort][dateOfWeek];
          return (events.length>1) && ('session' in events[1]) && (events[1].session === 'Circle Time')
        } else {
          return false;
        }

      });
    },
    
 scrollToDay() {
    
    const specificDate = moment().format('DD MMM ddd');
    const dayElement = document.getElementById(`day-${specificDate}`);

    if (dayElement) {
      const offset = 113; // Adjust this value based on your layout's requirements
      const leftPosition = Math.max(dayElement.offsetLeft - offset, 0); 
    
      const container = document.querySelector('.table-container');

      if (container) {
        container.scrollTo({
          left: leftPosition,
          behavior: 'smooth'
        });
      } else {
        console.log("Error: Container not found.");
      }
    } else {
      console.log("Error: Day element not found for date", specificDate);
    }
  },

    get_cohort_render_order(cohort_list) {
     
      let cohort_order = [];

      if (cohort_list.indexOf('All') > -1) {
        cohort_list.splice(cohort_list.indexOf('All'), 1)
        cohort_order.push('All')
      }
      // Custom order of cohort names
        const customOrder = ['Bumble Bees','Butterflies'];

      // Sort the cohort_list based on the custom order
      cohort_list.sort((a, b) => {
        const indexA = customOrder.indexOf(a);
        const indexB = customOrder.indexOf(b);
        return indexA - indexB;
      });
      

      const orderedCohorts = [...cohort_order, ...cohort_list];
      return orderedCohorts;
    },
    getCategoryValue(event, category) {
      // Assuming each event is an object with properties matching category names
      // You might need to adjust the logic depending on your actual data structure
      if (event[category]) {
        return event[category];
      } else {
        return 'NA'; // Return 'NA' if the event doesn't have a value for the category
      }
    },

    getCohortColor(cohortName) {
      const colorMapping = {
        'Butterflies': 'rgba(167, 238, 142, 1)',
        'Bumble Bees': 'rgba(147, 148, 255, 1)',
        
      };

      // Return the color for the given cohort name, or a default color if not found
      return colorMapping[cohortName] || 'rgba(255, 138, 0, 1)'; // Default color
    },

    getBoxShadowColorForCombined(label) {
      switch(label) {


        case 'Activity Session':
          return 'rgba(147, 148, 255, 1)';
        case 'Morning Activity':
          return 'rgba(147, 148, 255, 1)'; 


        case 'Morning Competency':
          return 'rgba(167, 238, 142, 1)'; 
        case 'Led By':
          return 'rgba(167, 238, 142, 1)'; 
        

        default:
          return 'rgba(200, 200, 200, 1)'; // Default gray
      }
    },
    toggleViewMode(mode) {
      this.viewMode = mode;
    },

    goToPreviousWeek() {
      console.log("this is data before going to previous week", this.events.length)
      const previousWeekStart = this.currentWeekStart.clone().subtract(1, 'week');
      // Check if the month has changed by comparing the month of the new week start and the current week start
      if (previousWeekStart.month() !== this.currentWeekStart.month()) {
        // Emit an event with the start date of the new week, not necessarily the start of the month

        this.$emit('month-changed', previousWeekStart.format("YYYY-MM-DD"));
        this.headerMonthYear = previousWeekStart.format("MMMM YYYY") // To update the headerbar date
      }
      // Update the current week start to the previous week, maintaining weekly navigation
      this.currentWeekStart = previousWeekStart;
      console.log("this is data after going to previous week", this.events.length)
    },

    goToNextWeek() {
      const nextWeekStart = this.currentWeekStart.clone().add(1, 'week');
      // Check if the month has changed by comparing the month of the new week start and the current week start
      if (nextWeekStart.month() !== this.currentWeekStart.month()) {
        // Emit an event with the start date of the new week, not necessarily the start of the month
        this.$emit('month-changed', nextWeekStart.format("YYYY-MM-DD"));
        this.headerMonthYear = nextWeekStart.format("MMMM YYYY")
      }
      // Update the current week start to the next week, maintaining weekly navigation
      this.currentWeekStart = nextWeekStart;
    },

    updateSessionFlag(sessionValue) {
      this.currentSessionFlag = sessionValue.includes('Morning') ? 'morning' : 'afternoon';
      return true; // Return true to ensure the div is always rendered
    },
    handleCellClick(eventData) {
      console.log('Cell data:', eventData);
      if(eventData.id){
      this.$emit('event-clicked', {
        id: eventData.id,
        activity_name: eventData.title,
        start: eventData.date, 
        cohort: eventData.cohort,
        session:eventData.session,
        competency:eventData.competency

      });
    }
      
    },
    handleEmptyCellClick(eventData){
      console.log('Cell data:', eventData);
      
      const headerMonthYear = this.headerMonthYear; // e.g., "March 2024"
      const [monthName, year] = headerMonthYear.split(' ');
     
      // Combine the day from eventData.date with the month and year from headerMonthYear
      // Since eventData.date does not contain year, use the year from headerMonthYear
      const fullDateStr = `${eventData.start.split(' ')[0]} ${monthName} ${year}`; // e.g., "12 March 2024"
      
      const formattedDate = moment(fullDateStr, "DD MMMM YYYY").format("YYYY-MM-DD");
      
      this.$emit('empty-event-clicked', {
        cohort: eventData.cohort,
        start: formattedDate, 
        session: eventData.session
      });
      this.$emit('showNewEventForm');
    },
    
    emitShowNewEventForm() {
      this.$emit('showNewEventForm'); // Emit an event named 'showNewEventForm'
    },

    formatValue(value) {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // Join the array elements with a '+' sign
        return value.join(' + ');
      }
      // If not an array, return the value as is
      return value;
    },
    
    // Funtion to highlight today's header
    isCurrentDate(date) {
      const today = new Date();
      const [day, month] = date.split(' ');
      const targetDate = new Date(today.getFullYear(), this.getMonthIndex(month), parseInt(day));
      
      return today.toDateString() === targetDate.toDateString();
    },
    getMonthIndex(month) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      return months.indexOf(month);
    },
    // Funtion to fetch weekly miscellaneous events
    async fetchMiscellaneousEvents() {
    try {
      console.log("fetchMiscellaneousEvents called++++++++++++++++++++++++++++++++++++++++++++++++++++++")
      const today = moment();
      const startDate = today.startOf('week').format("YYYY-MM-DD");
      const endDate = today.endOf('week').format("YYYY-MM-DD");
      
      console.log("Fetching data between", startDate, "and", endDate);
      
      const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_miscellaneous_curriculum_events', {
        start_date: startDate,
        end_date: endDate,
      });

      console.log('Miscellaneous Events:', response);
      this.miscellaneousEvents = response
      // You can assign the response to a component data property or perform any other necessary operations
      
    } catch (error) {
      console.error('Error fetching miscellaneous events:', error);
    }
  },
  getActivityTitle(date) {
    const [day, month, dayOfWeek] = date.split(' ');
    const targetDate = moment(`${day} ${month}`, 'DD MMM').format('YYYY-MM-DD');
  
    const matchingEvents = this.miscellaneousEvents.enriched_events.filter(event => {
      const eventDate = moment(event.start).format('YYYY-MM-DD');
      return eventDate === targetDate;
    });
    
    if (matchingEvents.length === 1) {
      return { title: matchingEvents[0].activity_title, additionalEvents: '', matchingEvents };
    } else if (matchingEvents.length > 1) {
      const firstEvent = matchingEvents[0].activity_title;
      const additionalEvents = matchingEvents.length - 1;
      return { title: firstEvent, additionalEvents: ` +${additionalEvents} more`, matchingEvents };
    } else {
      return { title: '', additionalEvents: '', matchingEvents: [] };
    }
  },

  },

};
</script>

<style>
/* .sticky-element::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 8px 5px 8px 0px rgba(0, 0, 0, 0.2); 
  z-index: -1;
} */


/* .fade-container::after{
  content: '';
  position: absolute;
  top: 25%;
  right: 0;
  bottom: 0;
  width: 70px; /* Width of the fade effect */
  /* background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  z-index: 2;
} */ 

</style>
