<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7] h-full">
        <div class="space-y-2">
          <div class="flex justify-between items-center pt-4 px-4 dark:border-gray-700">
            <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Weekly Child Observation
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <!-- <DateDisplay :date="selectedDate" /> -->
        </div>
        <form @submit.prevent="handleSubmit" name="unstruct-observ-form" class="flex-auto flex flex-col font-normal"
          ref="formRef">
          <div :class="cn(`max-h-[60vh] overflow-y-auto`)">
            <WeeklyChildPreview v-if="mode === 0" :observation="props.observation" :version="props.version" />
            <WeeklyChildEditPage1 v-if="mode === 1" :pageNum="pageNum" :userData="userDetails" 
              :studentsList="studentsList.data" @date:selected="updateDate" />
            <div v-if="mode === 1" v-for="(section, sectionIndex) in formStructure">
              <template v-for="(question, questionIndex) in section.questions" :key="question.id">
                <MultipleChoiceQuestion v-if="question.type === 'multipleChoice' || question.type === 'multipleChoiceWithOther'" 
                  :pageNum="pageNum" :componentPageNum="1 + question.number" :question="question"
                  :questionNumber="questionIndex + 1" :section="section.title" :sectionNumber="sectionIndex + 1"
                  :observation="props.observation" />
                <TextQuestion v-if="question.type === 'text'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :questionNumber="questionIndex + 1" 
                  :section="section.title" :sectionNumber="sectionIndex + 1"
                  :observation="props.observation" />
                <CompetencyPicker v-if="question.type === 'competencyPicker'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :competencyList="competencyList"
                  :questionNumber="questionIndex + 1"
                  :section="section.title" :sectionNumber="sectionIndex + 1" 
                  @change:comps="(newValue) => updateCompetencies(newValue, question.id)"
                  :observation="props.observation" />
                <TextWithMultipleChoice  v-if="question.type === 'textWithMultipleChoice'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :questionNumber="questionIndex + 1" 
                  :section="section.title" :sectionNumber="sectionIndex + 1"
                  :observation="props.observation" />
              </template>
            </div>
            <WeeklyChildPreview v-if="mode === 2" :observation="props.observation" :version="props.version" 
              :isSavePreview="true" />
            <div>
              <CommentsModule v-if="mode === 0 && fetchCommentsResource.data?.status === 'success'"
                :comments="fetchCommentsResource.data.comments" />
              <div ref="commentsRef"></div>
            </div>
          </div>
          <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
            hideInputWarning && 'hidden')">
            Please answer all the required questions!
          </div>
          <ObservationCommentInput v-if="isCommenting" :isSavingComment="isSavingComment" 
            :commentResponse="commentSaveResponse" :userList="taggableUsers" 
            @insert:tag="addToTags" />
          <div class="p-3 text-center">
            <span v-if="isSavingComment" :class="cn(`text-sm font-semibold text-gray-600 text-center`)">
              Saving comment... Please wait...
            </span>
            <span v-if="commentSaveResponse" :class="cn(`text-sm font-semibold text-red-500 text-center`)">
              {{ commentSaveResponse }}
            </span>
            <span v-if="isCommmentSaveSuccess":class="cn(`text-sm font-semibold text-green-600 text-center`)">
              Comment saved!
            </span>
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between">
            <div :class="cn(`flex gap-2 items-center`, mode !== 1 && 'hidden')">
              <div class="px-8 md:px-4 flex gap-1 items-center">
                <div v-for="n in totalPageNum" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, n <= pageNum && 'bg-[#FFD700]')">
                  <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
                </div>
              </div>
              <div class="text-sm font-semibold text-gray-600">
                {{ pageNum }}/{{ totalPageNum }}
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-end">
            <div class="flex md:justify-end items-center gap-4 py-3 px-4 flex-wrap">
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')" 
                :data-hs-overlay="`#hs-${props.modalName}`" :disabled="mode !== 0 || isCommenting">
                Close
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || isCommenting" @click="mode = 1">
                Edit
              </button>
              <button type="button" :class="cn(`py-2 inline-flex md:px-10 flex-auto md:basis-auto 
                items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white justify-center
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', isCommenting && 'hidden')"  
                :disabled="mode !== 0 || isCommenting" @click="isCommenting = true">
                Comment
              </button>
              <div :class="cn(`flex gap-2`, !isCommenting && 'hidden')">
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                  rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || !isCommenting" @click="isCommenting = false">
                  Discard
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none`, mode !== 0 && 'hidden', !isCommenting && 'hidden')"
                  :disabled="mode !== 0 || !isCommenting || isSavingComment" @click="saveComment">
                  Save
                </button>
              </div>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none`, mode === 0 && 'hidden', isCommenting && 'hidden')" 
                @click="goToPrevPage" :disabled="(mode !== 1 && mode !== 2) || pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum >= totalPageNum && 'hidden')" 
                @click="goToNextPage" :disabled="mode !== 1 ||pageNum >= totalPageNum">
                Next
              </button>
              <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 1 && 'hidden', pageNum !== totalPageNum && 'hidden')" 
                @click="showPreview" :disabled="mode !== 1 || pageNum != totalPageNum">
                Preview
              </button>
              <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none`, mode !== 2 && 'hidden')" 
                :disabled="mode !== 2">
                Save
              </button>
              <button type="button" :class="cn(`py-2 flex-auto inline-flex items-center gap-x-2 text-sm font-semibold
                md:basis-auto md:px-10 md:flex-initial
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none justify-center hidden`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleArchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || isArchived
                || isCommenting">
                Archive
              </button>
              <button type="button" :class="cn(`py-2 flex-auto inline-flex items-center gap-x-2 text-sm font-semibold
                md:basis-auto md:px-10 md:flex-initial
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                disabled:opacity-50 disabled:pointer-events-none justify-center`, mode !== 0 && 'hidden', 
                !userDetails?.is_system_manager && 'hidden', !isArchived && 'hidden', isCommenting && 'hidden')" 
                @click="handleUnarchive" :disabled="mode !== 0 || !userDetails?.is_system_manager || !isArchived ||
                isCommenting">
                Unarchive
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from 'vue';
import { cn } from '@/components/ui/utils.js'
import { createResource, createListResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
// import DateDisplay from '../DateDisplay.vue';
import WeeklyChildEditPage1 from './WeeklyChildEditPage1.vue';
import { formStructure } from '../../forms/WeeklyChild/weeklyChildQuestions';
import WeeklyChildQuestion from '../../forms/WeeklyChild/WeeklyChildQuestion.vue';
import WeeklyChildPreview from './WeeklyChildPreview.vue';
import MultipleChoiceQuestion from '../../forms/FormPages/MultipleChoiceQuestion.vue';
import TextQuestion from '../../forms/FormPages/TextQuestion.vue';
import CompetencyPicker from '../../forms/FormPages/CompetencyPicker.vue';
import TextWithMultipleChoice from '../../forms/FormPages/TextWithMultipleChoice.vue';
import ObservationCommentInput from '@/components/observations/edit/ObservationCommentInput.vue';
import CommentsModule from '@/components/observations/edit/comments/CommentsModule.vue';
import { getFinalUserTagList } from '@/lib/utils.js';

const props = defineProps([
  'modalName', 'observation', 'selectedSignal',
  'version', 'scrollSignal'
])

watch(() => props.observation, () => {
  console.log("observation changed")
})

const userDetails = inject('userDetails')
const competencyList = inject('observationCompetencies')

const mode = ref(0)  // 0 - view, 1 - edit, 2 - preview
const pageNum = ref(1)
const totalPageNum = 19
const selectedDate = ref(null)

const formRef = ref(null)
const hideInputWarning = ref(true)

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const studentsList = createListResource({
  doctype: 'Student',
  fields: ['name', 'student_name', 'cohort'],
  orderBy: 'student_name asc',
  filters: {
    is_enrolled: 1,
  },
  start: 0,
  pageLength: 30,
  auto: true
})

const goToPrevPage = () => {
  if (pageNum.value === 1) return
  pageNum.value = pageNum.value - 1
}

const goToNextPage = () => {
  hideInputWarning.value = true
  pageNum.value = pageNum.value + 1
}

const updateDate = (date) => {
  console.log(date)
  selectedDate.value = date
}

const previewData = ref(null)
const showPreview = async () => {
  const formData = new FormData(formRef.value)

  const formDataObj = {};
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });

  // formDataObj.engaging_activities = engagingCompetencies.value;
  // formDataObj.less_interesting_activities = lessInterestingCompetencies.value;

  console.log({formDataObj})
  previewData.value = formDataObj
  mode.value = 2
}

const observationEditResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.weekly_child_observations.api.edit_observation'
})

const isSubmitting = ref(false)

const handleSubmit = async (e) => {
  isSubmitting.value = true
  try {
    await observationEditResource.submit({
      name: props.observation.name,
      observation_data: previewData.value
    })
    if (observationEditResource.data.status === 'success') {
      console.log("Successfully saved observation");
      HSOverlay.close(`#hs-${props.modalName}`)
      pageNum.value = 1
    } else {
      console.error("Failed to save observation")
      alert("Form Submission Failed. Make sure you have filled everything correctly.")
    }
  } catch (error) {
    console.error("Failed to make observation save request")
    console.error(error)
      alert("Failed to submit observation form. Please contact an admin.")
  }
}

const isArchived = ref(false)

const isCommenting = ref(false)

const fetchCommentsResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.weekly_child_observations.api.fetch_comments'
})

watch(() => props.observation, () => {
  isCommenting.value = false
  if (props.observation) {
    fetchCommentsResource.submit({
      observation_id: props.observation.name
    })
  }
  if (props.observation?.is_archived === 1)
    isArchived.value = true
  else if (props.observation?.is_archived === 0)
    isArchived.value = false
})

const taggableUsers = computed(() => {
  if (!userDetails?.value?.school_info) return []
  return userDetails.value.school_info.educators
    .filter((educator) => !!educator.user_link)
    .map((educator) => {
      return {
        value: educator.educator_name,
        label: educator.educator_name,
        name: educator.user_link,
      }
    })
})

const taggedUsers = ref([])

const addToTags = (item) => {
  if (!taggedUsers.value.includes(item))
    taggedUsers.value.push(item)
}

const isSavingComment = ref(false)
const commentSaveResponse = ref('')
const isCommmentSaveSuccess = ref(false)

const commentSaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.weekly_child_observations.api.save_comment'
})

const saveComment = async () => {
  isSavingComment.value = true
  const formData = new FormData(formRef.value)
  const comment = formData.get('comment')
  const finalTags = getFinalUserTagList(comment, taggedUsers.value)
  const taggedUserNames = finalTags.map((user) => user.name).join(',')
  // console.log("Tagged Users: ", taggedUsers.value)
  // console.log("Final Tags", taggedUserNames)

  console.log(
  {
      comment_data: {
        'observation_id': props.observation.name,
        'comment': comment,
        'tagged_users': taggedUserNames || '',
      },}
  )
  // return
  try {
    await commentSaveResource.submit({
      comment_data: {
        'observation_id': props.observation.name,
        'comment': comment,
        'tagged_users': taggedUserNames || '',
      },
    })

    if (commentSaveResource.data.status === 'success') {
      fetchCommentsResource.reload()
      isSavingComment.value = false
      isCommenting.value = false
      isCommmentSaveSuccess.value = true
      setTimeout(() => {
        isCommmentSaveSuccess.value = false
      }, 1500)
    } else if (commentSaveResource.data.status === 'fail') {
      isSavingComment.value = false
      commentSaveResponse.value = commentSaveResource.data.message
      setTimeout(() => {
        commentSaveResponse.value = ''
      }, 1500)
      console.error("Failed to save comment.")
      console.error(commentSaveResource.data.message)
    }
  } catch (error) {
    isSavingComment.value = false
    console.error(error)
  }
}

const commentsRef = ref(null)

console.log()
watch(() => props.scrollSignal, () => {
  // console.log('scroll signal(new) is ', props.scrollSignal)
  // console.log("comments ref", commentsRef.value)
  setTimeout(() => {
    commentsRef.value.scrollIntoView({ behavior: 'smooth' });
  }, 1000)
})
</script>