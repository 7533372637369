<template>

    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
      <div class="relative bg-white rounded-2xl shadow-md w-11/12 md:w-3/5 lg:w-3/5 h-[80%] max-h-[97vh] overflow-auto">
         
         <!-- Cross button -->
         <div class="sticky top-7 mr-6 flex justify-end z-10">
             <button @click="closeComponent" class="border-black">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
             </button>
         </div>

        <!-- content body -->
        <div class="pl-4 md:pl-8 pr-4 mt-4"> 
         <!-- Heading -->
         <div class="font-semibold text-black text-2xl self-center"> Privacy Policy for Zippie</div>                       
         <div class="text-sm text-slate-500 font-medium mt-1">Updated August 2024</div>

          
          <!-- Summary -->
          <div class="mt-7 text-sm">Your privacy is important to us. Here's a quick overview of our Privacy Policy:</div>
          <ul class="list-disc list-inside mt-2 text-sm leading-7">
            <div class="flex gap-2">
                <li><span class="font-semibold">Information We Collect:</span> 
                    We collect personal information (like names and email addresses), usage data, and user-generated content. This includes student information like photos and videos.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">How We Use It:</span> 
                    We use your information to provide and improve our services, communicate with you, and ensure security.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">Sharing Your Information:</span> 
                    We may share your information with service providers who help us run Zippie, and when required by law.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">Student Privacy:</span> 
                    We take extra care with student information. It's stored securely and not sold or shared unnecessarily.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">Your Rights:</span> 
                    Depending on where you live, you have rights regarding your personal information, like the right to access or delete it.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">Security:</span> 
                    We use industry-standard measures to protect your information, but no method is 100% secure.
                </li>
            </div>
            
            <div class="flex gap-2">
                <li><span class="font-semibold">Changes:</span> 
                    We may update this policy. We'll notify you of significant changes.
                </li>
            </div>
        </ul>
          <div class="mt-5 text-sm">This summary is just an overview. Please read the full Privacy Policy below for complete details.</div>
            
          
          <!-- Content container -->
           <div class="mt-8 text-sm">

            <!-- #1 Definitions -->
            <div class="font-semibold text-xl">1. Definitions</div>
            <div class="mt-3">To help you understand this Privacy Policy, we've defined some key terms:</div>
            <ul class="list-disc list-inside mt-2 text-sm leading-7">
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Personal Information":</span> 
                        Any information that can be used to identify an individual, either alone or in combination with other information.
                    </li>
                </div>
                
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Student":</span> 
                        Any individual enrolled in an educational program managed through Zippie, typically a child or young person under the age of 18.
                    </li>
                </div>
                
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Student Information":</span> 
                        Any personal information specifically related to a student, including but not limited to names, photographs, and videos.
                    </li>
                </div>
                
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Educational Institution":</span> 
                        Any school, homeschool, microschool, or other organization using Zippie for educational purposes.
                    </li>
                </div>
                
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Data Controller":</span> 
                        The entity that determines the purposes and means of processing personal information (typically, the Educational Institution).
                    </li>
                </div>
                
                <div class="flex gap-2">
                    <li><span class="font-semibold">"Data Processor":</span> 
                        The entity that processes personal information on behalf of the Data Controller (in this case, Zippie).
                    </li>
                </div>
            </ul>
            <div class="mt-3 text-sm"><span class="font-semibold">Please note:</span>  Additional terms related to the use of our service are defined in our <router-link to="/front-portal/terms-of-use"> Terms and Conditions.</router-link></div>


            <div class="mt-7 text-sm">At Zippie, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our curriculum planning and scheduling website for educators of microschools and homeschools.</div>
            <div class="mt-7 text-sm">Please read this Privacy Policy carefully. By accessing or using Zippie, you acknowledge that you have read, understood, and agree to be bound by all the terms outlined in this Privacy Policy. If you do not agree with our policies and practices, please do not use our website.</div>



            <!-- #2 -->
            <div class="font-semibold text-xl mt-4">2. Information We Collect</div>
            <div class="mt-3 flex flex-col text-sm leading-6 gap-3">
            <div>We collect several types of information from and about users of our website, including:</div>
            <div class="font-semibold">2.1 Personal Information</div>
            <ul class="list-inside list-disc ml-4">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number (optional)</li>
            <li>Billing information (if applicable)</li>
            <li>School or organization name (if applicable)</li>
            </ul>
            <div class="font-semibold">2.2 Non-Personal Information</div>
            <ul class="list-inside list-disc ml-4">
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>IP address</li>
            <li>Usage data (e.g., pages visited, time spent on the website)</li>
            <li>Device information</li>
            </ul>
            <div class="font-semibold">2.3 User-Generated Content</div>
            <ul class="list-inside list-disc ml-4">
            <li>Curriculum plans</li>
            <li>Schedules</li>
            <li>Educational resources uploaded by users</li>
            </ul>
            </div>

            <!-- #3 -->
            <div class="font-semibold text-xl mt-4">3. How We Collect Information</div>
            <div class="mt-3 text-sm leading-6">
            We collect information in the following ways:
            <ul class="list-inside list-disc mt-2">
            <li>Directly from you when you provide it to us (e.g., during registration or when using our services)</li>
            <li>Automatically as you navigate through our website (using cookies and other tracking technologies)</li>
            <li>From third-party sources (e.g., education partners, payment processors)</li>
            <li> Through user uploads of student photographs and videos</li>
            </ul>
            </div>
           
            <!-- #4 -->
            <div class="font-semibold text-xl mt-4">4. How We Use Your Information</div>
            <div class="mt-3 text-sm leading-6">
            We use the information we collect about you or that you provide to us to:
            <ul class="list-inside list-disc mt-2">
                <li>Provide, maintain, and improve our services</li>
                <li>Process your account registration and maintain your account</li>
                <li>Respond to your inquiries and provide customer support</li>
                <li>Send you important notifications and updates about our services</li>
                <li>Analyze usage patterns and trends to enhance user experience</li>
                <li>Prevent fraudulent activities and ensure the security of our platform</li>
                <li>Store and organize student photographs and videos</li>
                <li>Comply with legal obligations</li>
            </ul>
            </div>

            <!-- #5 -->
            <div class="font-semibold text-xl mt-4">5. Disclosure of Your Information</div>
            <div class="mt-3 text-sm leading-6">
            <div>We may disclose personal information that we collect or you provide as described in this Privacy Policy:</div>
            <ul class="list-inside list-disc mt-2">
                <li>To our subsidiaries and affiliates</li>
                <li>To contractors, service providers, and other third parties we use to support our business including vetted cloud storage providers and cloud AI providers</li>
                <li>To fulfill the purpose for which you provide it</li>
                <li>For any other purpose disclosed by us when you provide the information</li>
                <li>With your consent</li>
            </ul>
            <div class="mt-2">We may also disclose your personal information:</div>
            <ul class="list-inside list-disc mt-2">
                <li>To comply with any court order, law, or legal process</li>
                <li>To enforce or apply our Terms and Conditions</li>
                <li>If we believe disclosure is necessary to protect the rights, property, or safety of Zippie, our users, or others</li>
            </ul>
            </div>

            <!-- #6 -->
            <div class="font-semibold text-xl mt-4">6. Student Photographs and Videos</div>
            <div class="mt-3 text-sm leading-6">
            We take special care to protect the privacy and security of student photographs and videos:
            <ul class="list-inside list-disc mt-2">
                <li>Student photographs and videos are uploaded to vetted cloud storage providers and cloud AI providers, such as Amazon Web Services and Google Cloud Services.</li>
                <li>These photographs and videos may be tagged with students' first names or nicknames to facilitate easy storage and filtering.</li>
                <li>Our cloud service providers are contractually obligated to not resell or share this data.</li>
                <li>We implement strict access controls and security measures to protect this sensitive information.</li>
            </ul>
            </div>

            <!-- #7 -->
            <div class="font-semibold text-xl mt-4">7. Data Security</div>
            <div class="mt-3 text-sm leading-6">
            We implement appropriate technical and organizational measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </div>

            <!-- #8 -->
            <div class="font-semibold text-xl mt-4">8. Third-Party Links and Services</div>
            <div class="mt-3 text-sm leading-6">
            Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to read the privacy policies of any third-party sites you visit.
            </div>

            <!-- #9 -->
            <div class="font-semibold text-xl mt-4">9. Children's Privacy</div>
            <div class="mt-3 text-sm leading-6">
            Our services are not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you are under 13, please do not provide any information on this website.
            </div>

            <!-- #10 -->
            <div class="font-semibold text-xl mt-4">10. Your Rights and Choices</div>
            <div class="mt-3 text-sm leading-6">
            <div>Depending on your location, you may have certain rights regarding your personal information, including:</div>
            <ul class="list-inside list-disc mt-2">
                <li>The right to access and receive a copy of your personal information</li>
                <li>The right to rectify or update your personal information</li>
                <li>The right to delete your personal information</li>
                <li>The right to restrict or object to our processing of your personal information</li>
                <li>The right to data portability</li>
            </ul>
            <div class="mt-2">To exercise any of these rights, please contact us using the information provided in the "Contact Us" section below.</div>
            </div>

            <!-- #11 -->
            <div class="font-semibold text-xl mt-4">11. Changes to Our Privacy Policy</div>
            <div class="mt-3 text-sm leading-6">
            We may update our Privacy Policy from time to time. If we make material changes to how we treat our users' personal information, we will notify you through a notice on the website home page or via email.
            </div>

            <!-- #12 -->
            <div class="font-semibold text-xl mt-4">12. Contact Us</div>
            <div class="mt-3 text-sm leading-6">
            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
            <div class="mt-2">
                Comini Learning<br>
                Mayflower, 2nd Floor,New Kantwadi,Off Perry Road,Bandra (W), Mumbai 400050<br>
                sai@comini.in<br>
                +91 9845769705
            </div>
            </div>

            <div class="mt-4 text-sm leading-6">
            By using Zippie, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
            </div>
   
          
          </div>

         
        </div>


        <!-- I accept button -->
        <div v-if="$auth.isLoggedIn" class="sticky bottom-0 mt-0 bg-white border-t py-4 w-full flex justify-end">
            <button @click="updatePrivacyPolicyStatus" class="text-white truncate bg-black rounded-md text-center text-sm py-2.5 px-8 mr-4"> I Accept</button>
         </div>

        
     </div> 
    </div>   
 </template>
 
 
 <script setup>
 import { ref, onMounted, inject, computed, watch } from 'vue';
 import { defineEmits } from 'vue';
 
 // Inject the $call method
 const $call = inject('$call');
 const $auth = inject('$auth');
 
 
 const emit = defineEmits(['close']);
 
 const closeComponent = () => {
  emit('close');
 };
 
 
 const updatePrivacyPolicyStatus = async() =>{
  try {
      const response = await $call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.update_privacy_policy_status');
      console.log("This is the response", response);
      if (response.status === "success"){
        emit('close');
        if(response.terms_of_use && response.privacy_policy){
            window.location.href = '/front-portal';
          }
      }
    } catch (error) {
        console.error('Error accepting Privacy Policy:', error);
    }
 }
 
 
 
 </script>