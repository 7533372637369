<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3">
      <!-- <label :for="question.id" class="block text-sm font-medium mb-2 dark:text-white">
        {{ question.text }}
      </label> -->
      <h4 class="font-semibold text-base md:text-lg">
        {{ getNthAlphabet(props.sectionNumber) }}. {{ props.section }}
      </h4>
      <label class="font-medium text-sm md:text-base" :for="question.id">
        {{ props.questionNumber }}. {{ props.question.text }}
      </label>
      <!-- <textarea :id="question.id" :name="question.id" rows="3"
        class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 
        focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" 
        :placeholder="`Enter ${question.text.toLowerCase()}...`"></textarea> -->
      <textarea @keydown.stop
        :id="`${question.id}`"
        :name="props.question.id"
        rows="3"
        class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        placeholder="Enter text here..." 
        v-model="text" 
        ref="textInputRef"
        @input="textareaAutoHeight"
      ></textarea>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { cn } from '@/components/ui/utils.js';
import { getNthAlphabet } from '@/lib/utils'

const props = defineProps([
  'question', 'section', 'sectionNumber',
  'questionNumber', 'pageNum', 'componentPageNum',
  'observation'
])

const text = ref(props.observation ? props.observation[props.question.id] : '')

const textInputRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  textInputRef.value.style.height = 'auto'
  let calculatedHeight = textInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  textInputRef.value.style.height = `${calculatedHeight}px`
}
</script>
