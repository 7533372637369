<template>
    <!-- <div class=" bg-customNavbar min-h-screen flex"> -->
    <div v-if="!$auth.isLoggedIn || $auth.isLoggedIn === undefined"  class=" bg-customNavbar pt-0 md:pt-10 pb-20 md:pb-0 mb-8">
        <img src="@/assets/app-logos/zippielogo.png" alt="Currie Login Logo"
        class="absolute top-[5%] left-[5%] w-[122px] h-[52px] md:w-[179.00px] md:h-[76.00px]">
        
  
      <div class="min-h-screen flex flex-col justify-center items-center md:flex-row md:py-8" :class="[iAmAParent ? 'md:px-8':'md:px-4']">
  
        <div class="w-full flex flex-col justify-center items-center mt-10 md:mt-0" :class="[iAmAParent ? 'md:max-w-[70%]':'md:max-w-[100%]']">
          <!-- <div class="w-full max-w-lg bg-customFormBackground p-6 rounded-md mx-auto"> -->
          <!-- <div class="w-full max-w-lg md:p-10 p-4 rounded-md mx-8 md:mx-auto"> -->
            <!-- Description with partially colored text -->
            <div class="flex flex-col justify-center items-center h-full w-[100%] mb-20">
                <p class="text-black text-3xl md:text-5xl font-medium leading-tight md:leading-tight text-center" :class="[iAmAParent ? 'md:text-left':'md:text-center']">
                  Make learning playful and purposeful
                  <!-- <br>
                  <span class="md:text-white">playful</span>
                  and purposeful -->
                </p>
            </div>
            <button style="color: #6B7280;"
              class="w-[70%] md:w-[35%] md:max-w-[400px] px-4 md:px-15 py-2 md:py-3 rounded-md bg-white mx-auto text-base md:text-lg"
              v-if="googleAuthUrl && iAmAnEducator" @click="handleGoogleLoginClickForEducator(googleAuthUrl)">
              <img src="@/assets/google_login_logo.png" alt="upload_photos_logo" height="38" width="38" style="max-width: none; display: inline;">       
              Sign in with Google
            </button>
  
  
            <!-- Button for selecting Educator or Parent Login flow -->
            <div v-if="!iAmAnEducator && !iAmAParent" class="flex flex-col md:flex-row w-full gap-6 justify-center items-center">
              <button @click="iAmAnEducatorClicked()"
                class="w-[70%] md:w-[30%] md:max-w-[345px] px-4 py-3 md:py-5 rounded-md bg-black text-white text-base md:text-xl"
                type="submit">
                I'm an Educator 
              </button>
              <button @click="iAmAParentClicked()"
                class="w-[70%] md:w-[30%] md:max-w-[345px] px-4 py-3 md:py-5 rounded-md bg-white text-black text-base md:text-xl"
                type="submit">
                I'm a Parent 
              </button>
            </div>
  
        </div>  
            
        <!-- Login option for parents -->
        <div v-if="iAmAParent" class="w-[86%] md:w-[30%] p-4 pb-6 pt-6 flex flex-col items-center bg-customFormBackground rounded-md">
          <!-- </div>  -->
          <PhoneAuth></PhoneAuth>
          <div class="font-medium text-sm mt-8 mb-2 md:mb-3"> Or continue with Google</div>
          <button style="color: #6B7280;"
              class="w-full md:max-w-[360px] px-4 md:px-15 py-2 rounded-md bg-white mx-auto text-base md:text-lg"
              v-if="googleAuthUrl" @click="handleGoogleLoginClick(googleAuthUrl)">
              <img src="@/assets/google_login_logo.png" alt="upload_photos_logo" height="26" width="26" style="max-width: none; display: inline;" class="mr-2.5">       
                Google
          </button>
  
          <div class="flex mt-8 mb-2 md:mb-3 gap-4 md:w-full justify-center">
            <div class="flex flex-col max-w-[360px]">
              <div class="font-medium text-sm leading-6 text-center lg:text-start">By logging in you agree to our <button @click="termsOfUseClick" class="underline">Terms & Conditions </button> and <button @click="privacyPolicyClick" class="underline"> Privacy Policy*</button></div>
              </div>
          </div>
        
          <div class="font-medium text-[12px] text-[#737373] mt-8 mb-2 md:mb-3 md:w-full md:px-3.5">*Please do take a minute to view them. we've tried to make it accessible </div>
          
        </div>
  
      </div>
    </div>
    
     <termsOfUseModal v-if="termsOfUseModalVisible" @close="handleTermsOfUseModalClose"></termsOfUseModal>
     <privacyPolicyModal v-if="privacyPolicyModalVisible" @close="handlePrivacyPolicyModalClose"></privacyPolicyModal>
  </template>
  <script>
  
  import PhoneAuth from '../components/PhoneAuth.vue';
  import termsOfUseModal from '../components/userAgreementAcceptance/termsOfUseModal.vue';
  import privacyPolicyModal from '../components/userAgreementAcceptance/privacyPolicyModal.vue';
  
  export default {
    inject: {
      $call: '$call', // Injecting the $call property
      $auth: '$auth', // Injecting the $auth property
      userDetails: {
        from: 'userDetails',
        default: () => ({}) // Provide a default value if userDetails is not provided
      }
    },
    components:{
      PhoneAuth,
      termsOfUseModal,
      privacyPolicyModal
    },
    data() {
      return {
        email: null,
        password: null,
        googleAuthUrl: "", // URL for Google OAuth
        iAmAnEducator: false,
        iAmAParent: false,
        privacyPolicyModalVisible: false,
        termsOfUseModalVisible: false,
      };
    },
    async mounted() {

      if(this.$auth.isLoggedIn){
        this.$router.push('/front-portal/').catch(err => {
          console.error('Navigation failed:', err);
        });
      }
      
      // Call fetchOAuthUrls when the component is mounted (page loads)
      this.fetchOAuthUrls();
  
      const termsAccepted = this.$route.query.terms_of_use_accepted === 'false';
      const privacyAccepted = this.$route.query.privacy_policy_accepted === 'false';
  
      if (termsAccepted) {
        this.termsOfUseModalVisible = true;
      } else if (privacyAccepted) {
        this.privacyPolicyModalVisible = true;
      }
    },
    methods: {
      async login() {
        if (this.email && this.password) {
          let res = await this.$auth.login(this.email, this.password);
      
          if (res) {
  
            if(window.localStorage.getItem('redirect_after_login')){
              var urlToRedirect = window.localStorage.getItem('redirect_after_login')
              window.localStorage.removeItem("redirect_after_login");
              window.location.href = urlToRedirect;
              console.log('Redirecting to url from login page',urlToRedirect)
              return;
            }
  
            this.$router.push({ name: 'Home' }).then(() => {
            window.location.href = this.$router.resolve({ name: 'Home' }).href;
            console.log("Page reloaded after login")
          });
          }
        }
      },
      async fetchOAuthUrls() {
       
        try {
          const response = await this.$call(
            'curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_social_logins_with_redirect',
            { redirect_to: '/api/method/frappe.integrations.oauth2_logins.login_via_google' }
  
          );
          if (response) {
            this.googleAuthUrl = response;
            // console.log("OAuth URLs Response:", response);
          } else {
            console.error("Failed to get OAuth URLs");
          }
        } catch (error) {
          console.error("Error while fetching OAuth URLs:", error);
        }
      },
      termsOfUseClick(){
        this.termsOfUseModalVisible = true
      },
      privacyPolicyClick(){
        this.privacyPolicyModalVisible = true
      },
      handleTermsOfUseModalClose() {
        this.termsOfUseModalVisible = false;
        if (this.$route.query.privacy_policy_accepted === 'false') {
          this.privacyPolicyModalVisible = true;
        }
      },
      handlePrivacyPolicyModalClose() {
        this.privacyPolicyModalVisible = false;
      },
      handleGoogleLoginClick(response){
        this.redirectToGoogleOAuth(response);  
      },
      handleGoogleLoginClickForEducator(response){
        this.redirectToGoogleOAuth(response);
      },
  
      redirectToGoogleOAuth(response) {
       
        const googleOAuthUrl = response[0].auth_url;
        // console.log("before click link", googleOAuthUrl)
        window.location.href = googleOAuthUrl;
      },
      iAmAnEducatorClicked(){
        this.iAmAnEducator = true;
        localStorage.setItem('roleChangedTo', '');
      },
      iAmAParentClicked(){
        this.iAmAParent = true;
        localStorage.setItem('roleChangedTo', 'Parent');
      },
  
    },
  };
  </script>
  
  <style>
  input::placeholder {
    color: rgba(102, 102, 102, 1);
    opacity: 1;
    /* Required for some browsers to show placeholder in the same color */
  }
  </style>