<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto">
        <!-- Cross button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <!-- Content container -->
        <div class="w-full flex flex-col px-4">
          <div class="font-semibold text-[22px] mt-2 mb-6 px-2 md:px-0">
            New Menu
          </div>
          
          <!-- Form -->
          <form @submit.prevent="saveMenu" class="mb-6 px-1">
            <!-- Date Input -->
            <div class="mb-7">
              <label for="menuDate" class="block text-sm font-medium">For date</label>
              <input type="date" id="menuDate" v-model="menuDate" required class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
            <div class="block text-sm font-medium">Add Menu Items</div>
            
            <!-- Menu Items -->
            <div class="flex flex-row items-end mt-1">    
                <div class="flex flex-col">
                  <div v-for="(item, index) in menuItems" :key="index" class="mb-1 flex flex-col">
                    <input type="text" :id="'item'+index" v-model="item.name" placeholder="Enter Itme Name" class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                  </div>
                </div>

                <!-- Add New Item Button -->
                <div class="mx-2 mb-2">
                  <button type="button" @click="addNewItem" class="flex items-center text-sm text-blue-600 hover:text-blue-800">
                    <div class="bg-slate-200 rounded-full w-6 h-6 flex items-center justify-center">
                      <span class="text-black text-lg leading-none">+</span>
                    </div>
                  </button>
              </div>       
            </div>

            <!-- MenuTag Input -->
            <div class="mt-5">
              <label for="menuDate" class="block text-sm font-medium">Tag</label>
              <input type="text" id="menuTag" v-model="menuTag" class="mt-2 block w-[88%] max-w-[331px] rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
  
            <!-- Buttons -->
            <div class="flex justify-between mt-8">
              <button type="button" @click="clearInputs" class="bg-white text-blue-600 py-2.5 px-1 w-[135px] text-[14px] text-start rounded-lg hover:bg-gray-200">Clear inputs</button>
              <button type="submit" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
 
  import { ref, onMounted, inject } from 'vue';
  const $call = inject('$call');
  
  const emit = defineEmits(['close', 'save']);
  
  const menuDate = ref('');
  const menuItems = ref([
    { name: '' },
    { name: '' },
    { name: '' }
  ]);
  const isSaving = ref(false);
  const errorMessage = ref('');
  const additionalNotes = ref('');
  const menuTag = ref('');
  
  const closeComponent = () => {
    emit('close');
  };
  
  const addNewItem = () => {
    menuItems.value.push({ name: '' });
  };
  
  const clearInputs = () => {
    menuDate.value = '';
    menuItems.value = menuItems.value.map(() => ({ name: '' }));
    menuTag.value = '';
  };
  
const saveMenu = async () => {
  console.log("save menu called")
  try {
    isSaving.value = true;
    errorMessage.value = '';

    const menu = {
      date: menuDate.value,
      items: menuItems.value.map(item => item.name).filter(name => name.trim() !== ''),
      additional_notes: additionalNotes.value,
      tag:menuTag.value
    };

    const response = await $call('curriculum_planner.curriculum_planner.doctype.lunch_menu.api.create_new_lunch_menu', {
      date: menu.date,
      items: menu.items,
      additional_notes: menu.additional_notes,
      tag:menu.tag,
    });

    if (response.status === 'success') {
      emit('newMenuCreated', response.data);
      closeComponent();
    } else {
      errorMessage.value = response.message || 'An error occurred while saving the menu.';
    }
  } catch (error) {
    console.error('Error saving lunch menu:', error);
    errorMessage.value = 'An unexpected error occurred. Please try again.';
  } finally {
    isSaving.value = false;
  }
 };
  </script>