<template>
 
  <div class="space-y-4">

   
    <button @click="goToActivityObservationsPage" class="border-black mt-5 md:mt-10 md:ml-10 px-4 py-2 hidden">
      New Activity Observation
    </button>
    
    <button @click="goToDailyObservationsPage" class="border-black mt-5 md:ml-10 px-4 py-2 hidden">
      New Daily Observation
    </button>
    <CalendarWidget class="ml-10 md:ml-10 mt-5"></CalendarWidget>
   
   
    <!-- {{ permissionStatus }} -->
    <!-- <div :class="cn(`pl-8`)" v-if="(browserPermissionStatus !== 'granted' || userDetails.permission_status !== 'granted')
      && hostname !== 'zippie.comini.in'">
      <PermissionDialog modalName="notification-perm-dialog"
        :updatePermissionStatus="updatePermissionStatus">
        <template #trigger>
          <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
            border border-transparent bg-gray-800 text-white hover:bg-gray-700 focus:outline-none
            focus:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none" aria-haspopup="dialog"
            aria-expanded="false" aria-controls="hs-notification-perm-dialog"
            :data-hs-overlay="`#hs-notification-perm-dialog`">
            Enable Notifications
          </button>
        </template>
      </PermissionDialog>
    <p>displaying button because hotname is: {{hostname}}</p>
    </div> -->
 </div>
  <p v-if="hostname === 'testpod.comini.in'">{{hostname}}</p>

 </template>
 

 <script>

import CalendarWidget from '../components/CalendarWidget.vue'
import { onMounted, ref, inject, computed } from 'vue';
import PermissionDialog from '../components/notifications/PermissionDialog.vue';
import { checkPermissionStatus } from '../components/notifications/firebaseUtils';
import { cn } from '@/components/ui/utils.js'

 export default {
  setup() {
    const userDetails = inject('userDetails')
    const modifiedPermissionStatus = ref('')
    const hostname = window.location.hostname;

    const browserPermissionStatus = ref(checkPermissionStatus())

    const updatePermissionStatus = (newStatus) => {
      modifiedPermissionStatus.value = newStatus
    }

      onMounted(() => {
        console.log(" onMounted called in modal form");
        setTimeout(() => {
      window.HSStaticMethods.autoInit();
	  console.log("Initialised Preline inside educator dashboard after delay");
    }, 100)

      });

      return {
        browserPermissionStatus,
        updatePermissionStatus,
        userDetails,
        cn,
        hostname
      }
    },
   components: {
     CalendarWidget,
     PermissionDialog
   },
   methods: {
    
    goToDailyObservationsPage() {
      const dailyObservationsUrl = '/app/daily-observations';
      window.location.href = dailyObservationsUrl;
    },
    goToActivityObservationsPage() {
      const activityObservationsUrl = '/app/activity-observations';
      window.location.href = activityObservationsUrl;
    }
  }
};
 </script>
 