  <template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== 1 && `hidden`)" >
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">Your name:</label>
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none hidden" 
        id="activity-observ-form-date" name="educator_id" 
        v-model="educator">
      <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
        readonly:opacity-50 readonly:pointer-events-none" 
        id="activity-observ-form-date" name="educator_name" :readonly="true"
        :value="findValueByName(userDetails?.school_info.educators, educator, 'educator_name')" autocomplete="off">
    </div>
    <div class="space-y-3">
      <p class="font-medium text-sm md:text-base">
        Name of the child for whom observations are made:
      </p>
      <div class="border rounded-md">
        <StudentSelect :studentsList="userDetails.school_info.students" ref="studentSelectRef"
          :defaultStudent="props.observation.learners" />
      </div>
    </div>
    <div class="space-y-3">
      <label class="font-medium text-sm md:text-base">
        Date of observation:
      </label>
      <input type="date" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm 
        focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
        id="activity-observ-form-date" name="date" v-model="dateValue" :max="formattedDate"
        required @change="emitDate" @click="openDatePicker" >
    </div>
  </div>
  <!-- <FormFooter :pageNum="props.pageNum" :goToNextPage="props.goToNextPage" :totalPageNum="props.totalPageNum"
    :goToPrevPage="props.goToPrevPage" /> -->
</template>

<script setup>
import { cn } from '@/components/ui/utils.js'
import {  inject, ref } from 'vue'
import StudentSelect from '@/components/observations/forms/DailyForm/StudentSelect.vue'
import { findValueByName } from '@/lib/utils'
import { openDatePicker } from '@/lib/utils';
// import FormFooter from '../FormFooter.vue';

const props = defineProps([ 'pageNum', 'observation' ])
const emit = defineEmits(['date:selected'])

const today = new Date();
// Format today's date in YYYY-MM-DD format (required for input type date)
const formattedDate = today.toISOString().split('T')[0];

const userDetails = inject('userDetails')

const educator = ref(props.observation.educator_name)
const dateValue = ref(props.observation.date)

const emitDate = () => {
  emit('date:selected', dateValue.value)
}
</script>