<template>
  <Card :class="cn(`basis-1/3 gap-2 rounded flex-1 min-w-0`, 'lg:flex')">
    <template #title>
      <div class="flex justify-between items-center lg:flex-col lg:items-stretch lg:gap-2 xl:flex-row 
        xl:items-center lg:flex-auto xl:flex-initial" ref="cardRef">
        <span class="font-medium text-sm md:text-lg">Weekly Observations</span>
        <!-- <UnstructObservationModal modalName="unstruct-form-modal-2" :userData="props.userData"
          :reloadObservations="props.reloadObservations">
          <template #trigger>
            <button type="button" class="py-2 md:py-3 px-8 2xl:px-16 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none
              justify-center shrink-0" data-hs-overlay="#hs-unstruct-form-modal-2"
              @click="currentFormNum=2">
              Add New
            </button>
          </template>
        </UnstructObservationModal> -->
      </div>
    </template>
    <div class="flex flex-col gap-2 h-full flex-auto">
      <!-- <Card class="hs-tooltip basis-1/2 h-full flex justify-center items-center hidden md:flex">
        <UnstructObservationModal modalName="unstruct-form-modal-1" :userData="props.userData">
          <template #trigger>
            <button type="button" class="hs-tooltip-toggle py-3 px-4 inline-flex justify-center items-center
              text-3xl rounded-3xl border border-transparent text-gray-800 gap-x-2
              hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none
              h-20 w-20" data-hs-overlay="#hs-unstruct-form-modal-1" @click="currentFormNum=1">
              +
              <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible
                opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900
                text-xs font-medium text-white rounded shadow-sm " role="tooltip">
                Create New Observation
              </span>
            </button>
          </template>
        </UnstructObservationModal>
      </Card> -->
      <div v-if="props.observeData?.unstructured?.length" 
        class="flex flex-col gap-2 md:gap-4 flex-auto">
        <UnstructViewCard v-for="observation in props.observeData.unstructured.slice(0,2)"
          :observation="observation" :selectObservation="selectObservation" />
      </div>
      <div v-else class="flex flex-col gap-2 md:gap-4 flex-auto items-center text-xs text-gray-500
        font-medium xl:text-sm">
        Nothing to show here. Click on the Add New button to create your first observation
      </div>
      <div v-if="props.observeData?.unstructured?.length"
        class="flex items-center gap-8 mt-2">
        <router-link :to="`/front-portal/observations/view/unstructured/all`" class="flex flex-auto">
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex justify-center items-center">
            View All
          </button>
        </router-link>
      </div>
      <div v-else class="flex items-center justify-start gap-8 mt-2">
        <div>
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center"
            @click="addNewObservation">
            Add New
          </button>
        </div>
      </div>
    </div>
    <UnstructEditModal modalName="unstruct-edit-modal" :observation="selectedObservation"
      :selectedSignal="selectedSignal" />
  </Card>
</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import Card from '../ui/Card.vue'
  import UnstructObservationModal from './forms/UnstructObservationModal.vue'
  import FormConfirmModal from './forms/FormConfirmModal.vue';
  import { cn } from '@/components/ui/utils'
  import UnstructViewCard from './view/UnstructViewCard.vue';
  import UnstructEditModal from './edit/Unstructured/UnstructEditModal.vue';

  const props = defineProps([
    'userData', 'observTab', 'observeData',
    'reloadObservations'
  ])

  const cardRef = ref(null)

  watch(() => props.observTab, () => {
    if (props.observTab === 'unstructured') {
      cardRef.value.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  })

  // const viewAllEducator = computed(() => {
  //   if (props.userData?.is_educator)
  //     return props.userData.educator_id
  //   return 'all'
  // })
  
  const selectedObservation = ref(null)

  const selectedSignal = ref(false)

  const selectObservation = (observationData) => {
    selectedObservation.value = observationData
    selectedSignal.value = !selectedSignal.value
    HSOverlay.open('#hs-unstruct-edit-modal')
  }

  console.log(props.observeData)

  const addNewObservation = () => {
    HSOverlay.open('#hs-unstruct-form-modal')
  }
</script>