import { getMessaging, getToken, isSupported } from "firebase/messaging";

export function checkPermissionStatus() {
  const permissionStatus = Notification.permission;

  if (permissionStatus === 'granted') {
    console.log('Notifications are already allowed.');
  } else if (permissionStatus === 'denied') {
    console.log('Notifications are blocked.');
  } else if (permissionStatus === 'default') {
    console.log('Notifications permission has not been requested yet.');
  }
  return permissionStatus;
}

export async function initializeFirebaseMessaging() {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const messaging = getMessaging();
      try {
        const currentToken = await getToken(messaging, { 
          vapidKey: 'BNJZXiQlFRXVuVShFNEa7I5uhidjW2BqI15PxJxEsFIG8Sf4rz6dchzsVBhTXWsOj2l8Mc6SUKIu2DlxzTUwU3g' 
        });

        if (currentToken) {
          console.log("service current token", currentToken);
          return currentToken;
          // Send the token to your server and update the UI if necessary
        } else {
          console.log('No registration token available. Request permission to generate one.');
          // Show permission request UI
        }
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    } else {
      console.log('This browser does not support Firebase Cloud Messaging.');
      console.log('You will not receive push notifications')
      // Implement alternative notification method (e.g., polling)
    }
  } catch (err) {
    console.error('Error checking messaging support:', err);
  }
  throw new Error('Failed to get current access token')
}

// initializeFirebaseMessaging();