<template>
  <div class="w-full px-3 items-center md:items-start md:ml-8 flex flex-col">
    <div class="text-xl w-full md:text-4xl mt-6 mb-3 font-medium max-w-[20.5rem]">Photo Essays</div>

    <!-- Spinner -->
    <div v-if="photosLoading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
      <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
        <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <!-- "Photos of Work" Thumbnail Card -->
    <div v-if="photosOfWork && photosOfWork.thumbnails.length" @click="handleImageClick(photosOfWork.thumbnails, 'photos-of-work')" 
      class="flex flex-col border border-slate-200 p-2 w-[100%] md:w-auto max-w-[20.5rem] md:max-h-[20rem] rounded-3xl m-2 cursor-pointer">
      
      <div class="font-bold ml-2 mt-2 text-lg">Photos of Work</div>

      <div class="flex flex-col border-slate-200 rounded-md items-center pl-[25px] md:p-2 cursor-pointer">
        <div class="flex flex-wrap relative min-h-[10rem] min-w-[15rem] md:min-w-[18rem] items-center">
          <!-- Render the images for Photos of Work -->
          <img v-if="photosOfWork.thumbnails[0]" :src="photosOfWork.thumbnails[0].thumbnail_image_link"
            class="rounded-3xl absolute left-[0px] md:left-[0px]" style="z-index: 1; height: 125px !important;" width="125"/>
          <img v-if="photosOfWork.thumbnails[1]" :src="photosOfWork.thumbnails[1].thumbnail_image_link"
            class="rounded-3xl absolute left-[25%] md:left-[85px]" style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125"/>
          <img v-if="photosOfWork.thumbnails[2]" :src="photosOfWork.thumbnails[2].thumbnail_image_link"
            class="rounded-3xl absolute left-[50%] md:left-[170px]" style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125"/>
          
          <div v-if="photosOfWork.count > 3" class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]" style="z-index: 4; height: 125px; width: 125px;"></div>
          <div v-if="photosOfWork.count > 3" class="absolute z-10 text-white text-5xl" style="left: calc(180px + 0px ); top: 57px;"> +{{ photosOfWork.count - 3 }}</div>
        </div>
      </div>
    </div>

    <!-- "Photos of Kids" Thumbnail Card -->
    <div v-if="photosOfKids && photosOfKids.thumbnails.length" @click="handleImageClick(photosOfKids.thumbnails, 'photos-of-kids')" 
      class="flex flex-col border border-slate-200 pt-2 px-2  w-[100%] md:w-auto max-w-[20.5rem] md:max-h-[20rem] rounded-3xl mt-2 cursor-pointer">
      
      <div class="font-bold mt-2 ml-2 text-lg">Photos of Kids</div>

      <div class="flex flex-col border-slate-200 rounded-md items-center pl-[25px] md:p-2 cursor-pointer">
        <div class="flex flex-wrap relative min-h-[10rem] min-w-[15rem] md:min-w-[18rem] items-center">
          <!-- Render the images for Photos of Kids -->
          <img v-if="photosOfKids.thumbnails[0]" :src="photosOfKids.thumbnails[0].thumbnail_image_link"
            class="rounded-3xl absolute left-[0px] md:left-[0px]" style="z-index: 1; height: 125px !important;" width="125"/>
          <img v-if="photosOfKids.thumbnails[1]" :src="photosOfKids.thumbnails[1].thumbnail_image_link"
            class="rounded-3xl absolute left-[25%] md:left-[85px]" style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125"/>
          <img v-if="photosOfKids.thumbnails[2]" :src="photosOfKids.thumbnails[2].thumbnail_image_link"
            class="rounded-3xl absolute left-[50%] md:left-[170px]" style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125"/>
          
          <div v-if="photosOfKids.count > 3" class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]" style="z-index: 4; height: 125px; width: 125px;"></div>
          <div v-if="photosOfKids.count > 3" class="absolute z-10 text-white text-5xl" style="left: calc(180px + 0px ); top: 57px;"> +{{ photosOfKids.count - 3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted,inject } from 'vue'
import { useRouter } from 'vue-router'; // Import the useRouter function

// Initialize the router
const router = useRouter();
const $call = inject('$call');


const photosLoading = ref(true)
const photosOfWork = ref(null)
const photosOfKids = ref(null)

const fetchPhotosSummary = async () => {
  try {
    // Make API call to fetch data for both categories
    const response = await $call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.fetch_photos_summary')

    // Update the data for both sections
    photosOfWork.value = {
      thumbnails: response.photos_of_work.thumbnails,
      count: response.photos_of_work.count
    }

    photosOfKids.value = {
      thumbnails: response.other_photos.thumbnails,
      count: response.other_photos.count
    }
    console.log("This is photos summary response",response)

    photosLoading.value = false
  } catch (error) {
    console.error("Error fetching photos summary:", error)
  }
}

// Call the API on component mount
onMounted(fetchPhotosSummary)

const handleImageClick = (thumbnails, category) => {
  console.log(`Clicked on ${category}:`, thumbnails);

  // Redirect to the categories page with the category as a dynamic route parameter
  router.push({
    name: 'PhotoEssaysCategory', // The route name for your PhotoEssays component
    params: { category } // Passing the category as a dynamic route parameter
  });
};

</script>
