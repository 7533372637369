<template>
  <div :class="cn('p-8 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <div class="space-y-3">
      <h4 class="font-semibold text-base md:text-lg">
        {{ getNthAlphabet(props.sectionNumber) }}. {{ props.section }}
      </h4>
      <p class="font-medium text-sm md:text-base">
        {{ props.questionNumber }}. {{ props.question.text }}
      </p>
      <div :class="cn(`flex flex-col gap-y-2 md:gap-y-4`)">
        <div v-for="option in props.question.options" class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              :value="option" :name="props.question.id" v-model="selectedOption">
              {{ option }}
          </label>
        </div>
        <div v-if="props.question.otherOption" class="flex">
          <label class="text-xs md:text-sm text-gray-600 ms-4 font-medium flex gap-4">
            <input type="radio" class="shrink-0 mt-0.5 border-gray-200 
              rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
              value="Other" :name="props.question.id" v-model="selectedOption">
              Other
          </label>
        </div>
      </div>
      <div v-if="question.otherOption && selectedOption === 'Other'" class="mt-2" @keydown.stop>
        <textarea 
          :id="`${question.id}-other`"
          :name="props.textFieldId"
          rows="3"
          class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
          focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
          placeholder="Enter text here..." 
          v-model="otherText" 
          ref="textInputRef"
          @input="textareaAutoHeight"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { cn } from '@/components/ui/utils.js';
import { getNthAlphabet } from '@/lib/utils'

const props = defineProps([
  'question', 'section', 'sectionNumber',
  'questionNumber', 'pageNum', 'componentPageNum',
  'observation'
]);

const selectedOption = ref(props.observation ? (props.observation[props.question.id] || '') : '');
const otherText = ref((props.observation && props.observation[props.question.id] === 'Other') ? (props.observation[`${props.question.id}_other`] || '') : '');

const textInputRef = ref(null)
const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  textInputRef.value.style.height = 'auto'
  let calculatedHeight = textInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight/2.5)
    calculatedHeight = windowHeight/2.5
  textInputRef.value.style.height = `${calculatedHeight}px`
}
</script>