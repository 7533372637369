<template>
  <Card :class="cn(`basis-1/3 gap-2 rounded flex-1 min-w-0`, 'lg:flex')">
    <template #title>
      <div class="flex justify-between items-center lg:flex-col lg:items-stretch lg:gap-2 xl:flex-row 
        xl:items-center lg:flex-auto xl:flex-initial" ref="cardRef">
        <span class="font-medium text-sm md:text-lg">Group/Activity Observations</span>
        <!-- <ActivityObservationModal v-if="modalActive" modalName="activity-form-modal-2" :userData="props.userData" 
          :reloadFn="reloadModal" :reloadObservations="props.reloadObservations">
          <template #trigger>
            <button type="button" class="py-2 md:py-3 px-8 2xl:px-16 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
              justify-center shrink-0" data-hs-overlay="#hs-activity-form-modal-2"
              @click="currentFormNum = 2">
              Add New
            </button>
          </template>
        </ActivityObservationModal> -->
      </div>
    </template>
    <div class="flex flex-col gap-2 h-full flex-auto">
      <!-- <Card class="hs-tooltip basis-1/2 h-full flex justify-center items-center hidden md:flex">
        <ActivityObservationModal modalName="activity-form-modal-1" :userData="props.userData">
          <template #trigger>
            <button type="button" class="hs-tooltip-toggle py-3 px-4 inline-flex justify-center items-center
              text-3xl rounded-3xl border border-transparent text-gray-800 gap-x-2
              hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white
              dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600
              h-20 w-20" data-hs-overlay="#hs-activity-form-modal-1" @click="currentFormNum=1">
              +
              <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible
                opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900
                text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700" role="tooltip">
                Create New Observation
              </span>
            </button>
          </template>
        </ActivityObservationModal>
      </Card> -->
      <div v-if="props.observeData?.activity_v3?.length 
          || props.observeData?.activity_v2?.length 
          || props.observeData?.activity?.length" 
        class="flex flex-col gap-2 md:gap-4 flex-auto">
        <ActivityObservViewCard v-for="observation in props.observeData.activity_v3?.slice(0,2) || []"
          :observation="observation" :selectObservation="selectObservation" :version="3" />
        <ActivityObservViewCard v-for="observation in props.observeData.activity_v2?.slice(0, spotsForV2) || []"
          :observation="observation" :selectObservation="selectObservation" :version="2" />
        <ActivityObservViewCard v-for="observation in props.observeData.activity?.slice(0, spotsForV1) || []"
          :observation="observation" :selectObservation="selectObservation" :version="1" />
      </div>
      <div v-else class="flex flex-col gap-2 md:gap-4 flex-auto items-center text-xs xl:text-sm text-gray-500
        font-medium">
        Nothing to show here. Click on the Add New button to create your first observation
      </div>
      <div v-if="observationExists" class="flex items-center justify-start gap-8 mt-2">
        <router-link :to="`/front-portal/observations/view/activity/all`" class="flex">
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center items-center">
            View All
          </button>
        </router-link>
      </div>
      <div v-else class="flex items-center justify-start gap-8 mt-2">
        <div>
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center"
            @click="addNewObservation">
            Add New
          </button>
        </div>
      </div>
    </div>
    <ActivityObservEditModal modalName="activity-observ-edit-modal" :observation="selectedObservation"
      :selectedSignal="selectedSignal" :version="selectedObservationVersion" />
    <ActivityObservV3EditModal modalName="activity-observ-v3-edit-modal" :observation="selectedObservation"
      :selectedSignal="selectedSignal" :version="selectedObservationVersion" />
  </Card>
</template>

<script setup>
  import Card from '../ui/Card.vue';
  import ActivityObservationModal from '@/components/observations/forms/ActivityObservationModal.vue'
  import { HSOverlay } from 'preline/preline';
  import FormConfirmModal from './forms/FormConfirmModal.vue';
  import { ref, computed, watch } from 'vue';
  import { cn } from '@/components/ui/utils'
  import ActivityObservViewCard from './view/ActivityObservViewCard.vue';
  import ActivityObservEditModal from '@/components/observations/edit/Activity/ActivityObservEditModal.vue'
  import ActivityObservV3EditModal from '@/components/observations/edit/Activity/ActivityObservV3EditModal.vue'

  const props = defineProps([
    'userData', 'observTab', 'observeData',
    'reloadObservations'
  ])

  const cardRef = ref(null)

  watch(() => props.observTab, () => {
    if (props.observTab === 'group') {
      cardRef.value.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  })

  // const viewAllEducator = computed(() => {
  //   if (props.userData?.is_educator)
  //     return props.userData.educator_id
  //   return 'all'
  // })

  const modalActive = ref(true)
  const reloadModal = () => {
    modalActive.value = false
    setTimeout(() => modalActive.value = true, 100)
  }

  const spotsForV2 = computed(() => {
    if (!props.observeData?.activity_v3) return 2
    if (props.observeData.activity_v3.length >= 2) return 0
    return 2 - props.observeData.activity_v3.length
  })

  const spotsForV1 = computed(() => {
    if (!props.observeData?.activity_v2) return spotsForV2.value
    if (props.observeData.activity_v2.length >= spotsForV2.value) return spotsForV2.value
    return spotsForV2.value - props.observeData.activity_v2.length
  })
  
  const selectedObservation = ref(null)
  const selectedObservationVersion = ref(2)
  const selectedSignal = ref(false)

  const selectObservation = (observationData, version = 1) => {
    selectedObservation.value = observationData
    selectedObservationVersion.value = version
    selectedSignal.value = !selectedSignal.value
    if (version === 3)
      HSOverlay.open('#hs-activity-observ-v3-edit-modal')
    else
      HSOverlay.open('#hs-activity-observ-edit-modal')
  }

  const observationExists = computed(() => {
    if (props.observeData?.activity_v3?.length 
              || props.observeData?.activity_v2?.length 
              || props.observeData?.activity?.length) {

      return true;
    } else {
      return false;
    }
  })

  const addNewObservation = () => {
    HSOverlay.open('#hs-activity-form-modal')
  }
</script>