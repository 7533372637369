<!-- src/components/PhoneAuth.vue -->
<template>


  <!-- Recaptcha doesn't seem to be getting rendered inside it when set to isible or in chrome,but it is required for arg inf funtion -->
  <!-- <div id="recaptcha-container" style="position: fixed; top: 0; width: 80vw; height: 50vh; display: flex; justify-content: center; align-items: center; z-index: 9999; overflow: auto;"></div>   -->
  
  <div class="flex flex-col">
    
    <!-- Phone no. input field -->
    <div v-if="!otpSent" class="max-w-sm space-y-3">
        <div class="relative">    
          <input v-model="phoneNumber" class="py-3 px-4 ps-20 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder="0000-000-000"
          :class="{'border-red-500 border-1': isNumberInvalid}"/>
            <div class="absolute inset-y-0 start-0 flex items-center text-gray-500 ps-px">
            <label for="hs-inline-leading-select-country" class="sr-only">Country</label>
            <select id="hs-inline-leading-select-country" ref="hs-inline-leading-select-country" name="hs-inline-leading-select-country" class="block w-full border-transparent rounded-lg focus:ring-blue-600 focus:border-blue-600 dark:text-neutral-500 dark:bg-neutral-800">
              <option>+91</option>
              <option>+1</option>
              <option>+5</option>
            </select>
          </div>
        </div>
    </div>
    <div v-if="isNumberInvalid" class="text-red-500 text-sm mt-2"> The mobile number entered is invalid</div>

    <!-- Send OTP button -->
    <button v-if="!otpSent" class="w-auto mt-4 px-4 md:px-\[2rem\] py-2 md:py-2 rounded-md bg-black text-white mx-auto text-base md:text-medium" @click="handleRequestOtpClck">
      {{ sendingOTP ? 'Sending OTP...' : 'Send OTP' }}
    </button>
    
    <div v-if="otpSent" class="mb-2 text-sm font-medium"> Enter OTP received on your phone number</div>
    <!-- OTP input field component -->
    <div v-if="otpSent" class="flex space-x-3" ref="pinInput" data-hs-pin-input="">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="1" autofocus="">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="2">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="3">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="4">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="5">
      <input type="text" class="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" data-hs-pin-input-item="6">
    </div>

    <div v-if="invalidOtp" class="text-red-500 text-sm mt-2"> Invalid OTP! Try again.</div>
   
    
    <div class="flex items-baseline justify-center gap-6 mt-4">       
        <!-- Submit/Confirm Otp button -->
        <button v-if="otpSent" @click="verifyOTP" class="w-auto px-4 md:px-[2rem] py-2 md:py-2 rounded-md bg-black text-white text-base md:text-medium">Confirm</button>
        
        <!-- Re-Send OTP button -->
        <button v-if="otpSent" class="text-black text-base underline md:text-medium align-middle" @click="resendOTP">Resend OTP</button>
    </div>

  </div>
</template>

<script>
import { app } from '../firebase.js';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

export default {
  inject: ["$call"],
  data() {
    return {
      phoneNumber: '',
      otpSent: false,
      confirmationResult: null,
      isNumberInvalid: false,
      invalidOtp: false,
      sendingOTP: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100)
  },
  props: {
    
  },
  methods: {
    handleRequestOtpClck(){
      this.requestOTP()
    },
    requestOTP() {      
      const get_country = this.getSelectedValues('hs-inline-leading-select-country')
      console.log ("this is the value of the selected input element",get_country)
      const formattedPhoneNumber = this.formatPhoneNumber(this.phoneNumber,get_country);

      if (formattedPhoneNumber) {
        this.phoneNumber = formattedPhoneNumber
        this.isNumberInvalid = false; 
        this.sendingOTP = true;
        const auth = getAuth(app);
        const appVerifier = this.recaptchaVerifier();
    
          signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
            .then((confirmationResult) => {
              this.confirmationResult = confirmationResult;
              this.otpSent = true;
              setTimeout(() => {   // Initialising preline here because preline otp input field get rendered after this.otpSent is set to true 
                window.HSStaticMethods.autoInit();
              }, 100)
              console.log('OTP has been sent.');
              this.sendingOTP = false;
            })
            .catch((error) => {
              console.error('Error during signInWithPhoneNumber', error);
            });
          }
          else {
        console.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
        this.isNumberInvalid = true;
      } 
    },
  verifyOTP() {
     
      const pinInputFields = this.$refs.pinInput.querySelectorAll('input[data-hs-pin-input-item]');
      let enteredPIN = '';

      for (let i = 1; i <= pinInputFields.length; i++) {
        const field = this.$refs.pinInput.querySelector(`input[data-hs-pin-input-item="${i}"]`);
        enteredPIN += field.value;
      }
      console.log('Entered PIN:', enteredPIN);

      this.confirmationResult.confirm(enteredPIN)
        .then((result) => {
          const user = result.user;
          console.log('User is verified', user);
          
          // ID Token
          user.getIdToken().then((idToken) => {
            console.log('ID Token:', idToken);
            // Send the ID token to your backend function
            this.$call('curriculum_planner.validate_firebase_id_token.validate_firebase_id_token', {
              id_token: idToken
            }).then(response => {
              console.log("This is the response received", response);

              if (response.status === 'success') {
          
                  // Redirect to parent UserHome page
                  this.$router.push({ name: 'UserHome' }).then(() => {
                    window.location.href = this.$router.resolve({ name: 'UserHome' }).href;
                  });
               
              } else {
                console.error('Login failed:', response.message);
                this.invalidOtp = true
              }

            });
          });
        })
        .catch((error) => {
          console.error('Error during OTP verification', error);
          this.invalidOtp = true
        });
    },

    resendOTP() {     
      if (this.phoneNumber) {
        console.log("Resend OTP function called and this is the phone no.",this.phoneNumber);
        const auth = getAuth(app);
        const appVerifier = this.recaptchaVerifier();
    
          signInWithPhoneNumber(auth, this.phoneNumber, appVerifier)
            .then((confirmationResult) => {
              this.confirmationResult = confirmationResult;
              this.otpSent = true;
              // setTimeout(() => {   // Initialising preline here because preline otp input field get rendered after this.otpSent is set to true 
              //   window.HSStaticMethods.autoInit();
              // }, 100)
              console.log('OTP has been sent again.');
            })
            .catch((error) => {
              console.error('Error during signInWithPhoneNumber', error);
            });
          
      } else {
        console.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
      }
    },
    recaptchaVerifier() {
      console.log("Captcha verifier called");
      const auth = getAuth(app);
      // Get the container element
      // const containerElement = document.getElementById('recaptcha-container');
      const containerElement = document.createElement('div');
      // Append the container element to the DOM
      document.body.appendChild(containerElement);
  
     
      console.log("This is the auth in recaptcha",auth)
      const recaptchaVerifier = new RecaptchaVerifier(auth, containerElement, {
        'size': 'invisible',  // Recaptcha seems to be working well that is have varify button visible on phones when set to "normal"
        'callback': (response) => {
          // reCAPTCHA solved - allow signInWithPhoneNumber
         console.log("captcha call back called")
        }
      });
      return recaptchaVerifier;
    },
    formatPhoneNumber(phoneNumber,country) {
      // Remove any non-digit characters from the phone number
      const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

      // Check if the cleaned phone number matches the expected format
      const isValidPhoneNumber = /^[0-9]\d{9}$/.test(cleanedPhoneNumber);

      if (isValidPhoneNumber) {
        // Format the phone number with the country code and hyphens
        // const formattedPhoneNumber = `${country} ${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6)}`; 
        const formattedPhoneNumber = `${country} ${cleanedPhoneNumber.slice(0, 4)}-${cleanedPhoneNumber.slice(4, 7)}-${cleanedPhoneNumber.slice(7)}`; // format aaccording to github solution, and tested to be working
        console.log("This is formatted phone number",formattedPhoneNumber)
        return formattedPhoneNumber;
      } else {
        return null;
      }
    },
    getSelectedValues(ref) {
    
    const selectElement = this.$refs[ref];
    if (selectElement.multiple) {
      // Handle multi-select
      const selectedOptions = selectElement.selectedOptions;
      return Array.from(selectedOptions).map(option => option.value);
    } else {
      // Handle single-select
      console.log ("Returning value for #####",selectElement.value)
      return selectElement.value;
    }
  },
  },
     
};
</script>
