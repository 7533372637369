<template>
  <Card :class="cn(`md:max-w-96 p-0 md:p-0`)">
    <div :class="cn(`bg-[#FAEDB1] pb-2 rounded-xl flex-auto flex flex-col`, props.prevResults[0].score >= 35 && 'bg-[#FBBA59]',
      props.prevResults[0].score >= 65 && 'bg-[#14B8A6]')">
      <div class="flex flex-col gap-4 p-4 md:p-5 bg-white rounded-b-xl flex-auto">
        <div class="flex justify-between items-center">
          <div class="flex flex-col gap-2">
            <h3 class="text-lg font-bold text-gray-800">
              {{ props.title }}
            </h3>
            <div class="text-gray-600 text-sm">
              No of takes: {{ props.prevResults.length }}
            </div>
          </div>
          <div :class="cn(`text-4xl font-bold text-[#FAEDB1]`, props.prevResults[0].score >= 35 && 'text-[#FBBA59]',
            props.prevResults[0].score >= 65 && 'text-[#14B8A6]')">
            {{ props.prevResults[0].score }}%
          </div>
        </div>
        <div>
          {{ props.description }}
        </div>
        <div class="flex gap-4 justify-start items-center">
          <AssessmentModal :modalName="`fac-assess-${props.qID}-modal`" :title="props.title" :qID="props.qID"
            :updateScore="props.updateScore" :description="props.description" :reloadQuizzes="props.reloadQuizzes">
            <template #trigger>
              <button type="button" class="py-3 px-8 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 disabled:opacity-50
                disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 justify-center
                dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800"
                :data-hs-overlay="`#hs-fac-assess-${props.qID}-modal`">
                Retake
              </button>
            </template>
          </AssessmentModal>
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue';
// import AssessmentModal from 'src/components/educators/AssessmentModal.vue';
import AssessmentModal from './AssessmentModal.vue';
import { cn } from '../ui/utils';

const props = defineProps([
  'title', 'description', 'qID', 'updateScore', 
  'prevResults', 'reloadQuizzes'
])
</script>