<template>
  <Card class="md:max-w-96">
    <template #title>
      <div class="custom-title">
        {{ props.title }}
      </div>
    </template>
    
    
    <div class="custom-description-and-btn-container flex flex-col gap-4">
      <div class="truncate custom-description-container">
        {{ props.description }}
      </div>
      <div class="flex gap-4 justify-start items-center">
        <AssessmentModal :modalName="`fac-assess-${props.qID}-modal`" :title="props.title" :qID="props.qID"
          :updateScore="props.updateScore" :description="props.description" :reloadQuizzes="props.reloadQuizzes">
          <template #trigger>
            <button type="button" class="start-learning-btn py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold
              rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 disabled:opacity-50
              disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 justify-center
              dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800" 
              :data-hs-overlay="`#hs-fac-assess-${props.qID}-modal`">
              Start Learning
            </button>
          </template>
        </AssessmentModal>
      </div>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue';
// import AssessmentModal from 'src/components/educators/AssessmentModal.vue';
import AssessmentModal from './AssessmentModal.vue';

const props = defineProps([
  'title', 'description', 'qID', 
  'updateScore', 'reloadQuizzes'
])
</script>