<template>

    <div class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self= "closeComponent">
      <div class="relative bg-white rounded-2xl shadow-md w-11/12 md:w-3/5 lg:w-3/5 h-[80%] max-h-[97vh] overflow-auto">
         
         <!-- Cross button -->
         <div class="sticky top-7 mr-6 flex justify-end z-10">
             <button @click="closeComponent" class="border-black">
                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
             </button>
         </div>

        <!-- content body -->
        <div class="pl-4 md:pl-8 pr-4 mt-4 mb-4"> 
            <!-- Heading -->
            <div class="font-semibold text-black text-2xl self-center"> Zippie Terms of Use</div>                       
            <div class="text-sm text-slate-500 font-medium mt-1">Updated August 2024</div>

            <!-- Summary -->
            <div class="mt-7 text-sm">Welcome to Zippie! Here's a quick overview of our Terms and Conditions:</div>
            <ul class="list-disc list-inside mt-2 text-sm leading-7">
    <div class="flex gap-2">
        <li><span class="font-semibold">What We Offer:</span> 
            Zippie provides curriculum planning and scheduling tools for educators and parents of microschools and homeschools.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Your Account:</span> 
            You're responsible for maintaining the confidentiality of your account and all activities under it.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Your Content:</span> 
            You keep ownership of the content you create, but you give us permission to use it to provide our services.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Our Content:</span> 
            Our website content is protected by copyright and other laws. You can't use it without permission.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Acceptable Use:</span> 
            Don't use Zippie for anything illegal or harmful to others.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Changes:</span> 
            We may update these terms. We'll notify you of significant changes.
        </li>
    </div>

    <div class="flex gap-2">
        <li><span class="font-semibold">Termination:</span> 
            We can suspend or terminate your account if you violate these terms.
        </li>
    </div>
            </ul>
            
            <div class="mt-5 text-sm">This summary is just an overview. Please read the full Terms and Conditions below for complete details. </div>

            <!-- List container -->
            <div class="mt-8 border rounded-md w-full md:w-[21%] min-w-[250px] pl-4 py-2">
                <div class="mt-2 text-lg font-semibold"> What's in these terms ?</div>
                <ol class="list-decimal list-inside mt-2 text-sm leading-7">
                    <li>Acceptance of Terms</li>
                    <li>Description of Service</li>
                    <li>User Accounts</li>
                    <li>User Content</li>
                    <li>Intellectual Property</li>
                    <li>Prohibited Uses</li>
                    <li>Privacy Policy</li>
                    <li>Disclaimers</li>
                    <li>Limitation of Liability</li>
                    <li>Modifications to Terms and Services</li>
                    <li>Termination</li>
                    <li>Governing Law</li>
                    <li>Contact Information</li>
                </ol>
            </div>

            <!-- Content container -->
            <div class="mt-8 text-sm">
                
                <!-- #1 Definitions -->
                <div class="font-semibold text-xl">1. Definitions</div>
                <div class="mt-3"> To help you understand these Terms and Conditions, we've defined some key terms:</div>
                <ul class="list-disc list-inside mt-2 text-sm leading-7">
                    <div class="flex gap-2">
                        
                     <li class="font-semibold">"Zippie," "we," "us," or "our": </li>
                     <span>Refers to Comini Learning, the company that owns and operates the Zippie platform.</span>
                    </div>
                     
                     <div class="flex gap-2">
                        <li class="font-semibold">Service" or "Services":
                        </li>
                        <span>The curriculum planning and scheduling tools, resources, and related services provided by Zippie.</span>
                    </div>    

                    <div class="flex gap-2">
                        <li class="font-semibold">User," "you," or "your": 
                        </li>
                        <span>Any individual or entity that accesses or uses our Services.</span>
                    </div>

                    <div class="flex gap-2">
                     <li class="font-semibold">Account": </li>
                     <span>The personal account created by a User to access and use our Services.</span>
                    </div>

                     <div class="flex gap-2">
                        <li class="font-semibold">User Content":
                        </li>
                        <span>Any content, including but not limited to curriculum plans, schedules, and educational resources, that Users submit, post, or display on Zippie.</span>
                    </div>
                </ul>

                <div class="mt-5 text-sm"> <span class="font-semibold">Please note:</span> Additional terms related to data and privacy are defined in our <router-link to="/front-portal/privacy-policy">Privacy Policy.</router-link> </div>
               

                <!-- #2 -->
                <div class="font-semibold text-xl mt-4">2. Acceptance of Terms</div>
                <div class="mt-3"> By accessing or using Zippie, you agree to be bound by these Terms and Conditions. If you do not agree to all the terms and conditions, you may not use our services.</div>
            
                <!-- #3 -->
                <div class="font-semibold text-xl mt-4">3. Description of Service</div>
                <div class="mt-3">Zippie provides tools and resources for curriculum planning and scheduling, specifically designed for educators of microschools and homeschools. Our services may include, but are not limited to:
                <ul class="list-inside mt-2 text-sm leading-6">
                    <li>- Curriculum planning tools</li>
                    <li>- Scheduling features</li>
                    <li>- Resource sharing capabilities</li>
                    <li>- Educational content libraries</li>
                </ul>
                </div>

                <!-- #4 -->
                <div class="font-semibold text-xl mt-4">4. User Accounts</div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3"> 
                <div>3.1. You must create an account to use certain features of Zippie. You are responsible for maintaining the confidentiality of your account information and password.</div>

                <div>3.2. You agree to provide accurate and complete information when creating your account and to update this information as necessary.</div>

                <div>3.3. You are solely responsible for all activities that occur under your account.</div>
                </div>


                <!-- #5 -->
                <div class="font-semibold text-xl mt-4">5.User Content </div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3"> 
                <div>4.1. You retain all rights to any content you submit, post, or display on Zippie ("User Content").</div>

                <div>4.2. By submitting User Content, you grant Zippie a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and distribute such content for the purpose of providing and promoting our services.</div>

                <div>4.3. You represent and warrant that you have all necessary rights to submit User Content and that such content does not violate any third-party rights or applicable laws.</div>
                </div>

            
                <!-- #6 -->
                <div class="font-semibold text-xl mt-4">6.Intellectual Property</div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3"> 
                <div>5.1. All content, features, and functionality of Zippie, including but not limited to text, graphics, logos, and software, are owned by Comini Learning and are protected by copyright, trademark, and other intellectual property laws.</div>

                <div>5.2. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our website without our prior written consent.</div>

                </div>


                <!-- #7 -->
                <div class="font-semibold text-xl mt-4">7. Prohibited Uses</div>
                <div class="mt-3">You agree not to use Zippie for any unlawful purpose or in any way that violates these Terms and Conditions, including but not limited to:
                <ul class="list-inside mt-2 text-sm leading-6">
                    <li>- Transmitting any malicious code or viruses</li>
                    <li>- Attempting to gain unauthorized access to our systems or other users' accounts</li>
                    <li>- Interfering with or disrupting the integrity or performance of Zippie</li>
                    <li>- Engaging in any activity that could harm minors</li>
                </ul>
                </div>


                <!-- #8 -->
                <div class="font-semibold text-xl mt-4">8. Privacy Policy</div>
                <div class="mt-3"> Your use of Zippie is also governed by our Privacy Policy, which can be found at    <router-link to="/front-portal/privacy-policy">Privacy Policy.</router-link>
                </div>


                <!-- #9 -->
                <div class="font-semibold text-xl mt-4">9. Disclaimers</div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3">
                <div>8.1. Zippie is provided "as is" and "as available" without any warranties, either express or implied.</div>
                <div>8.2. We do not warrant that Zippie will be uninterrupted, error-free, or free from harmful components.</div>
                <div>8.3. We are not responsible for the accuracy, quality, or appropriateness of any User Content or educational materials shared on Zippie.</div>
                </div>

                <!-- #10 -->
                <div class="font-semibold text-xl mt-4">10. Limitation of Liability</div>
                <div class="mt-3 text-sm leading-6">
                To the fullest extent permitted by law, Comini Learning  shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of Zippie.
                </div>

                <!-- #10 -->
                <div class="font-semibold text-xl mt-4">11. Modifications to Terms and Services</div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3">
                <div>11.1. We reserve the right to modify these Terms and Conditions at any time. We will provide notice of any material changes by posting the updated Terms on Zippie.</div>
                <div>11.2. We may add, modify, or remove features or functionality of Zippie at any time without prior notice.</div>
                </div>

                <!-- #12 -->
                <div class="font-semibold text-xl mt-4">12. Termination</div>
                <div class="mt-3 text-sm leading-6">
                We reserve the right to terminate or suspend your account and access to Zippie at our sole discretion, without notice, for any reason, including a breach of these Terms and Conditions.
                </div>

                <!-- #13 -->
                <div class="font-semibold text-xl mt-4">13. Governing Law and Jurisdiction</div>
                <div class="mt-3 flex flex-col text-sm leading-6 gap-3">
                    <div>12.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of India, including but not limited to the Information Technology Act, 2000 and the Indian Contract Act, 1872.</div>
                    <div>12.2. Any disputes arising out of or in connection with these Terms and Conditions, including any question regarding their existence, validity, or termination, shall be subject to the exclusive jurisdiction of the courts in Delhi, India.</div>
                    <div>12.3. Notwithstanding the above, nothing in this clause shall limit the right of the platform to take proceedings against any user in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.</div>
                    <div>12.4. In case of any disputes, the parties agree to attempt to resolve the matter amicably through mediation before resorting to litigation.</div>
                </div>

                <!-- #14 -->
                <div class="font-semibold text-xl mt-4">14. Contact Information</div>
                <div class="mt-3 text-sm leading-6">
                If you have any questions about these Terms and Conditions, please contact us at <span class="text-blue-600 hover:text-blue-400">sai@comini.in</span> or Comini learning, Mayflower, 2nd Floor,New Kantwadi, Off Perry Road,Bandra (W), Mumbai 400050.
                </div>

                <div class="mt-3 text-sm leading-6">
                By using Zippie, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                </div>
            
            </div>

            
        </div>


        <!-- I accept button -->
        <div v-if="$auth.isLoggedIn" class="sticky bottom-0 mt-0 bg-white border-t py-4 w-full flex justify-end">
            <button @click="updateTermsOfUseStatus" class="text-white truncate bg-black rounded-md text-center text-sm py-2.5 px-8 mr-4"> I Accept</button>
         </div>

        
     </div> 
    </div>   
 </template>
 
 
 <script setup>
 import { ref, onMounted, inject, computed, watch } from 'vue';
 import { defineEmits } from 'vue';
 
 // Inject the $call method
 const $call = inject('$call');
 const $auth = inject('$auth');
 
 const emit = defineEmits(['close']);
 
 
 const closeComponent = () => {
  emit('close');
 };

 const updateTermsOfUseStatus = async() =>{
  try {
      const response = await $call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.update_terms_of_use_status');
      console.log("This is the response", response);
      if (response.status === "success"){
        emit('close');
          if(response.terms_of_use && response.privacy_policy){
            window.location.href = '/front-portal';
          }
      }
    } catch (error) {
        console.error('Error accepting Terms of Use:', error);
    }
 }
 
 
 
 </script>